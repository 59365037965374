<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content ion-text-center">
          <div class="page-heading">
            <h1>Üzenet küldése</h1>
            <p>
              Jelenleg fejlesztés alatt áll a valós idejű üzenetküldésünk, de addig is továbbítjuk az üzeneteid az
              alábbi mező kitöltésével.
            </p>
          </div>
          <form @submit.prevent="sendMessage" novalidate>
            <div class="form-group">
              <ion-label for="message" position="stacked" class="control-label">Üzenet</ion-label>
              <ion-textarea
                id="message"
                rows="6"
                v-model="validations.message.$model"
                name="message"
                required
                :class="{ 'is-invalid': validations.message.$error || hasServerValidationError('message') }"
                class="form-control"
                autocapitalize="sentences"
              >
              </ion-textarea>
              <ErrorMessage :field="validations.message" />
              <ServerValidation field="message" :server-validation="serverValidation" />
            </div>
            <ion-button
              type="submit"
              id="submit"
              expand="full"
              shape="round"
              :disabled="isLoading"
              strong="true"
              class="btn"
            >
              {{ isLoading ? 'Loading' : 'Küldés' }}
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { IonPage, IonContent, IonLabel, IonTextarea, IonButton, IonRippleEffect } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import useToast from '@/composables/toast'
import useFormControl from '@/composables/formControl'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'MessagePet',
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonTextarea,
    IonButton,
    IonRippleEffect,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const route = useRoute()
    const { openToast } = useToast()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const message = ref('')
    const isLoading = ref(false)

    const validationRules = {
      message: { required },
    }

    const validations = useVuelidate(validationRules, {
      message,
    })

    function resetForm() {
      message.value = ''
      setTimeout(() => {
        validations.value.message.$reset()
        validations.value.$reset()
      }, 10)
    }

    async function sendMessage() {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        await httpClient.save(`pets/${route.params.id}/message`, { message: message.value })

        openToast({ message: 'Az üzenetet sikeresen elküldtük!' })
        resetForm()

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        openToast({ color: 'danger', message: 'Hiba az üzenet küldés során!' })

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      message,
      sendMessage,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
    }
  },
})
</script>

<style lang="scss" scoped>
.container {
  .content {
    padding: 30px 20px 0;
  }
}

.btn {
  margin-top: 30px;
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}
</style>
