<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content pet-search">
          <div class="btn-group">
            <div @click="getPets({ filter: 'all' })" :class="['btn btn-sm', isActiveButton('all') ? 'active' : null]">
              <ion-img :src="require('@/assets/images/icons/search-all.svg')" alt="Mindenki keresés kép" class="icon" />
              Mindenki
            </div>
            <div
              @click="getPets({ filter: 'followed' })"
              :class="['btn btn-sm', isActiveButton('followed') ? 'active' : null]"
            >
              <ion-img
                :src="require('@/assets/images/icons/search-follow.svg')"
                alt="Követettek keresés kép"
                class="icon"
              />
              Követetettek
            </div>
            <div
              @click="getPets({ filter: 'local' })"
              :class="['btn btn-sm', isActiveButton('local') ? 'active' : null]"
            >
              <ion-img
                :src="require('@/assets/images/icons/search-local.svg')"
                alt="Helyiek keresés kép"
                class="icon"
              />
              Helyiek
            </div>
          </div>
          <form @submit.prevent="search" @keypress.enter="search" class="search-bar">
            <ion-input
              id="searchFor"
              v-model="searchFor"
              name="searchFor"
              enterkeyhint="go"
              placeholder="Név, fajta, város..."
              required
              class="form-control"
              autocapitalize="sentences"
            />
            <ion-img
              :src="require('@/assets/images/icons/search-icon.svg')"
              alt="search"
              @click="search"
              class="search-button"
            />
          </form>
          <div v-if="pets" class="pet-list">
            <PetListItem v-for="pet in pets" :key="pet.id" :pet="pet" />
          </div>

          <ion-infinite-scroll @ionInfinite="getPets({ filter: activeButton }, $event)" :disabled="!hasNextPage">
            <ion-infinite-scroll-content />
          </ion-infinite-scroll>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { IonPage, IonContent, IonInput, IonImg, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'

import PetListItem from '@/components/Pet/PetListItem'

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonImg,
    PetListItem,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  setup() {
    const pets = ref(null)
    const searchFor = ref('')
    const nextPage = ref(1)
    const hasNextPage = ref(true)
    const activeButton = ref('all')
    const isLoading = ref(false)

    const getPets = async (params, event) => {
      if (isLoading.value) {
        return
      }

      if (!event) {
        nextPage.value = 1
        hasNextPage.value = true
        pets.value = []
      }

      isLoading.value = true

      const reqParams = {
        page: nextPage.value,
        limit: 50,
        ...params,
      }

      try {
        const { data } = await httpClient.get('pets', '', reqParams)

        const petResults = data.data
        const pages = data.pagination

        if (nextPage.value > 1) {
          pets.value = helpers.appendToList(pets.value, petResults)
        } else {
          pets.value = petResults
        }

        if (params.filter) {
          activeButton.value = params.filter
        } else {
          activeButton.value = null
        }

        if (pages.lastPage > pages.currentPage) {
          nextPage.value = pages.currentPage + 1
        } else {
          hasNextPage.value = !hasNextPage.value
        }

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      } finally {
        isLoading.value = false

        if (event) {
          event.target.complete()
        }
      }
    }

    onMounted(() => getPets({ filter: 'all' }))

    const search = () => getPets({ filter: activeButton.value, search: searchFor.value })

    const isActiveButton = param => param === activeButton.value

    return {
      pets,
      searchFor,
      search,
      getPets,
      hasNextPage,
      activeButton,
      isActiveButton,
    }
  },
})
</script>

<style lang="scss" scoped>
.ios .pet-search {
  margin-top: 150px !important;
}

.pet-search {
  margin-top: 100px;

  .pet-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 10px;

    @media (max-width: 575px) {
      width: 90%;
      margin: 0 auto;
    }

    @media (max-width: 405px) {
      width: 100%;
    }

    .pet-list-item {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      word-break: break-all;

      @media (max-width: 367px) {
        flex: 0 0 calc(33% - 20px);
        max-width: calc(33% - 20px);
        margin-left: 7px;
        margin-right: 7px;
      }

      @media (min-width: 368px) {
        margin-left: 15px;
        margin-right: 15px;
      }

      @media (min-width: 368px) and (max-width: 575px) {
        flex: 0 0 calc(33% - 30px);
        max-width: calc(33% - 30px);
      }

      @media (min-width: 576px) {
        width: 80px;
        max-width: 80px;
      }

      .pet-avatar {
        @media (max-width: 360px) {
          width: 75px;
          height: 75px;
        }
      }
    }
  }
}

.search-bar {
  position: relative;
  padding: 0 15px;

  ion-input {
    --padding-start: 55px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    border-radius: 28px;
    border: none;
    box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  }

  .search-button {
    position: absolute;
    left: 20px;
    top: 50%;
    bottom: 0;
    width: 50px;
    height: 25px;
    color: red;
    transform: translateY(-50%);
    z-index: 3;
  }
}

.btn-group {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin: 30px 0 24px 0;

  .btn {
    display: flex;
    align-items: center;
    text-transform: none;
    font-size: 12px;
    opacity: 0.25;
    height: unset;
    cursor: pointer;
    margin: 0 16px;
    flex-shrink: 0;

    &.active {
      opacity: 1;
    }

    .icon {
      max-width: 18px;
      max-height: 18px;
      min-height: 18px;
      min-height: 18px;
      margin-right: 8px;
    }
  }
}
</style>
