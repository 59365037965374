<template>
  <div v-if="posts && posts.length" class="posts">
    <ion-img
      v-for="post in posts"
      :key="post.id"
      :src="post.image"
      @click="navigateTo({ name: POST_ROUTE, params: { id: post.id } })"
      :alt="`${post.pet.name} bejegyzése`"
      class="post-image"
    />
  </div>

  <div v-if="isLoading">Loading...</div>

  <ion-infinite-scroll @ionInfinite="loadPetPosts($event, petId)" :disabled="!hasNextPage">
    <ion-infinite-scroll-content />
  </ion-infinite-scroll>
</template>

<script>
import { defineComponent, computed, watch, toRefs } from 'vue'
import { IonInfiniteScroll, IonInfiniteScrollContent, IonImg } from '@ionic/vue'
import { useRoute } from 'vue-router'

import { PET_ROUTE, POST_ROUTE } from '@/constants/routes'

import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'PetPosts',
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonImg,
  },
  setup() {
    const route = useRoute()
    const { activePet, fetchPetPosts, loadPetPosts, petFeed, resetPetPosts } = usePets()
    const { navigateTo } = useNavigation()

    const isPetRoute = computed(() => route.name === PET_ROUTE)
    const petId = computed(() => route.params.id)

    watch(petId, () => {
      if (petId.value && isPetRoute.value) {
        resetPetPosts()
        fetchPetPosts(petId.value)
      }
    })

    return {
      ...toRefs(petFeed),
      loadPetPosts,
      activePet,
      navigateTo,
      POST_ROUTE,
      petId,
    }
  },
})
</script>

<style lang="scss" scoped>
.posts {
  margin: 0.5rem calc(((100vw - 285px) / -2) + 1rem);
  border-radius: 30px;
  overflow: hidden;
  clear: both;

  @media (max-width: 767px) {
    margin: 0.5rem calc(((100vw - 285px) / -2) + 1rem);
  }

  @media (min-width: 768px) {
    margin: 0.5rem calc(((600px - 285px) / -2) + 1rem);
  }

  &:before,
  &:after {
    content: '';
    display: block;
  }
}

.post-image {
  float: left;
  object-fit: cover;
  margin: 4px;

  @media (max-width: 767px) {
    &:nth-child(6n + 1),
    &:nth-child(6n + 6) {
      height: calc((100vw - 2rem) * 0.66 - 8px);
      width: calc((100vw - 2rem) * 0.66 - 8px);
    }

    &:nth-child(6n + 2),
    &:nth-child(6n + 3),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      height: calc((100vw - 2rem) * 0.33 - 8px);
      width: calc((100vw - 2rem) * 0.33 - -3px);
    }

    &:nth-child(6n + 6) {
      margin-top: calc((100vw - 2rem) * -0.33 + 4px);
    }

    &:nth-child(6n + 1) {
      margin-top: 0;
      margin-left: 0;
    }

    &:nth-child(6n + 2) {
      margin-top: 0;
      margin-right: 0;
    }

    &:nth-child(6n + 3),
    &:nth-child(6n + 6) {
      margin-right: 0;
    }

    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      margin-left: 0;
    }
  }

  @media (min-width: 768px) {
    &:nth-child(6n + 1),
    &:nth-child(6n + 6) {
      height: calc((600px - 2rem) * 0.66 - 8px);
      width: calc((600px - 2rem) * 0.66 - 8px);
    }

    &:nth-child(6n + 2),
    &:nth-child(6n + 3),
    &:nth-child(6n + 4),
    &:nth-child(6n + 5) {
      height: calc((600px - 2rem) * 0.33 - 8px);
      width: calc((600px - 2rem) * 0.33 - 8px);
    }

    &:nth-child(6n + 6) {
      margin-top: calc((600px - 2rem) * -0.33 + 4px);
    }
  }

  &:nth-child(6n + 4),
  &:nth-child(6n + 5) {
    clear: left;
  }
}

ion-icon {
  --fill: var(--ion-text-color);
}

.no-more-posts {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-family: var(--ion-poppins-font);

  ion-img {
    height: 45px;
    width: 53px;
    margin-bottom: 12px;
  }
}
</style>
