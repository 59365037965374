import usePets from '@/composables/pets'
import httpClient from '@/core/api/api.service'

export default function useDoxData() {
  const { activePet } = usePets()
  const getDoxData = async () => {
    const url = '/pets/{pet}/dox-data'.replace('{pet}', activePet.value.id)
    return await httpClient.get(url)
  }
  return { getDoxData }
}
