<template>
  <ion-page id="dox-page">
    <ion-content :scroll-events="true" id="dox-data-content">
      <div class="container">
        <div class="content">
          <ion-refresher
            slot="fixed"
            pull-min="100"
            pull-factor="0.5"
            @ionRefresh="doRefresh($event)"
            v-if="Capacitor.isNative"
          >
            <ion-refresher-content refreshing-spinner="crescent" />
          </ion-refresher>
          <div v-if="isLoading && activePet?.has_dox" class="loader">
            <ion-spinner name="crescent"></ion-spinner>
          </div>

          <swiper
            :pager="false"
            id="ionSlider"
            ref="slides"
            v-else
            :class="Capacitor.isNative ? '' : 'swiper-no-swiping'"
            :noSwiping="Capacitor.isNative && dox_owner_pet.length > 1 ? false : true"
            :noSwipingClass="'swiper-no-swiping'"
            :noSwipingSelector="'svg, div, img'"
            :speed="200"
            :space-between="30"
            :loop="dox_owner_pet.length > 1 ? true : false"
            :loopAdditionalSlides="0"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            @slideChange="swipeChange"
            @toEdge="loopEnded"
            @swiper="onSwiper"
          >
            <swiper-slide class="slideItem" v-for="element in dox_owner_pet" :key="element.pet_id">
              <h1 class="dox-logo">
                <ion-img :src="require('@/assets/images/icons/dox-label.svg')" class="dox-label-img"> </ion-img>
              </h1>
              <div class="topPart">
                <div class="topPart-inner" :class="dox_owner_pet.length <= 1 ? 'one-dox-only' : ''">
                  <div class="dox-data-wrapper">
                    <div class="slide-btn-wrapper swiper-button-prev prev-btn" v-if="dox_owner_pet.length > 1">
                      <img :src="backIcon" class="slide-btn" />
                    </div>

                    <div class="avatar-wrapper">
                      <ion-thumbnail slot="start" class="dox-tag-img" :class="{ dtag: element.tag_name === 'Dtag' }">
                        <img :src="element.tag" />
                      </ion-thumbnail>
                      <ion-avatar class="pet-avatar">
                        <ion-img class="upload" :class="'upload-icon'" :src="element.avatar" alt="Kép " />
                      </ion-avatar>
                    </div>

                    <div class="dox-data-section">
                      <ion-label class="control-label dox-name">
                        <h1>Alpha<span class="dot">.</span>{{ element.tag_name }}</h1>
                      </ion-label>
                      <p class="top-part-item">
                        <ion-label class="control-label"> Verzió:&nbsp;</ion-label>
                        <ion-label class="text-label"> {{ element.rfids[0]?.version }} </ion-label>
                      </p>
                      <p class="top-part-item">
                        <ion-label class="control-label">Azonosító:&nbsp;</ion-label>
                        <ion-label class="text-label"> {{ element.tag_identifier }} </ion-label>
                      </p>
                      <p class="top-part-item">
                        <ion-label class="control-label">Kedvenc:&nbsp;</ion-label>
                        <ion-label class="text-label pet-name-label"> {{ element.pet_name }} </ion-label>
                      </p>
                      <p class="top-part-item">
                        <ion-label class="control-label">Gazdi:&nbsp;</ion-label>
                        <ion-label class="text-label"> {{ dox_owner_person }} </ion-label>
                      </p>
                    </div>
                    <div class="slide-btn-wrapper swiper-button-next next-btn" v-if="dox_owner_pet.length > 1">
                      <img :src="forwardIcon" class="slide-btn" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="emergency-wrapper" :class="{ isMissing: element.isMissing === true }">
                <ion-toggle
                  slot="end"
                  mode="ios"
                  class="toggle-unsafe"
                  v-model="element.isMissing"
                  @ionChange.prevent="petMissing(element.id)"
                />
                <ion-text
                  ><b>{{ element.pet_name }}</b> {{ element.isMissing ? 'eltűnt' : 'biztonságban van' }}!
                </ion-text>
              </div>
              <div class="reads-wrapper">
                <h2 class="bold-item">Leolvasások</h2>
                <Reads
                  :read-items="element.rfids[0]?.dox_data_reads"
                  v-if="element.rfids[0]?.dox_data_reads.length > 0"
                />
                <div v-else>
                  <ion-label class="control-label reads-label no-reads-label"> Még nem történt leolvasás </ion-label>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <div v-if="!activePet?.has_dox" class="no-dox-container">
            <ion-img :src="require('@/assets/images/animated/doxocial-06-loop.gif')" alt="DOX info animation" />

            <p>Egy DOX-szal a DOXOCIAL is sokkal több!</p>

            <ion-item class="menu-item menu-item--buy-dox" lines="none">
              <ion-button
                expand="block"
                shape="round"
                strong="true"
                @click="openBrowserLink('https://dox-tag.eu')"
                class="btn btn-dox"
              >
                Irány a shop
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
            </ion-item>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, onMounted, watch } from 'vue'
import {
  IonContent,
  IonPage,
  IonThumbnail,
  IonImg,
  IonSpinner,
  IonLabel,
  IonAvatar,
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonText,
  IonToggle,
  IonRippleEffect,
  alertController,
} from '@ionic/vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Navigation])

import useDoxData from '@/composables/doxData'
import useNavigation from '@/composables/navigation'

import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons'
import { Capacitor } from '@capacitor/core'

import Reads from '@/components/DoxData/Reads'
import { useRoute } from 'vue-router'
import router from '../../router'

import usePets from '@/composables/pets'
import { PET_LOST_ROUTE } from '@/constants/routes'
import PET_STATUS from '@/constants/pet-status'

export default defineComponent({
  name: 'DoxData',
  components: {
    IonContent,
    IonPage,
    IonThumbnail,
    IonImg,
    Reads,
    IonSpinner,
    IonLabel,
    Swiper,
    SwiperSlide,
    IonAvatar,
    IonRefresher,
    IonRefresherContent,
    IonRippleEffect,
    IonItem,
    IonText,
    IonToggle,
  },

  setup() {
    const isLoading = ref(false)

    const { activePet, updatePet } = usePets()
    const { navigateTo, openBrowserLink } = useNavigation()

    const state = reactive({
      doxData: {},
      dox_owner_pet: [],
      dox_owner_person: '',

      tags: [
        { black: require('@/assets/images/doxTags/black.png') },
        { deepblue: require('@/assets/images/doxTags/deepblue.png') },
        { green: require('@/assets/images/doxTags/green.png') },
        { ocean: require('@/assets/images/doxTags/ocean.png') },
        { pastelred: require('@/assets/images/doxTags/pastelred.png') },
        { purple: require('@/assets/images/doxTags/purple.png') },
        { rookie: require('@/assets/images/doxTags/Rookie.png') },
        { yellow: require('@/assets/images/doxTags/yellow.png') },
        { dtag: require('@/assets/images/doxTags/dtag.png') },
      ],

      backIcon: require('@/assets/images/icons/chevron-back-outline.svg'),
      forwardIcon: require('@/assets/images/icons/chevron-forward-outline.svg'),

      activeIndex: 0,
      swiperComp: null,
    })
    const { getDoxData } = useDoxData()
    const route = useRoute()

    onMounted(() => {
      doxDataInit()
    })

    watch(
      route,
      () => {
        if (
          route.name == 'dox-data' &&
          router.options.history.state.forward !== '/dox-data-read-map' &&
          activePet.value.has_dox
        ) {
          state.doxData = {}
          state.dox_owner_pet = []
          state.dox_owner_person = ''
          doxDataInit()
        }
      },
      activePet,
    )

    async function doxDataInit() {
      isLoading.value = true
      if (activePet.value.has_dox) {
        await getDoxData().then(resp => {
          state.doxData = resp?.data
          for (const property in state.doxData) {
            state.dox_owner_person = property
            for (let e in state.doxData[property]) {
              state.doxData[property][e].pet_id = state.doxData[property][e].id
              state.doxData[property][e].pet_name = state.doxData[property][e].name
              state.doxData[property][e].isMissing = state.doxData[property][e].status === 2
              state.doxData[property][e].avatar = state.doxData[property][e].avatar?.cdn_url
                ? state.doxData[property][e].avatar.cdn_url
                : ''

              if (state.doxData[property][e].rfids.length > 0) {
                if (state.doxData[property][e].rfids.length > 1) {
                  for (let elem in state.doxData[property][e].rfids) {
                    state.doxData[property][e].rfids[elem].rfids = []
                    state.doxData[property][e].rfids[elem].rfids.push(state.doxData[property][e].rfids[elem])
                    state.doxData[property][e].rfids[elem].rfids[0].version =
                      state.doxData[property][e].rfids[elem].version
                    state.doxData[property][e].rfids[elem].avatar = state.doxData[property][e].avatar
                    state.doxData[property][e].rfids[elem].avatar_id = state.doxData[property][e].avatar_id

                    state.doxData[property][e].rfids[elem].name = state.doxData[property][e].name
                    state.doxData[property][e].rfids[elem].pet_name = state.doxData[property][e].pet_name
                    state.doxData[property][e].rfids[elem].isMissing = state.doxData[property][e].isMissing

                    delete state.doxData[property][e].rfids[elem].created_at
                    delete state.doxData[property][e].rfids[elem].production_date
                    delete state.doxData[property][e].rfids[elem].updated_at

                    state.dox_owner_pet.push(state.doxData[property][e].rfids[elem])
                  }
                } else {
                  state.dox_owner_pet.push(state.doxData[property][e])
                }
              }

              for (let el of state.dox_owner_pet) {
                if (el.rfids != undefined && el.rfids.length > 0) {
                  let tag_name = el.rfids[0].id.toLowerCase().split('-', 1)
                  el.tag_identifier = el.rfids[0].id.toLowerCase().split('-', 2)[1]

                  el.tag = ''
                  for (let i of state.tags) {
                    if (tag_name.toString() == Object.keys(i).toString()) {
                      el.tag = Object.values(i)
                      el.tag_name = capital(tag_name.toString())
                      break
                    } else {
                      el.tag = Object.values(state.tags[0])
                      el.tag_name = capital(tag_name.toString())
                    }
                  }
                }
              }
            }
          }
          isLoading.value = false
        })
      }
    }

    function capital(string) {
      return string.replace(/^./, string[0].toUpperCase())
    }

    function swipeChange() {
      document.querySelector('#dox-data-content').scrollToTop()
    }

    function loopEnded() {}

    const doRefresh = async event => {
      state.activeIndex = state.swiperComp.activeIndex

      try {
        state.doxData = {}
        state.dox_owner_pet = []
        state.dox_owner_person = ''
        await doxDataInit()
        state.swiperComp.slideTo(state.activeIndex, 0, false)
      } finally {
        event.target.complete()
      }
    }
    function onSwiper(swiper) {
      state.swiperComp = swiper
    }

    const petMissing = async tagId => {
      const doxTagData = state.dox_owner_pet.find(el => el.id == tagId)

      if (!doxTagData) return

      if (!doxTagData.isMissing) {
        return petComingHome(doxTagData.pet_id)
      }

      await navigateTo({ name: PET_LOST_ROUTE, params: { id: doxTagData.pet_id } })
    }

    const petComingHome = async id => {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog',
        header: 'Megjött',
        message: `Biztos vagy benne, hogy hazatért?`,
        buttons: [
          {
            text: 'Igen',
            cssClass: 'btn btn-primary',
            handler: () => {
              const args = {
                status: PET_STATUS.ACTIVE.id,
                missing: null,
              }
              updatePet({ id }, args)
            },
          },
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn',
            handler: () => {
              const doxTagData = state.dox_owner_pet.find(el => el.pet_id == id)
              doxTagData.isMissing = true
            },
          },
        ],
      })
      return dialog.present()
    }

    return {
      chevronBackOutline,
      chevronForwardOutline,
      ...toRefs(state),
      isLoading,
      Capacitor,
      swipeChange,
      loopEnded,
      doRefresh,
      onSwiper,
      activePet,
      openBrowserLink,
      petMissing,
    }
  },
})
</script>
<style lang="scss" scoped>
.ios {
  .container {
    .content {
      @media only screen and (max-width: 600px) {
        margin-top: 30px !important;
      }
    }
  }
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .content {
    @media only screen and (max-width: 600px) {
      margin-top: -28px !important;
    }

    ion-refresher {
      position: absolute;
      top: 10%;
      z-index: 2002;
    }
  }
}
#dox-page {
  font-family: 'Poppins';
  .loader {
    display: flex;
    justify-content: center;
  }

  #ionSlider {
    margin-top: 120px;
  }

  .slideItem {
    display: flex;
    flex-direction: column;

    .dox-logo {
      margin: 0 0 30px 0;
    }

    .slide-btn-wrapper {
      width: 80px;
      height: 40px;
      position: absolute;
      top: 100px;

      @media only screen and (max-width: 600px) {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 100px;
      }

      &.swiper-button-prev.prev-btn {
        left: 18%;
      }
      &.swiper-button-next.next-btn {
        left: 74.5%;
      }
      @media only screen and (max-width: 600px) {
        &.swiper-button-prev.prev-btn {
          left: 5px;
        }
        &.swiper-button-next.next-btn {
          left: auto;
          right: -5%;
        }
      }

      .slide-btn {
        color: #ccc;
        width: 40px;
        height: 40px;

        @media only screen and (max-width: 600px) {
          width: 30px;
          height: 30px;
        }
      }
    }

    .dox-label-img {
      top: 10%;
      height: 20px;
    }

    .bold-item {
      font-weight: 900;
    }

    .topPart {
      display: flex;
      margin-bottom: 60px;
      width: 100%;

      .topPart-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .dox-data-wrapper {
        display: flex;
        align-items: center;
        min-inline-size: min-content;

        .dot {
          font-family: sans-serif;
        }
      }

      .avatar-wrapper {
        display: flex;
        .dox-tag-img {
          width: 100px;
          height: 150px;
          position: relative;
          left: -25%;
          transform: rotate(5deg);

          &.dtag {
            transform: rotate(0deg);
            width: 150px;
            left: -15%;
          }
        }

        .pet-avatar {
          width: 50px;
          height: 50px;
          z-index: 10;
          position: relative;
          left: -82px;
          top: 109px;
          border: 4px solid #fff;
          box-shadow: 5px 2px 22px rgb(212 212 212 / 51%);
          background-color: #fff;
        }
      }

      .dox-data-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 110px;
        margin-left: -70px;

        ion-label {
          text-align: left;
          margin-bottom: 0 !important;
          font-family: var(--ion-poppins-font) !important;
        }

        .dox-name {
          letter-spacing: 0;
          h1 {
            letter-spacing: -1px;
          }
        }

        ion-label:not(.text-label) {
          padding-left: 5px;

          letter-spacing: -1px;
          h1 {
            font-weight: var(--font-weight-bold) !important;
          }
        }

        ion-label.text-label {
          max-width: 70px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: var(--ion-poppins-font);
          font-weight: var(--font-weight-light) !important;
        }
        .top-part-item {
          margin: 0;
          display: flex;
          flex-wrap: nowrap;
          font-size: 12px;
          max-height: 60px;
          font-family: 'Poppins';
        }
      }
    }

    .no-reads-label {
      padding-left: 0;
      margin-top: 1.4rem;
      font-weight: 500;
    }
    .reads-wrapper {
      font-family: 'Poppins';
      text-align: center;

      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        max-height: 25px;
        min-height: 25px;
        height: 25px;
        margin: auto;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
.no-dox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin-top: -20px;
  }

  ion-img {
    max-width: 400px;
  }

  ion-button {
    width: 250px;
    font-size: 14px;
    --background: #e6224a;
    --box-shadow: none;
  }
}

.emergency-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 47px;

  ion-toggle {
    margin-bottom: 11px;
    overflow: visible;
    contain: none;

    --handle-box-shadow: 0px 0px 7px rgba(72, 228, 83, 0.99);

    &.toggle-checked {
      --handle-box-shadow: 0px 0px 7px #e6224a;
    }
  }

  &.isMissing ion-text {
    color: #e6224a;
  }

  ion-text {
    color: #a9a9a9;
    font-weight: 400;
    font-size: 12px;

    b {
      font-weight: 700;
    }
  }
}
</style>
