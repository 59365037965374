<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <lottie-animation
            ref="anim"
            :autoPlay="false"
            :playInterval="30000"
            :playOnClick="true"
            :animationData="require(`@/assets/images/lottie/events/calendar.json`)"
          />

          <h1 class="title">Eseménynaptár</h1>

          <div class="calendar-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              class="add-circle"
              @click="goToMap('date')"
            >
              <circle cx="17.5" cy="17.5" r="17.5" :fill="selectedDateIsPast ? '#E7E5E5' : '#E6224A'" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.28027 17.7776C9.28027 16.7381 10.1229 15.8955 11.1623 15.8955H23.7094C24.7489 15.8955 25.5915 16.7381 25.5915 17.7776C25.5915 18.817 24.7489 19.6596 23.7094 19.6596H11.1623C10.1229 19.6596 9.28027 18.817 9.28027 17.7776Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.4343 9.62109C18.4737 9.62109 19.3164 10.4637 19.3164 11.5032V24.0503C19.3164 25.0897 18.4737 25.9323 17.4343 25.9323C16.3949 25.9323 15.5522 25.0897 15.5522 24.0503V11.5032C15.5522 10.4637 16.3949 9.62109 17.4343 9.62109Z"
                fill="white"
              />
            </svg>

            <Calendar
              locale="hu"
              class="calendar"
              :attributes="attrs"
              :disabledDates="disabledDates"
              @dayclick="handleDaySelect"
            />

            <div class="event-list">
              <div
                v-if="!selectedDateIsPast && eventsThisDay.length === 0"
                class="no-event-box"
                @click="goToMap('date')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 35 35"
                  fill="none"
                  class="add-circle"
                >
                  <circle cx="17.5" cy="17.5" r="17.5" fill="#E7E5E5" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.28027 17.7776C9.28027 16.7381 10.1229 15.8955 11.1623 15.8955H23.7094C24.7489 15.8955 25.5915 16.7381 25.5915 17.7776C25.5915 18.817 24.7489 19.6596 23.7094 19.6596H11.1623C10.1229 19.6596 9.28027 18.817 9.28027 17.7776Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.4343 9.62109C18.4737 9.62109 19.3164 10.4637 19.3164 11.5032V24.0503C19.3164 25.0897 18.4737 25.9323 17.4343 25.9323C16.3949 25.9323 15.5522 25.0897 15.5522 24.0503V11.5032C15.5522 10.4637 16.3949 9.62109 17.4343 9.62109Z"
                    fill="white"
                  />
                </svg>

                <h1>
                  Ezen a napon nincs esemény,<br />
                  szervezd meg a sajátod!
                </h1>
              </div>

              <div v-else-if="selectedDateIsPast && eventsThisDay.length === 0" class="no-event-box">
                <svg xmlns="http://www.w3.org/2000/svg" width="58" height="50" viewBox="0 0 58 50" fill="none">
                  <path
                    d="M34.9885 0H22.8988C10.5708 0 0.561035 10.2672 0.561035 22.8629V24.0382C0.561035 28.2737 3.35596 31.977 7.38586 33.0192L11.1341 33.9949C10.9824 32.9083 10.9608 31.7552 10.9608 30.6243L8.16584 29.8925C5.54424 29.205 3.72429 26.8101 3.72429 24.0382V22.8629C3.72429 12.0413 12.3257 3.23761 22.8988 3.23761H34.9885C45.5615 3.23761 54.163 12.0413 54.163 22.8629V24.0382C54.163 26.8101 52.343 29.205 49.7214 29.8925L48.2915 30.2694C48.1615 31.4447 47.9881 32.5979 47.7281 33.751L50.5231 33.0192C54.5313 31.977 57.3479 28.2737 57.3479 24.0382V22.8629C57.3262 10.2672 47.2948 0 34.9885 0Z"
                    fill="#333333"
                  />
                  <path
                    d="M29.5074 49.54H28.5325C17.8294 49.54 9.11963 40.6255 9.11963 29.6708V16.3434C9.11963 15.4564 9.83461 14.7246 10.7013 14.7246C11.5679 14.7246 12.2829 15.4564 12.2829 16.3434V29.6708C12.2829 38.8514 19.5627 46.3024 28.5325 46.3024H29.5074C38.4772 46.3024 45.757 38.8514 45.757 29.6708V16.3434C45.757 15.4564 46.472 14.7246 47.3386 14.7246C48.2053 14.7246 48.9203 15.4564 48.9203 16.3434V29.6708C48.9203 40.6255 40.2105 49.54 29.5074 49.54Z"
                    fill="#333333"
                  />
                  <path
                    d="M20.4719 20.7119C22.1186 21.0224 23.1802 22.8186 22.8335 24.6813C22.4869 26.5662 20.8619 27.8302 19.2153 27.4976C17.5687 27.1871 16.5071 25.3909 16.8537 23.5282C17.2004 21.6655 18.8253 20.4015 20.4719 20.7119Z"
                    fill="#333333"
                  />
                  <path
                    d="M37.5889 20.7119C35.9422 21.0224 34.8806 22.8186 35.2272 24.6813C35.5739 26.5662 37.1989 27.8302 38.8455 27.4976C40.4921 27.1871 41.5537 25.3909 41.2071 23.5282C40.8604 21.6655 39.2355 20.4015 37.5889 20.7119Z"
                    fill="#333333"
                  />
                  <path
                    d="M29.0298 30.1812C31.6081 30.1812 33.688 30.9129 33.688 32.3543C33.688 34.2836 31.6081 36.235 29.0298 36.235C26.4515 36.235 24.3716 34.3058 24.3716 32.3543C24.3716 30.9129 26.4515 30.1812 29.0298 30.1812Z"
                    fill="#333333"
                  />
                  <path
                    d="M24.8053 42.0664C25.1953 41.9777 25.5853 41.9111 25.9536 41.8446C25.802 41.8668 25.672 41.889 25.5203 41.9111C26.1486 41.8224 26.7986 41.7781 27.4269 41.8668C28.2719 42.0664 28.1202 42.0664 28.6619 42.2881C28.5319 42.2216 28.4019 42.1772 28.2719 42.1107C28.7702 42.3325 29.2469 42.5764 29.7019 42.8869C30.4168 43.3747 31.5435 43.0421 31.9335 42.2881C32.3668 41.4454 32.1068 40.5363 31.3485 40.0041C31.0668 39.8045 30.7635 39.6492 30.4602 39.494C29.7235 39.117 28.9435 38.7844 28.1202 38.6292C26.7336 38.3631 25.282 38.5183 23.917 38.8509C23.0937 39.0505 22.5087 40.0706 22.7904 40.8911C23.0937 41.7781 23.917 42.2881 24.8053 42.0664Z"
                    fill="#333333"
                  />
                </svg>

                <h1>
                  Ezen a napon nem volt esemény,<br />
                  válassz egy jövőbeni dátumot és<br />
                  hozd létre a sajátod!
                </h1>
              </div>

              <CalendarCard
                v-for="event in eventsThisDay"
                :key="event.id"
                :event="event"
                @click="goToMap('event', event)"
              />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { IonPage, IonContent } from '@ionic/vue'
import { Calendar } from 'v-calendar'
import 'v-calendar/dist/style.css'
import useEvents from '@/composables/events'
import useNavigation from '@/composables/navigation'
import CalendarCard from '@/components/Events/CalendarCard'
import { PET_WALKING_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'EventCalendar',
  components: {
    IonPage,
    IonContent,
    Calendar,
    CalendarCard,
  },
  setup: () => {
    const today = new Date()
    const { getEvents } = useEvents()
    const { navigateTo } = useNavigation()

    const events = ref([])
    const eventsThisDay = ref([])
    const selectedDate = ref(today)
    const selectedDateIsPast = ref(false)

    const attrs = ref([
      {
        key: 'today',
        dates: today,
      },
      {
        key: 'selected',
        highlight: true,
        dates: today,
      },
    ])

    const disabledDates = ref([{ start: null, end: new Date().setDate(today.getDate() - 1) }])

    const handleDaySelect = date => {
      eventsThisDay.value = []
      selectedDateIsPast.value = false
      selectedDate.value = date.date

      attrs.value = attrs.value.map(attr =>
        attr.key !== 'selected'
          ? attr
          : {
              ...attr,
              dates: date.date,
            },
      )

      if (date.date < today && !datesAreOnSameDay(date.date, today)) {
        selectedDateIsPast.value = true
      }

      events.value.forEach(event => {
        const eventDate = new Date(event.date)

        if (datesAreOnSameDay(eventDate, date.date)) {
          eventsThisDay.value = [...eventsThisDay.value, event]
        }
      })
    }

    const goToMap = async (type = 'date', event = null) => {
      if (type === 'date') {
        if (selectedDateIsPast.value) return

        const date = selectedDate.value

        return await navigateTo({
          name: PET_WALKING_ROUTE,
          query: {
            eventMenuOpened: true,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
          },
        })
      }

      if (!event.is_active) return

      return await navigateTo({
        name: PET_WALKING_ROUTE,
        query: {
          openEvent: event.id,
        },
      })
    }

    const datesAreOnSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()

    onMounted(async () => {
      const eventList = await getEvents('calendar')
      events.value = eventList

      const datesOfEvents = []

      eventList.forEach(event => {
        const eventDate = new Date(event.date)

        if (datesAreOnSameDay(eventDate, today)) {
          eventsThisDay.value = [...eventsThisDay.value, event]
        }

        const existingDate = datesOfEvents.find(date => datesAreOnSameDay(date, eventDate))

        if (!existingDate) {
          datesOfEvents.push(eventDate)
        }
      })

      datesOfEvents.forEach(date => {
        attrs.value = [
          ...attrs.value,
          {
            bar: true,
            dates: date,
          },
        ]
      })
    })

    return {
      attrs,
      disabledDates,
      eventsThisDay,
      selectedDateIsPast,
      handleDaySelect,
      goToMap,
    }
  },
})
</script>

<style lang="scss">
.calendar {
  --vc-bg: transparent;
  --vc-border: transparent;
  --vc-header-arrow-color: #e6224a;
  --vc-weekday-color: #c1c1cc;
  margin: 0 auto;

  .vc-arrow svg {
    height: 20px;
    margin-top: 1px;
  }

  .vc-header {
    margin-bottom: 14px;
    button {
      background: none;

      &:hover {
        background: none;
      }

      &:focus-within {
        box-shadow: none;
      }

      &.vc-title {
        font-size: 16px;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        text-transform: capitalize;
        letter-spacing: -0.3px;
      }
    }
  }

  .vc-weekday {
    max-width: 45px;
  }

  .vc-day {
    min-height: 45px;
    min-width: 45px;
    max-width: 45px;
    font-size: 12px;
    --vc-font-medium: 700;
    font-family: 'Open Sans', sans-serif;

    &.is-today {
      color: #00cb39 !important;

      .vc-highlight-content-solid {
        color: #00cb39 !important;
      }
    }

    .vc-day-content {
      font-size: 12px;
      width: 100%;
      height: 100%;
      outline: none;

      &:focus-within {
        box-shadow: none;
      }

      &:hover {
        background: transparent;
      }
    }

    &:not(.is-today) {
      .vc-day-content {
        color: #5b5b5b;

        &.vc-highlight-content-solid,
        &.vc-highlight-content-solid.disabled {
          color: #5b5b5b !important;
        }

        &.vc-disabled {
          color: #dddded;
        }
      }
    }

    .vc-highlight {
      width: 100%;
      height: 100%;
      --vc-highlight-solid-bg: #efefef;
    }

    .vc-day-content.vc-disabled ~ .vc-day-layer {
      .vc-bar {
        --vc-bar-bg: #e7e7f3;
      }
    }

    .vc-day-layer {
      justify-content: flex-end;
      align-items: center;

      .vc-bars {
        width: 4px;
        height: 22px;
        margin-right: 9px;

        .vc-bar {
          width: 4px;
          height: 22px;
          border-radius: 5px;
          --vc-bar-bg: #e6224a;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

.lottie-animation {
  width: 42px;
  height: 42px;
  margin: 100px auto 0 auto;
}

h1.title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 18px !important;
  letter-spacing: -0.3px;
  text-align: center;
  line-height: 23px;
  margin-top: 0;
  margin-bottom: 20px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  width: min-content;
  margin: 0 auto;

  .add-circle {
    align-self: flex-end;
    margin-right: 14px;
    margin-bottom: 14px;
  }
}

.no-event-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 50px;

  svg {
    margin: 0 0 9px 0 !important;
    align-self: center !important;
  }

  h1 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px !important;
    letter-spacing: -0.3px;
    color: #afafaf;
    line-height: normal;
  }
}

.event-list {
  display: flex;
  flex-direction: column;

  .event-card {
    margin-bottom: 38px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
