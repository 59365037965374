<template>
  <ion-page v-disable-swipe-back>
    <ion-content>
      <div class="container">
        <PetWalkingMap
          :key="mapComponentKey"
          ref="walkingMap"
          @click="mapClick(false)"
          class="map-container"
          v-bind:class="{
            disabled: (menuActive && isRunning) || isPoiMenuOpen || poiSelectorOpened || showPoiCreationBackdrop,
          }"
          :data="mapData"
          :test="test"
          :forceClear="forceClear"
          :createMenuActive="isPoiCreationMenuOpen"
          :showPoiCreationMarker="!showPoiCreationBackdrop && isPoiCreationMenuOpen"
          :isRunning="isRunning"
          v-on:menuOpen="mapClick"
          v-on:forceClear="forceClear = false"
        />
        <div class="content ion-text-center has-bottom-menu">
          <div class="walk-point-box" @click="goToLeaderboard" v-if="isAuthenticated">
            <div class="counter">{{ walkingScore }}</div>
            <svg class="chart-circle" id="chart_points" width="50%" viewBox="0 0 100 100">
              <linearGradient id="gradient-1">
                <stop offset="0" stop-color="#05FF00" />
                <stop offset="50" stop-color="#14AF11" />
              </linearGradient>
              <path class="underlay" d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50" />
            </svg>
            <div class="lottie-container">
              <lottie-animation
                class="checkmark"
                :autoPlay="true"
                :animationData="require('@/assets/images/lottie/leaderboard/explorer.json')"
                v-if="activePetLevel === 'explorer'"
              />
              <lottie-animation
                class="checkmark"
                :autoPlay="true"
                :animationData="require('@/assets/images/lottie/leaderboard/adventurer.json')"
                v-else-if="activePetLevel === 'adventurer'"
              />
              <lottie-animation
                class="checkmark"
                :autoPlay="true"
                :animationData="require('@/assets/images/lottie/leaderboard/newbie.json')"
                v-else-if="activePetLevel === 'newbie'"
              />
              <lottie-animation
                class="checkmark"
                :autoPlay="true"
                :animationData="require('@/assets/images/lottie/leaderboard/beginner.json')"
                v-else-if="activePetLevel === 'beginner'"
              />
            </div>
          </div>
          <div class="top-icons" v-show="!isPoiMenuOpen && !isPoiCreationMenuOpen && !poiSelectorOpened">
            <div class="dox-gif-btn dox-gif-btn-radar">
              <ion-img
                :src="require('@/assets/images/icons/walk/mapcenter.svg')"
                alt="Középre igazítás"
                @click="recenterMap"
              />
            </div>
            <div class="right-side">
              <div class="dox-gif-btn dox-gif-btn-radar" v-if="isAuthenticated">
                <lottie-animation
                  ref="anim"
                  :autoPlay="true"
                  :playInterval="6 * 1e4"
                  :animationData="require('@/assets/images/lottie/walk/submitpoi.json')"
                  @click="addNewPoi"
                />
              </div>

              <div class="dox-gif-btn dox-gif-btn-radar">
                <lottie-animation
                  ref="anim"
                  :autoPlay="true"
                  :playInterval="1000 * 60"
                  :animationData="require('@/assets/images/lottie/walk/event-calendar.json')"
                  @click="goToEventCalendar"
                />
              </div>
            </div>
          </div>
          <div class="centerContent">
            <div v-if="isLoading">
              <ion-spinner name="crescent"></ion-spinner>
            </div>
          </div>
          <div class="popup" v-bind:class="{ active: showPopup }" v-if="isAuthenticated">
            <span @click="closePopup()" class="close-btn"></span>
            <img :src="petSelectResult.selectedPet.avatar" alt="" />
            <div v-if="addToWalkContent">
              <p>
                Szeretnéd <strong>{{ petSelectResult.selectedPet.name }} kedvenedet is hozzáadni a sétához?</strong>
              </p>
              <ion-button class="btn-strong" @click="closePopup('addtowalk')" shape="round" strong="true"
                >Hozzáadás a sétához</ion-button
              >
            </div>
          </div>

          <div class="poi-selector-box" :class="{ active: poiSelectorOpened }">
            <div class="creation-content">
              <div class="buttons">
                <div
                  class="button"
                  :class="{ selected: selectedCreationType === 'poi' }"
                  @click="handlePoiSelectClick('poi')"
                >
                  <lottie-animation
                    ref="poiTypeAnim"
                    :animationData="require('@/assets/images/lottie/walk/poi-select.json')"
                  />
                  <p>Helyszín</p>
                </div>

                <div
                  class="button"
                  :class="{ selected: selectedCreationType === 'event' }"
                  @click="handlePoiSelectClick('event')"
                >
                  <lottie-animation
                    ref="eventTypeAnim"
                    :animationData="require('@/assets/images/lottie/walk/event-select.json')"
                  />
                  <p>Esemény</p>
                </div>
              </div>

              <ion-button
                class="btn-strong poi-add"
                shape="round"
                strong="true"
                @click="
                  () => {
                    poiSelectorOpened = false
                    isPoiCreationMenuOpen = true
                    showPoiCreationBackdrop = true
                  }
                "
              >
                {{ selectedCreationType === 'event' ? 'Esemény' : 'Helyszín' }}&nbsp;&nbsp; hozzáadása
              </ion-button>
            </div>
          </div>

          <div class="poi-creation-marker" :class="{ active: showPoiCreationBackdrop && isPoiCreationMenuOpen }">
            <div class="creation-content">
              <div class="icons">
                <ion-img :src="require(`@/assets/images/icons/walk/unknown-normal.svg`)" class="creation-marker" />
                <ion-img :src="require(`@/assets/images/icons/walk/create-arrow.svg`)" class="creation-arrow" />
              </div>

              <p>Koppints és mozgasd a jelölőt a {{ selectedCreationType === 'event' ? 'helyszínre' : 'helyre' }}!</p>
            </div>
          </div>

          <BottomArchMenu
            class="bottomMenu__pet"
            :class="{ isRunning }"
            :menuHeight="menuHeight"
            v-bind:isActive="menuActive"
            v-on:menuClosed="menuActive = false"
            v-if="isAuthenticated"
          >
            <div v-if="!isRunning">
              <ion-button shape="round" strong="true" class="btn-strong start-walking" @click="start()"
                >Séta indítása</ion-button
              >
              <ion-ripple-effect></ion-ripple-effect>
              <p>Jelenleg rejtve vagy a sétában,<br />és másokat sem láthatsz.</p>
            </div>
            <div v-else>
              <ion-button shape="round" class="btn-strong stop-walking" color="dark" @click="stop()"
                >Séta leállítása</ion-button
              >
              <ion-ripple-effect></ion-ripple-effect>
            </div>
            <template v-slot:petSelector>
              <PetSelector ref="petSelectorEl" v-on:onSelect="onPetSelect($event)"></PetSelector>
            </template>
          </BottomArchMenu>

          <BottomArchMenu
            class="bottomMenu__poi"
            :class="selectedPoi?.type"
            :menuHeight="poiMenuHeight"
            :isActive="isPoiMenuOpen"
            :canClose="isRunning"
            v-on:menuClosed="isPoiMenuOpen = false"
          >
            <template v-if="selectedPoi">
              <PresentDetails :present="selectedPoi" v-if="selectedPoi.type === 'present'" />
              <EventDetails :selectedEvent="selectedPoi" v-else-if="selectedPoi.type === 'event'" />
              <PoiDetails :selectedPoi="selectedPoi" v-else />
            </template>
          </BottomArchMenu>

          <BottomArchMenu
            class="bottomMenu__create"
            :menuHeight="poiCreationMenuHeight"
            :isActive="isPoiCreationMenuOpen"
            :canClose="isRunning"
            v-on:menuClosed="isPoiCreationMenuOpen = false"
          >
            <h1 v-if="selectedCreationType === 'poi'">Kedvenctérkép</h1>
            <h1 v-else>Eseménytérkép</h1>
            <p v-if="selectedCreationType === 'poi'">Van egy jó helyed? Add hozzá a térképhez!</p>
            <p v-else>Programot szerveznél? Add hozzá a térképhez!</p>

            <div class="poi-list" v-if="selectedCreationType === 'poi'">
              <div
                class="poi"
                @click="selectedPoiType = 'walkplace'"
                :class="{ selected: selectedPoiType === 'walkplace' }"
              >
                <div class="poi-icon">
                  <ion-img :src="require('@/assets/images/icons/walk/walkplace-marker.svg')" alt="Kutyafuttató" />

                  <ion-img
                    class="selected"
                    v-if="selectedPoiType === 'walkplace'"
                    :src="require('@/assets/images/icons/transparent_checkmark.png')"
                  />
                </div>

                <div class="poi-type-name" @click="openInfoPanel('walkplace')">
                  <h2>Kutyafuttató</h2>
                  <ion-img :src="require('@/assets/images/icons/walk/info.svg')" alt="Infó" />
                </div>
              </div>

              <div
                class="poi"
                @click="selectedPoiType = 'dogbeach'"
                :class="{ selected: selectedPoiType === 'dogbeach' }"
              >
                <div class="poi-icon">
                  <ion-img :src="require('@/assets/images/icons/walk/dogbeach-marker.svg')" alt="Kutyastrand" />

                  <ion-img
                    class="selected"
                    v-if="selectedPoiType === 'dogbeach'"
                    :src="require('@/assets/images/icons/transparent_checkmark.png')"
                  />
                </div>

                <div class="poi-type-name" @click="openInfoPanel('dogbeach')">
                  <h2>Kutyastrand</h2>
                  <ion-img :src="require('@/assets/images/icons/walk/info.svg')" alt="Infó" />
                </div>
              </div>

              <div
                class="poi"
                @click="selectedPoiType = 'dogfriendly'"
                :class="{ selected: selectedPoiType === 'dogfriendly' }"
              >
                <div class="poi-icon">
                  <ion-img :src="require('@/assets/images/icons/walk/dogfriendly-marker.svg')" alt="Kutyabarát hely" />

                  <ion-img
                    class="selected"
                    v-if="selectedPoiType === 'dogfriendly'"
                    :src="require('@/assets/images/icons/transparent_checkmark.png')"
                  />
                </div>

                <div class="poi-type-name" @click="openInfoPanel('dogfriendly')">
                  <h2>Kutyabarát hely</h2>
                  <ion-img :src="require('@/assets/images/icons/walk/info.svg')" alt="Infó" />
                </div>
              </div>
            </div>
            <div class="event-list" v-else-if="selectedCreationType === 'event' && isPoiCreationMenuOpen">
              <p>Esemény dátuma:</p>

              <div class="arch-picker">
                <VueScrollPicker :options="selectableYears" v-model="selectedYear" />
                <VueScrollPicker :options="selectableMonths" v-model="selectedMonth" />
                <VueScrollPicker :options="selectableDays" v-model="selectedDay" />
              </div>
            </div>

            <ion-button class="btn-strong poi-add" shape="round" strong="true" @click="handlePoiCreation"
              >Tovább a részletekhez</ion-button
            >
          </BottomArchMenu>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { defineComponent, computed, ref, onMounted, watch, reactive, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  IonContent,
  IonPage,
  IonSpinner,
  IonImg,
  IonButton,
  IonRippleEffect,
  useBackButton,
  alertController,
  isPlatform,
} from '@ionic/vue'
import useNavigation from '@/composables/navigation'
import {
  PET_FINDER_ROUTE,
  PET_WALKING_ROUTE,
  PAGE_WALK_LEADERBOARD_ROUTE,
  PAGE_POI_CREATE_ROUTE,
  PAGE_EVENT_CREATE_ROUTE,
  EVENT_CALENDAR_ROUTE,
} from '@/constants/routes'
import { removeCircleOutline, closeOutline } from 'ionicons/icons'
import useAuth from '@/composables/auth'
import usePets from '@/composables/pets'
import usePois from '@/composables/pois'
import useEvents from '@/composables/events'
import useToast from '@/composables/toast'
import BottomArchMenu from '@/components/Global/BottomArchMenu'
import PetSelector from '@/components/Pet/PetSelector'
import PetWalkingMap from '@/components/Pet/PetWalkingMap'
import useGeoLocation from '@/composables/geolocation'
import { Capacitor } from '@capacitor/core'

import 'snapsvg-cjs/dist/snap.svg.js'
import PresentDetails from '@/components/Pet/Walk/PresentDetails'
import PoiDetails from '@/components/Pet/Walk/PoiDetails'
import EventDetails from '@/components/Pet/Walk/EventDetails'
const Snap = window.Snap

export default defineComponent({
  name: 'PetWalking',
  components: {
    IonContent,
    IonPage,
    IonSpinner,
    IonImg,
    IonButton,
    IonRippleEffect,
    BottomArchMenu,
    PetSelector,
    PetWalkingMap,
    PresentDetails,
    PoiDetails,
    EventDetails,
  },
  setup() {
    const petSelectorEl = ref(null)
    const forceClear = ref(false)
    const walkingMap = ref(null)
    const mapComponentKey = ref(0)
    const test = false
    var refreshId = null
    if (test) {
      console.log('TEST DATA ACTIVE!')
    }
    useBackButton(10, () => {
      navigateTo({ name: PET_FINDER_ROUTE })
      console.log('Handler was called!')
    })
    const { isAuthenticated } = useAuth()
    const { navigateTo } = useNavigation()
    const { openToast } = useToast()
    const {
      activePet,
      setActivePet,
      PETS_STATE,
      stopWalking,
      startWalking,
      getActiveWalking,
      refreshWalking,
      setPetsInMap,
      setPetWalking,
      givePointToPet,
      getCurrentLeaderboard,
    } = usePets()
    const {
      checkLocationServiceRequirements,
      coords,
      //ios lassú
      watchPosition,
      clearWatchPosition,
      getCurrentPosition,
      askToTurnOnLocationServices,
    } = useGeoLocation()
    const route = useRoute()
    const router = useRouter()
    const myPets = computed(() => PETS_STATE.pets)
    const petsWalking = computed(() => PETS_STATE.petsWalking)
    const isLoading = ref(false)
    const showPopup = ref(false)
    const _menuActive = ref(false)
    const mapData = ref([])
    const activePetLevel = ref(activePet.value?.level ?? 'beginner')
    const menuActive = computed({
      get: () => {
        return showPopup.value == true ? true : _menuActive.value
      },
      set: val => (_menuActive.value = showPopup.value == true ? true : val),
    })
    const safeAreaBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab').slice(0, -2))
    const menuHeight = computed({
      get: () => {
        let baseHeight = 260
        if (isRunning.value == false) {
          baseHeight = isPlatform('ios') ? 340 : 300
        } else {
          if (myPets.value?.length > 0) {
            baseHeight = isPlatform('ios') ? 340 : 275
          }
        }

        baseHeight += safeAreaBottom

        return `${baseHeight}px`
      },
      set: val => (_menuActive.value = showPopup.value == true ? true : val),
    })
    const headerText = ref('Kalandra fel!')
    const mainText = ref('Vidd magaddal a DOXOCIAL-t is!')
    const petSelectResult = reactive({
      selectedPet: {},
    })
    const isRunning = computed(() => {
      return PETS_STATE.activeWalking
    })

    const addToWalkContent = ref(false)

    const isPoiMenuOpen = ref(false)
    const poiMenuHeight = computed(() => {
      return window.innerHeight - 175 + 'px'
    })
    const selectedPoi = ref(null)

    const isPoiCreationMenuOpen = ref(false)
    const poiSelectorOpened = ref(false)
    const selectedCreationType = ref('poi')
    const showPoiCreationBackdrop = ref(false)
    const poiCreationMenuHeight = computed(() => {
      if (isPlatform('ios')) return '450px'
      return '380px'
    })
    const selectedPoiType = ref('walkplace')

    const { getEvent } = useEvents()
    const { getPoi, getPresent, checkSubmitIsAvailable } = usePois()

    var testPos = {
      coords: {
        latitude: 46.07279,
        longitude: 18.26784,
      },
    }

    let poiLottieInterval, eventLottieInterval, fetchPointsInterval

    const poiTypeAnim = ref()
    const eventTypeAnim = ref()

    const walkingScore = ref(1)
    const totalWalkingScore = ref(1)
    const petWalkedDistances = ref([])
    const canSubmitPoi = ref(false)

    Number.prototype.pad = function (n) {
      return ('0' + this).slice(n || 2 * -1)
    }

    const selectedYear = ref(route.query.year ? parseInt(route.query.year) : new Date().getFullYear())
    const selectedMonth = ref(route.query.month ? parseInt(route.query.month) : new Date().getMonth() + 1)
    const selectedDay = ref(route.query.day ? parseInt(route.query.day) : new Date().getDate() + 1)

    const selectableYears = computed(() => {
      const currYear = new Date().getFullYear() + 5
      const lastYear = new Date().getFullYear()
      return Array.from({ length: currYear - lastYear + 1 }, (_, index) => lastYear + index).reverse()
    })
    const selectableMonths = computed(() => {
      const months = 12 - (selectedYear.value === new Date().getFullYear() ? new Date().getMonth() : 0)

      return Array.from({ length: months }, (_, i) => {
        i = i + 1 + (months === 12 ? 0 : new Date().getMonth())
        return i < 10 ? `0${i}` : i
      })
    })
    const selectableDays = computed(() => {
      const currentYear = new Date().getFullYear()
      const currentMonth = new Date().getMonth() + 1
      const minimumDay = new Date().getDate() - 1
      const lastDay = new Date(selectedYear.value, selectedMonth.value, 0).getDate()

      const minusDays =
        currentYear === selectedYear.value && selectedMonth.value === currentMonth.pad() ? minimumDay : 0

      return Array.from({ length: lastDay - minusDays }, (_, index) => (index + 1 + minusDays).pad())
    })

    var changePetOnCancel = false

    var mapTest = [
      { user_id: 292, longitude: 18.26784, latitude: 46.07279, pets: [525, 573] },
      { user_id: 293, longitude: 18.26804, latitude: 46.07329, pets: [535, 583] },
      { user_id: 294, longitude: 18.26824, latitude: 46.07379, pets: [545, 593] },
      { user_id: 295, longitude: 18.26844, latitude: 46.07429, pets: [555, 603] },
      { user_id: 296, longitude: 18.26864, latitude: 46.07479, pets: [565, 613] },
      { user_id: 297, longitude: 18.26884, latitude: 46.07529, pets: [575, 623] },
      { user_id: 298, longitude: 18.26904, latitude: 46.07579, pets: [585, 633] },
      { user_id: 299, longitude: 18.26924, latitude: 46.07629, pets: [595, 643] },
      { user_id: 300, longitude: 18.26944, latitude: 46.07679, pets: [625, 653] },
      { user_id: 301, longitude: 18.26964, latitude: 46.07729, pets: [635, 663] },
      { user_id: 302, longitude: 18.26984, latitude: 46.07779, pets: [645, 673] },
    ]

    const handlePoiSelectClick = type => {
      if (type === 'poi') {
        selectedCreationType.value = 'poi'
        poiTypeAnim.value?.playAnim()
        eventTypeAnim.value?.stop()

        clearInterval(eventLottieInterval)

        poiLottieInterval = setInterval(() => {
          poiTypeAnim.value?.playAnim()
        }, 3500)
      } else {
        selectedCreationType.value = 'event'
        eventTypeAnim.value?.playAnim()
        poiTypeAnim.value?.stop()

        clearInterval(poiLottieInterval)

        eventLottieInterval = setInterval(() => {
          eventTypeAnim.value?.playAnim()
        }, 6000)
      }
    }

    /*
      1. ha nem fut a séta, és petet vált, akkor a selectorban is változik
      2. ha nem fut a séta és olyanra vált, akinek nincs doxa, akkor automatikusan doxosra váltunk vissza
      3. ha fut a séta és vált akinek van doxa és már sétált, akkor váltunk a térképen is
      4. ha fut a séta és vált, akinek van doxa, de nincs a sétában akkor popup, hogy betegyük-e
      5. ha fut a séta és vált, de nincs dox, akkor popup, hogy csak dox-al sétálhat
    */
    const initHasDox = async () => {
      try {
        isLoading.value = true
        addToWalkContent.value = false
        // console.log('inithasdox', isRunning.value, PETS_STATE.activeWalking, activePet.value.id)
        if (PETS_STATE.activeWalking) {
          // van dox, séta aktív -> meg kell nézni, hogy sétált-e
          if (PETS_STATE.petsWalking.some(petId => petId == activePet.value.id)) {
            // ha sétált, akkor váltunk, de ez a frissítés miatt elvileg automatikusan megtörténik
            console.log('new active pet already walking -> just change')
            forceClear.value = true
            mapData.value = (await refreshWalking(coords, test ? mapTest : null)).data
          } else {
            // nem sétált, akar-e sétálni popup
            // console.log('do you wana walk?')
            changePetOnCancel = true
            addToWalkContent.value = true
            menuActive.value = true
            onPetSelect({ selectedPet: activePet.value })
            showPopup.value = true
          }
        } else {
          menuActive.value = true
          // séta inaktív van dox -> váltunk
          // szerver oldalnak jelezni kell, hogy változik az aktív pet, ha nem történik meg automatikusan!
        }
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const toggleMenu = () => {
      menuActive.value = !menuActive.value
    }

    const onPetSelect = async e => {
      petSelectResult.selectedPet = e.selectedPet
      showPopup.value = false
      headerText.value = 'Kalandra fel!'
      mainText.value = 'Vidd magaddal a DOXOCIAL-t is!'
      if (isRunning.value /* && petSelectResult.selectedPet.id != activePet.value.id*/) {
        mapData.value = (await refreshWalking(coords, test ? mapTest : null)).data
      }
    }

    const closePopup = async mode => {
      console.log('changepet:', changePetOnCancel)
      if (changePetOnCancel && !mode) {
        // majd arra kell váltani, amelyik aktív volt korábban
        console.log('setactive pet close popup', petsWalking.value[0])
        setActivePet({ id: petsWalking.value[0] })
      }
      changePetOnCancel = false
      showPopup.value = false
      addToWalkContent.value = false
      switch (mode) {
        case 'addtowalk':
          console.log('addtowalk', activePet.value.id)
          // onPetSelect({ selectedPet: { ...activePet.value, selected: true } })
          setPetWalking(activePet.value, true)
          petSelectorEl.value.initMyPets()
          // petváltásnál, ha nem sétált, a főpet helyén van az előző pet, ezt törölni kell
          forceClear.value = true
          mapData.value = (await refreshWalking(coords, test ? mapTest : null)).data
          break
        default:
          break
      }
    }

    const start = async () => {
      isLoading.value = true
      try {
        await getUserCoords()
        const res = await startWalking(coords, test ? mapTest : null)

        startRefreshing()

        if (Capacitor.isNative) {
          await checkLocationServiceRequirements()
          await askToTurnOnLocationServices()
          watchPosition()
        }

        // give the active pet a start point
        givePointToPet(activePet.value.id, 'start')

        setInterval(() => {
          if (isRunning.value) {
            petsWalking.value.forEach(async pet => {
              const { success } = await givePointToPet(pet, 'walked_time')

              if (activePet.value.id === pet && success) {
                walkingScore.value++
              }
            })
          }
        }, 1e3 * 60 * 60 + 1e3 * 60 * 5)

        mapData.value = res
        menuActive.value = false
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const stop = () => {
      if (showPopup.value) {
        showPopup.value = false
      }
      stopWalking(test ? mapTest : null)
      mapData.value = []
      stopRefreshing()
    }

    const getUserCoords = async () => {
      try {
        const res = await getCurrentPosition(test ? testPos : null)
        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    let markerClicked = false

    const mapClick = async (ev, id) => {
      if (ev) {
        setTimeout(() => {
          markerClicked = false
        }, 1000)
      }

      if (!ev && !showPopup.value && !markerClicked) {
        menuActive.value = false
      } else if (ev == 'markerClick') {
        markerClicked = true
        menuActive.value = true
      }

      if (!ev && isPoiMenuOpen.value && !markerClicked) {
        isPoiMenuOpen.value = false
        selectedPoi.value = null
      } else if (['presentClick', 'poiClick'].includes(ev)) {
        markerClicked = true
        isPoiMenuOpen.value = true

        try {
          if (ev === 'presentClick') {
            const present = await getPresent(id)

            selectedPoi.value = {
              type: 'present',
              ...present,
            }
          } else if (ev === 'poiClick') {
            if (id.type === 'event') {
              selectedPoi.value = await getEvent(id.id)
              selectedPoi.value.type = 'event'
            } else {
              selectedPoi.value = await getPoi(id.id)
            }
          }
        } catch (error) {
          console.error(error)

          isPoiMenuOpen.value = false

          await openToast({
            message: 'Hiba történt betöltés közben. Kérlek próbáld meg később.',
            color: 'warning',
          })
        }
      }

      if (!ev && (isPoiCreationMenuOpen.value || poiSelectorOpened.value) && !markerClicked) {
        poiSelectorOpened.value = false

        if (showPoiCreationBackdrop.value) {
          showPoiCreationBackdrop.value = false
        } else {
          isPoiCreationMenuOpen.value = false
        }
      }
    }

    const markerClick = () => {
      console.log('markerClick')
      menuActive.value = true
    }

    const init = async () => {
      try {
        if (route.query.eventMenuOpened) {
          handlePoiSelectClick('event')
          selectedYear.value = parseInt(route.query.year)
          selectedMonth.value = parseInt(route.query.month)
          selectedDay.value = parseInt(route.query.day)

          setTimeout(() => {
            menuActive.value = false
            isPoiCreationMenuOpen.value = true
            showPoiCreationBackdrop.value = true

            router.replace({ query: null })
          }, 1000)
        } else if (route.query.openEvent) {
          selectedPoi.value = await getEvent(route.query.openEvent)
          selectedPoi.value.type = 'event'

          walkingMap.value?.setMapCenter(selectedPoi.value.latitude, selectedPoi.value.longitude)

          menuActive.value = false
          isPoiMenuOpen.value = true

          router.replace({ query: null })
        } else if (route.query.openPoi) {
          selectedPoi.value = await getPoi(route.query.openPoi)

          walkingMap.value?.setMapCenter(selectedPoi.value.latitude, selectedPoi.value.longitude)

          menuActive.value = false
          isPoiMenuOpen.value = true

          router.replace({ query: null })
        }

        if (!isAuthenticated.value) return

        isLoading.value = true
        test ? await getActiveWalking(mapTest) : await getActiveWalking()
        await initHasDox()
        // elvileg mindig az lesz, csak, hogy ne eseen el böngészőben tesztkor
        if (Capacitor.isNative) {
          await checkLocationServiceRequirements()
          await askToTurnOnLocationServices()

          const { coords } = await getUserCoords()

          myPets.value.forEach(pet => {
            petWalkedDistances.value.push({
              id: pet.id,
              distance: 0,
              lat: coords.latitude,
              lng: coords.longitude,
            })
          })
        }

        await fetchUpdatePoints()

        fetchPointsInterval = setInterval(fetchUpdatePoints, 1e3 * 60)

        if (PETS_STATE.activeWalking && !refreshId) {
          watchPosition(test ? testPos : null)
          startRefreshing()
        }
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    const fetchUpdatePoints = async () => {
      try {
        const { total, points } = await getCurrentLeaderboard()

        walkingScore.value = points
        totalWalkingScore.value = total
      } catch (error) {
        console.error('fetchUpdatePoints', error)
      }
    }

    const stopRefreshing = () => {
      test ? clearWatchPosition(testPos) : clearWatchPosition()
      if (refreshId) {
        clearInterval(refreshId)
        refreshId = null
      }
      setPetsInMap([], null)
    }

    const startRefreshing = async () => {
      if (!refreshId) {
        await refreshData()
        refreshId = setInterval(async () => {
          //getUserCoords()
          await refreshData()
        }, 3000)
      }
    }

    const refreshData = async () => {
      if (test) {
        // console.log('coords changed', coords)
        mapTest = mapTest.map(td => {
          return { ...td, longitude: (td.longitude += 0.003) }
        })
        mapData.value = mapTest
      } else {
        mapData.value = (await refreshWalking(coords, test ? mapTest : null)).data
      }
    }

    // WALKING POINTS

    const levels = [
      {
        name: 'beginner',
        points: 100,
      },
      {
        name: 'newbie',
        points: 300,
      },
      {
        name: 'adventurer',
        points: 600,
      },
      {
        name: 'explorer',
        points: 1000,
      },
    ]

    const updateWalkingPoints = async points => {
      // find current level by points
      const currentLevel = levels.find(l => l.points > points)
      activePetLevel.value = currentLevel?.name ?? 'explorer'

      const paper = Snap('#chart_points')
      const myID = 'circle-graph-1'
      let prog = null
      if (document.getElementById(myID)) {
        prog = paper.select('#' + myID)
      } else {
        prog = paper.path('M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50')
      }

      const percentPoints = (parseInt(points) / currentLevel?.points ?? 1000) * 100

      const lineL = prog.getTotalLength()
      const oneUnit = lineL / 100
      const toOffset = lineL - oneUnit * (percentPoints > 100 ? 100 : percentPoints)
      const animTime = 0

      prog.attr({
        'stroke-dashoffset': lineL,
        'stroke-dasharray': lineL,
        id: myID,
      })

      prog.animate(
        {
          'stroke-dashoffset': toOffset,
        },
        animTime,
        a => Math.pow(a, 0.48),
      )
    }

    const recenterMap = async () => {
      const { coords } = await getUserCoords()

      if (coords.latitude && coords.longitude) {
        walkingMap.value?.setMapCenter(coords.latitude, coords.longitude)
      }
    }

    const addNewPoi = async () => {
      if (!canSubmitPoi.value) {
        const icon = require(`@/assets/images/icons/sad-dog.png`)

        const dialog = await alertController.create({
          cssClass: 'confirm-dialog poi-dialog',
          message: `
            <div class="poi-info">
              <img src="${icon}" class="icon" />
              <p>A térképet csak Felfedező szinten tudod szerkeszteni!</p>
            </div>
          `,
          buttons: [
            {
              text: 'Bezárás',
              role: 'cancel',
              cssClass: 'btn btn-link btn-block btn-popup-close',
            },
          ],
        })

        return await dialog.present()
      }

      menuActive.value = false
      poiSelectorOpened.value = true
    }

    const goToLeaderboard = async () => {
      await navigateTo({ name: PAGE_WALK_LEADERBOARD_ROUTE })
    }

    const goToEventCalendar = async () => {
      await navigateTo({ name: EVENT_CALENDAR_ROUTE })
    }

    watch(totalWalkingScore, () => {
      updateWalkingPoints(totalWalkingScore.value)
    })

    watch(route, () => {
      if (!isAuthenticated.value) return

      if (route.name == PET_WALKING_ROUTE) {
        console.log('route = petwalking')
        return init()
      }

      mapComponentKey.value += 1
    })

    const handlePoiCreation = async () => {
      const markerLocation = walkingMap.value.getPoiCreationMarkerPosition()

      if (selectedCreationType.value === 'poi') {
        await navigateTo({
          name: PAGE_POI_CREATE_ROUTE,
          params: {
            type: selectedPoiType.value,
            lat: markerLocation.lat(),
            lng: markerLocation.lng(),
          },
        })
      } else {
        await navigateTo({
          name: PAGE_EVENT_CREATE_ROUTE,
          params: {
            lat: markerLocation.lat(),
            lng: markerLocation.lng(),
          },
          query: {
            date: `${selectedYear.value}.${selectedMonth.value}.${selectedDay.value}`,
          },
        })
      }

      showPoiCreationBackdrop.value = false
      isPoiCreationMenuOpen.value = false
    }

    const openInfoPanel = async name => {
      let icon = require('@/assets/images/icons/walk/walkplace-marker.svg')
      let title = 'Kutyafuttató'
      let description = 'Kutyafuttatók, kiránduló- és olyan sétáló helyszínek,  amiket érdemes Kedvencekkel felkeresni.'

      if (name === 'dogbeach') {
        icon = require('@/assets/images/icons/walk/dogbeach-marker.svg')
        title = 'Kutyastrand'
        description = 'Kutyastrandok és olyan partszakaszok, ahol Kedvencek is megmártózhatnak a vízben.'
      } else if (name === 'dogfriendly') {
        icon = require('@/assets/images/icons/walk/dogfriendly-marker.svg')
        title = 'Kutyabarát hely'
        description =
          'Olyan szolgáltatók, szállásadók, vendéglátóhelyek, munkáltatók ahol szívesen látják a Kedvenceket is.'
      }

      const dialog = await alertController.create({
        cssClass: 'confirm-dialog poi-dialog',
        message: `
          <div class="poi-info">
            <img src="${icon}" class="icon" />
            <h1>${title}</h1>
          </div>
          <p>${description}</p>
        `,
        buttons: [
          {
            text: 'Bezárás',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block btn-popup-close',
          },
        ],
      })

      return dialog.present()
    }

    onMounted(async () => {
      menuActive.value = true
      handlePoiSelectClick('poi')
      init()

      try {
        if (!isAuthenticated.value) return
        const { can_submit } = await checkSubmitIsAvailable()

        if (can_submit) {
          canSubmitPoi.value = true
        }
      } catch (error) {
        console.log(error)
        canSubmitPoi.value = activePet.value?.level === 'explorer'
      }
    })

    onUnmounted(() => {
      console.log('walking unmounted')
      clearInterval(poiLottieInterval)
      clearInterval(eventLottieInterval)
      clearInterval(fetchPointsInterval)
    })

    return {
      mapComponentKey,
      navigateTo,
      PET_FINDER_ROUTE,
      removeCircleOutline,
      isLoading,
      BottomArchMenu,
      menuActive,
      isPoiMenuOpen,
      menuHeight,
      poiMenuHeight,
      toggleMenu,
      onPetSelect,
      petSelectResult,
      start,
      isRunning,
      stop,
      showPopup,
      closePopup,
      closeOutline,
      headerText,
      mainText,
      petsWalking,
      mapClick,
      markerClick,
      mapData,
      test,
      addToWalkContent,
      petSelectorEl,
      forceClear,
      recenterMap,
      addNewPoi,
      walkingMap,
      selectedPoi,
      walkingScore,
      goToLeaderboard,
      isPoiCreationMenuOpen,
      poiSelectorOpened,
      showPoiCreationBackdrop,
      poiCreationMenuHeight,
      selectedPoiType,
      handlePoiCreation,
      activePet,
      openInfoPanel,
      activePetLevel,
      selectedCreationType,
      poiTypeAnim,
      eventTypeAnim,
      selectableYears,
      selectableMonths,
      selectableDays,
      selectedYear,
      selectedMonth,
      selectedDay,
      handlePoiSelectClick,
      goToEventCalendar,
      isAuthenticated,
    }
  },
})
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Ionicons';
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1');
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix')
      format('embedded-opentype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1') format('truetype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1') format('woff'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&family=Poppins:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

ion-content {
  --keyboard-offset: 0 !important;
}

.poi-add {
  --box-shadow: none;
  --background: #e6224a;
}

.container {
  transition: 0.4s ease-in;
  overflow: hidden;

  &.centered {
    display: flex;
    align-items: center;
  }

  .background {
    position: absolute;
    width: 120%;
    height: 120%;
    left: 0;
    top: -8%;
    transform: translateX(-8%);

    ion-img {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }
}

.content {
  height: calc(100vh - 80px);
  display: flex;
  flex-flow: column;
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  justify-content: space-between;

  &.has-bottom-menu {
    margin-bottom: 0;
  }

  h2 {
    font-weight: bold;
  }

  .top-icons {
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    z-index: 5;

    .right-side {
      .dox-gif-btn {
        margin-bottom: 17px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .dox-gif-btn {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      cursor: pointer;
      background-color: #fff;
      pointer-events: bounding-box;

      ion-img {
        width: 28px;
      }

      .lottie-animation {
        width: 28px;
        position: relative;
        top: 4px;
      }
    }

    .dox-gif-btn.dox-gif-btn-radar {
      padding: 8px;
    }
    .dox-gif-btn.dox-gif-btn-walking.active {
      padding: 3px;
    }

    .top-icon {
      @media (max-width: 767px) {
        width: 25px;
        min-width: 25px;
        height: 25px;
      }

      @media (min-width: 768px) {
        width: 31px;
        min-width: 31px;
        height: 31px;
      }
    }
  }
  .centerContent {
    transform: translateY(10vh);

    @media (min-height: 750px) {
      transform: translateY(45%);
    }

    p {
      font-size: 12px;
      margin-top: -10px;
      margin-left: 50px;
      margin-right: 50px;
    }

    h5 {
      margin-top: -10px;
      font-size: 20px;
      font-family: var(--ion-poppins-font);
      margin-bottom: 10px;
    }

    .webshopBtn {
      width: 50%;
      font-size: 12px;
    }
  }

  .bottomMenu {
    z-index: 5;
    transform: translateX(-9%);

    &__pet {
      z-index: 5;
      padding-top: 13px;
      width: 150%;
      transform: translateX(-17.25%);
      background: linear-gradient(0deg, #efefef 0%, #ffffff 27.2%);

      ion-button {
        max-width: 80%;
      }

      p {
        color: #9e9e9e;
        margin-top: 5px;
        font-size: 12px;
      }

      .start-walking {
        --background: #e6224a;
        --box-shadow: none;
      }

      .stop-walking {
        margin-top: 0;
      }

      .pet-select {
        margin-top: 0;
      }
    }

    &__poi {
      z-index: 6;
      width: 200%;
      overflow: unset;
      transform: translateX(-25%);

      .present__icon {
        position: relative;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 6;
        min-width: 55px;
        max-width: 55px;
        min-height: 55px;
        background: #fff;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        .lottie-animation {
          max-width: 50px;
        }
      }

      h1 {
        margin-top: -40px;
        font-size: 15px !important;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
      }

      .difficulty {
        display: flex;
        justify-content: center;

        ion-img {
          margin: 0 1.5px;
          min-width: 14px;
        }

        &__title {
          font-size: 10px;
          font-weight: 400;
        }
      }

      .tips {
        max-height: 450px;
        overflow-y: auto;

        @media (max-height: 700px) {
          padding-bottom: 250px;
        }

        .tip {
          max-width: calc(100vw - 70px);
          margin: 0 auto;
          box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.07);
          border-radius: 16px;
          padding: 12px 20px;
          display: flex;
          align-items: center;
          text-align: left;
          margin-bottom: 17px;

          &.inactive {
            color: #bababa;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          .icon {
            max-height: 26px;
            flex-shrink: 0;
            margin-right: 20px;
          }

          h1 {
            margin: 0;
            font-size: 12px;
            font-weight: 900;
            font-family: 'Montserrat', sans-serif;
          }

          p {
            margin: 0;
            margin-top: 5px;
          }
        }
      }
    }

    &__create {
      z-index: 6;
      width: 175%;
      transform: translateX(-22%);
      padding-top: 40px;

      h1 {
        margin-top: -5px;
        margin-bottom: 8px;
        font-size: 17px !important;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
      }

      p {
        font-size: 12px;
        margin-top: -5px;
      }

      ion-button {
        --background: #e6224a;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 900;
        max-width: 238px;
        margin: 0 auto;
      }

      .poi-list {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        margin-top: 24px;
        margin-bottom: 30px;

        .poi {
          max-width: 112px;
          width: 100%;
          display: flex;
          text-align: center;
          flex-direction: column;
          align-items: center;
          margin-right: 8px;

          &:last-of-type {
            margin-right: 0;
          }

          &.selected {
            .poi-type-name {
              opacity: 1;
              transition: opacity 0.25s ease-in-out;
            }
          }

          .poi-icon {
            position: relative;

            min-width: 71px;
            max-width: 71px;
            min-height: 71px;
            background: #fff;
            border-radius: 9999px;
            box-shadow: -2px 6px 10px rgba(0, 0, 0, 0.08);

            display: flex;
            justify-content: center;
            align-items: center;

            margin-bottom: 16px;

            ion-img {
              min-width: 30px;
              max-width: 30px;
            }

            .selected {
              position: absolute;
              right: 10px;
              bottom: 12px;
              animation: selectType 0.25s ease-in-out;

              ion-img {
                min-height: 15px;
              }
            }
          }

          .poi-type-name {
            display: flex;
            align-items: center;
            opacity: 0.5;
            transition: opacity 0.25s ease-in-out;

            h2 {
              margin-right: 5px;
            }

            ion-img {
              min-width: 12px;
            }
          }

          h2 {
            margin: 0;
            font-size: 11px !important;
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
          }
        }
      }

      .event-list {
        p {
          font-size: 12px;
          line-height: 16.34px;
          letter-spacing: -0.64px;
          color: #989898;
          margin-top: 23px;
        }

        .arch-picker {
          display: flex;
          height: 75px;
          max-width: 278px;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@keyframes selectType {
  from {
    opacity: 0;
    scale: 0;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.stop-walking {
  margin-top: 20px;
}

.menu-content {
  ion-button {
    width: 95%;
  }
}

.dog-head-icon {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin: auto;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
  background-color: #fff;
  border-radius: 35px 35px 0 0;
  width: min(80%, 300px);
  margin: 0 auto;
  padding: 40px 50px 100px;
  transition: all 0.5s ease-in-out;
  bottom: -400px;
  font-size: 10px;

  ion-button {
    font-size: 11px;
  }

  &.active {
    bottom: 190px;

    @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 220px;
    }
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 14px;
    width: 30px !important;
    height: 30px !important;
    font-weight: 700 !important;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    &::after {
      font-family: 'Ionicons';
      content: '\f129';
    }
  }

  img {
    border: 3px solid #fff;
    border-radius: 50%;
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
    background: #fff;
    margin: auto;
    filter: grayscale(100%);
  }
}

.map-container {
  z-index: 5;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  height: calc(100vh - 55px);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0;
    display: block;
    z-index: 20;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  &.disabled {
    &::after {
      transition: 0.2s ease-in-out;
      opacity: 0.65;
      pointer-events: all;
    }
  }
}

ion-content::part(scroll) {
  overflow-y: hidden !important;
}

.walk-point-box {
  position: fixed;
  bottom: calc(75px + env(safe-area-inset-bottom));
  left: 1rem;
  z-index: 6;

  .chart-circle {
    position: absolute;
    left: -3px;
    bottom: -3px;
    min-width: calc(50px + 6px);
    min-height: calc(50px + 6px);

    stroke: url(#gradient-1);
    stroke-width: 8;
    fill: transparent;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform: rotate(-90deg);

    .underlay {
      stroke-width: 8;
      fill: transparent;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: transparent;
    }
  }

  .counter {
    z-index: 11;
    background: #fff;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: #2fe449;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  }

  .lottie-container {
    background: white;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    min-width: 18px;
    min-height: 18px;
    max-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkmark {
      min-width: 15px;
      max-width: 15px;

      position: relative;
      bottom: -1.5px;
    }
  }
}

.poi-selector-box {
  display: none;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  z-index: 10;
  transform: translateY(calc(-50% - 80px));

  &.active {
    display: block;
  }

  .creation-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .buttons {
      display: flex;
      align-items: center;

      .button {
        width: 124px;
        height: 122px;
        border-radius: 9999px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: transparent;
        margin-right: 23px;

        .lottie-animation {
          width: 58px;
          height: 58px;
          margin-top: -10px;
          margin-bottom: 4px;
        }

        &:last-of-type {
          margin-right: 0;

          .lottie-animation {
            width: 60px;
            height: 60px;
            margin-bottom: 3px;
          }
        }

        transition: all 0.3s ease-in-out;

        &.selected {
          background: rgba(182, 182, 182, 0.3);
          box-shadow: -6px 8px 10px rgba(0, 0, 0, 0.12);
        }

        p {
          font-weight: 800;
          font-family: 'Montserrat', sans-serif;
          font-size: 16px;
          line-height: 18.29px;
          letter-spacing: -0.64px;
          text-align: center;
          margin: 0;
          color: #fff;
        }
      }
    }

    ion-button {
      height: 50px;
      border-radius: 34px;
      font-weight: 900;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.64px;
      margin-top: 30px;
    }
  }
}

.poi-creation-marker {
  display: none;
  height: calc(100vh - 280px);
  position: absolute;
  top: calc(-130px + 55px);
  pointer-events: none;
  left: 0;
  right: 0;
  z-index: 10;

  &.active {
    display: block;
  }

  .creation-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .icons {
      width: 32px;
      height: 41px;
      margin: 0 auto;
      position: relative;
    }

    .creation-marker {
      width: 32px;
      height: 41px;
      margin: 0 auto;
    }

    .creation-arrow {
      position: absolute;
      top: 31px;
      right: -29px;
    }

    p {
      position: relative;
      white-space: nowrap;
      text-align: center;
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: 900;
      color: #fff;
      bottom: -46px;
      font-size: 15px;
    }
  }
}
</style>
