<template>
  <div>
    <div class="map-wrapper">
      <div id="map" :class="{ createMenu: creationMenuActive }"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, toRefs, ref, watch } from 'vue'
import usePets from '@/composables/pets'
import useAuth from '@/composables/auth'
import usePois from '@/composables/pois'
import useEvents from '@/composables/events'
import useGeoLocation from '@/composables/geolocation'
import { PET_ROUTE } from '@/constants/routes'
import { MAPS_API } from '@/constants/types'
import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
// import { GoogleMap, GoogleMapsEvent, GoogleMapsLatLng } from 'ionic-native';
import httpClient from '@/core/api/api.service'
import useNavigation from '@/composables/navigation'
import { alertController, isPlatform } from '@ionic/vue'
import { useBackButton } from '@ionic/vue'

export default defineComponent({
  name: 'PetWalkingMap',
  props: {
    data: {},
    test: Boolean,
    forceClear: Boolean,
    createMenuActive: Boolean,
    showPoiCreationMarker: Boolean,
    isRunning: Boolean,
  },
  components: {},
  emits: ['menuOpen', 'forceClear'],
  setup(props, { emit, expose }) {
    useBackButton(10, () => {
      navigateTo({ name: PET_ROUTE })
      console.log('Handler was called!')
    })

    const { navigateTo } = useNavigation()

    const userId = computed(() => {
      if (AUTH_STATE && AUTH_STATE.user) {
        return AUTH_STATE.user.id
      } else {
        return null
      }
    })

    const mapData = toRefs(props).data
    const isRunning = toRefs(props).isRunning
    const map = ref(null)
    var markerClusterer = null
    const pets = ref([])
    const markers = []
    const presentMarkers = []
    const mapLoaded = ref(false)

    const { activePet, PETS_STATE, setPetsInMap /*, activePet*/ } = usePets()
    const { AUTH_STATE } = useAuth()
    const { coords, getCurrentPosition } = useGeoLocation()
    const petsWalking = computed(() => PETS_STATE.petsWalking)

    const creationMenuActive = toRefs(props).createMenuActive

    const { getPresents, getPois } = usePois()
    const { getEvents } = useEvents()

    const poiCreationMarker = ref(null)
    const previewPetMarker = ref(null)

    var first = 0

    //MAPS_API_IOS
    const loader = new Loader({
      apiKey: MAPS_API,
      version: 'weekly',
      libraries: [],
    })

    const openMenu = () => {
      emit('menuOpen', 'markerClick')
    }

    const addMarker = (mainPet = false, position, pet, iconPos, anchor) => {
      if (pet && map.value) {
        var marker = new window.google.maps.Marker({
          position: { lat: Number(position.latitude), lng: Number(position.longitude) },
          map: map.value,
          optimized: false,
          zIndex: mainPet ? 99999 : 1,
        })
        marker = createMarkerIcon(mainPet, marker, pet, iconPos, null, null, anchor)
        markerClusterer.addMarker(marker)
        const listener = window.google.maps.event.addListener(marker, 'click', ev => {
          pet.current ? openMenu() : navigateTo({ name: PET_ROUTE, params: { id: pet.id, refresh: true } })
          // ev.preventDefault()
          // console.log('ev', ev)
          // ev.domEvent.stopPropagation()
          // ev.stop()

          ev.domEvent.cancelBubble = true
        })

        return { marker, listener }
      }
    }

    const removeMarker = (marker, refresh, forceRefresh = true) => {
      // a kirajzolás miatt a userhez tartozó összes markert el kell venni
      const userMarkers = markers.filter(m => m.userId == marker.userId)
      userMarkers.forEach(um => {
        um.marker?.setMap(null)
        // console.log('pet removed:', um.petId, um.userId)
        // marker.marker.setMap(null)
        if (um.listener) {
          // console.log('remove listener')
          window.google.maps.event.removeListener(um.listener)
        }
        markerClusterer.removeMarker(um.marker)

        const index = markers.findIndex(m => m.petId == um.petId)
        markers.splice(index, 1)
      })

      // a következő frissítési ciklusban, ami kell visszakerül, ha ez nem elég gyors, akkor itt
      // meg kell hívni a frissítést

      if (!forceRefresh) return

      refreshData(mapData, mapLoaded)
      if (refresh) {
        refreshData(mapData, mapLoaded)
      }
    }

    const setMarkers = async data => {
      // console.log('active user: ', userId.value)
      if (mapLoaded.value) {
        const allPetIds = []
        const bounds = map.value.getBounds()
        for await (const d of data) {
          const userPets = []
          const inBounds = true /*bounds ? bounds.contains({ lat: d.latitude, lng: d.longitude }) : false*/
          // console.log('marker in bounds:', d.pets[0], inBounds, d.latitude, d.longitude)
          // ha aktuális user, akkor ha nincs a térképen, akkor is frissíteni kell, mert mozgatva lesz a térkép
          if (inBounds || d.user_id == userId.value) {
            for await (const petId of d.pets) {
              allPetIds.push({
                petId,
                userId: d.user_id,
                latitude: d.latitude,
                longitude: d.longitude,
                current: d.user_id == userId.value,
                // majd szerver oldalról kell, hogy jöjjön
                active: petId.active,
              })
              // ha még nem lett lekérve a pet, akkor lekérjük
              let pet = pets.value.find(p => p.id == petId)
              if (!pet) {
                if (!props.test) {
                  pet = await fetchPet(petId)
                } else {
                  pet = {
                    id: petId,
                    avatar: null,
                  }
                }
                pets.value.push(pet)
              }
              userPets.push({ avatar: pet.avatar, id: pet.id, active: pet.active, current: d.user_id == userId.value })
            }
            // ha nincs még hozzáadva a merker tömbhöz, akkor hozzáadjuk
            addUserMarkers(d.user_id, { latitude: d.latitude, longitude: d.longitude }, userPets)
          } else {
            // console.log('marker not in map:', d.pets[0], inBounds)
          }
        }
        // kiszedni data-ból currentet és annak a koordinátái lesznek
        setPetsInMap(allPetIds)

        // van-e olyan, ami nincs a data-ban?
        var moved = false
        for (const m of markers) {
          const mIndex = allPetIds.findIndex(all => all.petId == m.petId)
          if (mIndex == -1) {
            removeMarker(m, true)
          } else {
            // ha megvan, és látszik, akkor frissítjük a pozíciót
            // térképet elég egyszer mozgatni (több current is lehet, ha a usernek több pet van fent)
            moved = moveMap(m, allPetIds, bounds, moved)
          }
        }

        // csak ha látszik cluster
        // nem frissül
        // markerClusterer.render()
      }
    }

    const fetchPet = async petId => {
      try {
        // console.log('fetchpet', petId)
        const { data } = await httpClient.get('pets', petId)
        return Promise.resolve(data.data)
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    }

    const addUserMarkers = (uid, position, pets) => {
      const r = isPlatform('ios') ? 34 : 30
      const deltaAngle = 45 * (Math.PI / 180) // Convert degrees to radians

      if (props.forceClear) {
        console.log('forceclear')
        markerData = markers.find(m => m.userId == uid)
        if (markerData) {
          removeMarker(markerData, false)
        }
        emit('forceClear', false)
      }
      // 0. helyen az aktív pet jön
      const activePetIndex = [0]
      const activePetId = pets[0].id
      const activePet = pets[0]
      if (activePetIndex !== -1) {
        pets.splice(activePetIndex, 1)
      }

      // ha ferdén alatta kell kezdeni, akkor nem kell a -1
      const startAngle = (pets.length - 1) * deltaAngle
      var clearData = false
      pets.some(p => {
        var markerData = markers.find(m => m.petId == p.id)
        if (!markerData) {
          // ha egy nincs meg, akkor is töröljük az összeset az újrarajzolás miatt
          clearData = true
          return true
        }
        // else {
        //   setMarkerIcon(markerData, pets)
        // }
      })

      var markerData = null
      if (clearData) {
        // elég egyet törölni, az törli a többit is
        markerData = markers.find(m => m.userId == uid)
        if (markerData) {
          removeMarker(markerData, false)
        }
        // az összeset hozzáadjuk
        var i = 0
        // const testPets = []
        // for (let index = 0; index < 9; index++) {
        //   testPets.push(pets[0])
        // }
        var reversePets = pets.reverse()
        reversePets.forEach(p => {
          const iconPos = getIconPos(i, r, deltaAngle, startAngle)
          // console.log('marker pet added:', i, p.id, uid, iconPos.x, iconPos.y)
          const { marker, listener } = addMarker(
            false,
            position,
            p,
            { x: 0, y: 0 },
            { x: iconPos.x - 10, y: iconPos.y },
          )
          markerData = { userId: uid, marker, current: uid == userId.value, petId: p.id, listener }
          markers.push(markerData)
          i++
        })
      }
      // activepet külön középre
      // console.log('active pet id:', activePet.id, activePetId)
      markerData = markers.find(m => m.petId == activePetId)
      if (!markerData) {
        // console.log('active pet added:', activePetId, uid, 0, 0)
        const { marker, listener } = addMarker(true, position, activePet, { x: 0, y: 0 })
        markerData = { userId: uid, marker, current: uid == userId.value, petId: activePet.id, listener }
        markers.push(markerData)
      }
    }

    const getIconPos = (i, r, deltaAngle, startAngle) => {
      // takarás miatt visszafelé kell kirajzolni ezért a startAngle-t csökkentjük
      // fok helyett radiánban kell számolni ezért PI/180
      const x = r * Math.cos(startAngle - (i * deltaAngle + 180 * (Math.PI / 180)))
      const y = r * Math.sin(startAngle - (i * deltaAngle + 180 * (Math.PI / 180)))
      return { x, y }
    }

    String.prototype.insert = function (index, string) {
      var ind = index < 0 ? this.length + index : index
      return this.substring(0, ind) + string + this.substr(ind)
    }

    /*
    const getRoundedPicture = function (url) {
      // url: 'https://res.cloudinary.com/doxocial/image/upload/w_280,h_280,c_fill,r_max,bo_30px_solid_white/znwclpddq8exd4250yjr.png' || require('@/assets/images/icons/marker.png'),
      let str = url.insert(60, ',c_fill,r_max,bo_30px_solid_white')
      // console.log(str)
      return str
    }
    */

    const getRoundedImageWithSpace = function (url, mainPet, petId) {
      let orgImg = url.split('/')
      orgImg = orgImg[orgImg.length - 1]
      orgImg = orgImg.substring(0, orgImg.length - 4)
      // url: 'https://res.cloudinary.com/doxocial/image/upload/w_280,h_280,c_fill,r_max,bo_30px_solid_white/znwclpddq8exd4250yjr.png' || require('@/assets/images/icons/marker.png'),
      //let str = url.insert(60, ',c_fill,r_max,bo_30px_solid_white')
      let start = 'https://res.cloudinary.com/doxocial/image/upload/l_'
      let checkmark = '/l_transparent_checkmrak_cjfdyp_pzs1s4,w_130,y_90,x_90'
      let end1 = ',w_90,h_90,g_center,y_0,x_0,w_240,h_240,c_fill,r_max,bo_25px_solid_white'
      let end2 = '/seta_petback_qt021n.png'

      let finalString = start + orgImg + end1
      if (mainPet && activePet.value.id == petId) {
        finalString += checkmark
      }
      finalString += end2

      //,w_90,g_center,y_0,x_0,w_400,h_400,c_fill,r_max,bo_30px_solid_white/empty_tiftej.png
      // red_checkmark_pbivwu
      return finalString
    }

    const createMarkerIcon = (mainPet = false, marker, pet, iconPos, extendedSize, iconUrl = null, anchor) => {
      var point = new window.google.maps.Point(iconPos.x, iconPos.y)

      const size = mainPet ? 70 : 50
      var icon = {
        //url: 'https://res.cloudinary.com/doxocial/image/upload/l_znwclpddq8exd4250yjr,w_90,g_center,y_0,x_0,w_400,h_400,c_fill,r_max,bo_30px_solid_white/empty_tiftej.png',
        url: iconUrl
          ? iconUrl
          : pet?.avatar
          ? getRoundedImageWithSpace(pet.avatar, mainPet, pet.id)
          : require('@/assets/images/icons/marker.png'),
        scaledSize: new window.google.maps.Size(extendedSize?.w ?? size, extendedSize?.h ?? size),
        origin: point,
        anchor: {
          x: (anchor?.x ?? point.x) + size / 2,
          y: (anchor?.y ?? point.y) + size / 2,
        },
      }
      marker.setIcon(icon)
      return marker
    }

    const moveMap2 = (m, allPetIds, bounds, moved) => {
      const d = allPetIds?.find(all => all.petId == m.petId)
      // const onMap = bounds && bounds.contains(m.marker.getPosition())
      if (d) {
        // console.log('on map', onMap)
        // if (onMap) {
        moveTransition(m.marker, m.marker.position, d)
        m.marker.setPosition({ lat: d.latitude, lng: d.longitude })
        // }

        // ha mozgatni kell a térképet, úgy hogy a főpet középen legyen, akkor ezt vissza
        // addig ki lehetne pakolni egy olyan részre, ami csak egyszer fut le, de akkor nehezebb visszaállítani
        //if (/*onMap ||*/ first) {
        if (m.current && !moved) {
          if (first) {
            first = false
          }
          // console.log('movemap: ', m)
          map.value.panTo({ lat: Number(d.latitude), lng: Number(d.longitude) })
          return true
        }
        //}
      }
    }

    const moveMap = (m, allPetIds, bounds, moved) => {
      if (PETS_STATE?.pets?.findIndex(item => item.id == m.petId) <= -1) {
        return false
      }
      const d = allPetIds.find(all => all.petId == m.petId)
      const onMap = bounds && bounds.contains({ lat: 0, lng: 0 })
      if (d) {
        //console.log('on map', m.marker.getPosition())
        // if (onMap) {
        moveTransition(m.marker, m.marker.position, d)
        //m.marker.setPosition({ lat: d.latitude, lng: d.longitude })
        // }
        //console.log(Math.abs(Number(d.latitude)))
        //console.log(map.value)
        // ha mozgatni kell a térképet, úgy hogy a főpet középen legyen, akkor ezt vissza
        // addig ki lehetne pakolni egy olyan részre, ami csak egyszer fut le, de akkor nehezebb visszaállítani
        if (onMap && first < 10) {
          map.value.panTo({ lat: Number(d.latitude), lng: Number(d.longitude) })
          //return true
          if (Math.abs(Number(d.latitude)) > 1 && Math.abs(Number(d.longitude)) > 1) {
            first = 10
          }

          if (m.current && !moved) {
            first++
            // console.log('movemap: ', m)
            map.value.panTo({ lat: Number(d.latitude), lng: Number(d.longitude) })
            return true
          }
        }
        //}
      }
    }

    const delay = 25
    const numDeltas = 40

    const moveTransition = (marker, fromCoords, toCoords) => {
      const deltaLng = (toCoords.longitude - fromCoords.lng()) / numDeltas
      const deltaLat = (toCoords.latitude - fromCoords.lat()) / numDeltas
      // console.log('move delta:', deltaLng, deltaLat)
      if (deltaLng > 0 || deltaLat > 0) {
        // console.log('marker setposition', marker.position.lat() + deltaLat, marker.position.lng() + deltaLng)
        for (let i = 0; i < numDeltas; i++) {
          moveMarker(marker, i, deltaLat, deltaLng, fromCoords)
        }
      }
    }

    const moveMarker = (marker, i, deltaLat, deltaLng, fromCoords) => {
      setTimeout(() => {
        const lat = marker.position.lat() + deltaLat
        const lng = marker.position.lng() + deltaLng
        marker.setPosition({ lat, lng })
        if (fromCoords.lng() == 0 || fromCoords.lat() == 0) {
          moveMap2()
        }
      }, delay * i)
    }

    // cluster csak akkor frissül, ha töröljük a benne lévő elemeket
    // https://github.com/Leaflet/Leaflet.markercluster/issues/649
    const clearClusters = (useMarkers = false) => {
      if (mapLoaded.value) {
        if (useMarkers) {
          markers.forEach(m => removeMarker(m, false, false))
        } else {
          //const bounds = map.value.getBounds()
          for (const cluster of markerClusterer.clusters) {
            // csak, ha több marker van benne
            if (cluster.count > 1) {
              //const cp = cluster.marker.getPosition()
              // csak ha látszik
              //if (bounds.contains(cp)) {
              // csak a clusterben lévő markerek
              cluster.markers.forEach(cm => {
                const i = markers.map(mmm => mmm.marker).indexOf(cm)
                if (i > -1) {
                  const markerData = markers[i]
                  removeMarker(markerData)
                }
              })
              //}
            }
          }
        }
      }
    }

    const refreshData = async (mapData, mapLoaded) => {
      // console.log('refreshdata', mapData.value)
      if (mapData.value && mapData.value.length && mapLoaded) {
        // console.log('data changed')
        // markerClusterer.clearMarkers()
        // markers.splice(0, markers.length)

        clearClusters(true)
        await setMarkers(mapData.value)
      } else if (mapLoaded && mapData.value?.length === 0) {
        clearClusters(true)
      }
    }

    const setMapCenterTo = ref()

    const setMapCenter = (lat, lng) => {
      try {
        map.value.panTo(new window.google.maps.LatLng(lat, lng))
      } catch {
        setMapCenterTo.value = { lat, lng }
      }
    }

    watch(
      () => mapLoaded.value && setMapCenterTo.value && map.value,
      () => {
        if (!map.value) return
        if (!setMapCenterTo.value) return

        map.value.panTo(new window.google.maps.LatLng(setMapCenterTo.value?.lat, setMapCenterTo.value?.lng))
        setMapCenterTo.value = undefined
      },
    )

    const getCustomMarkerIcon = marker => {
      if (marker.type === 'present') {
        return marker.isActive
          ? require('@/assets/images/icons/walk/gift.svg')
          : require('@/assets/images/icons/walk/giftlost.svg')
      } else if (marker.type === 'walkplace') {
        return require('@/assets/images/icons/walk/walkplace-marker.svg')
      } else if (marker.type === 'dogbeach') {
        return require('@/assets/images/icons/walk/dogbeach-marker.svg')
      } else if (marker.type === 'dogfriendly') {
        return require('@/assets/images/icons/walk/dogfriendly-marker.svg')
      } else if (marker.type === 'unknown') {
        return require(`@/assets/images/icons/walk/unknown-normal.svg`)
      } else if (marker.type === 'unknown-pink') {
        return require(`@/assets/images/icons/walk/unknown-pink.svg`)
      } else if (marker.type !== 'not_reviewed_poi') {
        return require(`@/assets/images/icons/walk/poi/${marker.type}.svg`)
      }

      return require('@/assets/images/icons/walk/unknown-marker.svg')
    }

    watch([mapData, mapLoaded], async () => {
      await refreshData(mapData, mapLoaded)
    })

    watch([isRunning, mapLoaded], async () => {
      if (!mapLoaded.value) return

      // if (isRunning.value === true) {
      //   presentMarkers.forEach(m => m.setMap(map.value))
      // } else {
      //   presentMarkers.forEach(m => m.setMap(null))
      // }

      if (isRunning.value === false && !previewPetMarker.value) {
        let marker = new window.google.maps.Marker({
          position: { lat: Number(coords.latitude), lng: Number(coords.longitude) },
          map: map.value,
          optimized: false,
          zIndex: 99999,
          icon: {
            url: getRoundedImageWithSpace(activePet.value.avatar, false, -1),
            scaledSize: new window.google.maps.Size(32, 32),
            origin: { x: 0, y: 0 },
            anchor: {
              x: 32 / 2,
              y: 32 / 2,
            },
          },
          opacity: 0.5,
        })

        const listener = window.google.maps.event.addListener(marker, 'click', ev => {
          openMenu()
          ev.domEvent.cancelBubble = true
        })

        previewPetMarker.value = { marker, listener }
      } else {
        if (!previewPetMarker.value) return

        window.google.maps.event.removeListener(previewPetMarker.value.listener)
        previewPetMarker.value.marker.setMap(null)
        previewPetMarker.value = null
      }
    })

    onMounted(async () => {
      // data.value = await getData()

      await loader.load()

      try {
        await getCurrentPosition()
      } catch {}

      first = 0
      /*setTimeout(() => {
        first = true;
        moveMap()
      }, 2100)*/
      map.value = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: coords.latitude, lng: coords.longitude },
        zoom: 14,
        mapId: '6dfc21d28698beac',
        scaleControl: false,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        maxZoom: 200,
        minZoom: 0.1,
        mapTypeControl: false,
      })

      // disabled layer miatt nem tud rákattintani
      // map.value.addListener('click', () => {
      //   emit('menuOpen', false)
      // })

      const renderer = {
        render: ({ count, position }) => {
          return new window.google.maps.Marker({
            icon: new window.google.maps.MarkerImage(
              require('@/assets/images/icons/walk/petcluster.png'),
              new window.google.maps.Size(96, 96),
              new window.google.maps.Point(0, 0),
              new window.google.maps.Point(15, 15),
            ),
            position,
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
          })
        },
      }

      markerClusterer = new MarkerClusterer({ markers: [], map: null, renderer })
      // console.log('mc', markerClusterer)

      // createTestMarkers(6)

      console.log('map loaded')
      mapLoaded.value = true

      const pois = await getPois()
      const events = await getEvents()
      // const events = []
      const presents = await getPresents()

      pois.forEach(m => {
        const markerImage = new window.google.maps.MarkerImage(
          getCustomMarkerIcon(m),
          new window.google.maps.Size(26, 34),
          new window.google.maps.Point(0, 0),
          new window.google.maps.Point(13, 17),
        )

        let marker = new window.google.maps.Marker({
          position: { lat: Number(m.latitude), lng: Number(m.longitude) },
          map: map.value,
          optimized: false,
          zIndex: 1,
          icon: markerImage,
        })

        markerClusterer.addMarker(marker)

        window.google.maps.event.addListener(marker, 'click', async ev => {
          if (m.is_active) {
            await emit('menuOpen', 'poiClick', m)
          } else {
            const icon = require(`@/assets/images/icons/sad-dog.png`)
            const poiName =
              m.original_type === 'walkplace'
                ? 'kutyafuttató'
                : m.original_type === 'dogbeach'
                ? 'kutyastrand'
                : 'kutyabarát hely'

            const dialog = await alertController.create({
              cssClass: 'confirm-dialog poi-dialog',
              message: `
                <div class="poi-info">
                  <img src="${icon}" class="icon" />
                  <p>Ez a ${poiName} még elfogadásra vár.</p>
                </div>
              `,
              buttons: [
                {
                  text: 'Bezárás',
                  role: 'cancel',
                  cssClass: 'btn btn-link btn-block btn-popup-close',
                },
              ],
            })

            await dialog.present()

            return
          }

          ev.domEvent.cancelBubble = true
        })
      })

      presents.forEach(present => {
        const markerImage = new window.google.maps.MarkerImage(
          present.is_active
            ? require('@/assets/images/icons/walk/gift.svg')
            : require('@/assets/images/icons/walk/giftlost.svg'),
          new window.google.maps.Size(26, 34),
          // new window.google.maps.Size(29.04, 40),
          new window.google.maps.Point(0, 0),
          new window.google.maps.Point(13, 17),
        )

        let marker = new window.google.maps.Marker({
          position: { lat: Number(present.latitude), lng: Number(present.longitude) },
          // map: isRunning.value === true ? map.value : null,
          map: map.value,
          optimized: false,
          zIndex: 50 + present.id,
          icon: markerImage,
        })

        presentMarkers.push(marker)

        markerClusterer.addMarker(marker)

        window.google.maps.event.addListener(marker, 'click', async ev => {
          if (present.is_active) {
            await emit('menuOpen', 'presentClick', present.id)
          } else {
            const icon = require(`@/assets/images/icons/sad-dog.png`)

            const dialog = await alertController.create({
              cssClass: 'confirm-dialog poi-dialog',
              message: `
                <div class="poi-info">
                  <img src="${icon}" class="icon" />
                  <p>Ezt az elrejtett kincset már kiásták, figyeld hol bukkan fel újabb!</p>
                </div>
              `,
              buttons: [
                {
                  text: 'Bezárás',
                  role: 'cancel',
                  cssClass: 'btn btn-link btn-block btn-popup-close',
                },
              ],
            })

            return await dialog.present()
          }

          ev.domEvent.cancelBubble = true
        })
      })

      events
        .filter(event => event.show_on_map)
        .forEach(m => {
          const bigMarker = !m.is_reviewed || (m.is_reviewed && m.is_active)
          const markerImage = new window.google.maps.MarkerImage(
            !m.is_reviewed
              ? require(`@/assets/images/icons/walk/event-not-reviewed-marker.svg`)
              : m.is_active
              ? require(`@/assets/images/icons/walk/event-marker.svg`)
              : require(`@/assets/images/icons/walk/event-inactive-marker.svg`),
            new window.google.maps.Size(bigMarker ? 60 : 44, bigMarker ? 60 : 44),
            new window.google.maps.Point(0, 0),
            new window.google.maps.Point(0, bigMarker ? 50 : 40),
          )

          let marker = new window.google.maps.Marker({
            position: { lat: Number(m.latitude), lng: Number(m.longitude) },
            map: map.value,
            optimized: false,
            zIndex: 200 + m.id,
            icon: markerImage,
          })

          markerClusterer.addMarker(marker)

          window.google.maps.event.addListener(marker, 'click', async ev => {
            if (m.is_active && m.is_reviewed) {
              await emit('menuOpen', 'poiClick', {
                ...m,
                type: 'event',
              })
            } else {
              const icon = require(`@/assets/images/icons/sad-dog.png`)

              const dialog = await alertController.create({
                cssClass: 'confirm-dialog poi-dialog',
                message: `
                <div class="poi-info">
                  <img src="${icon}" class="icon" />
                  <p>Ez az esemény ${m.is_reviewed ? 'már véget ért' : 'még elfogadásra vár'}.</p>
                </div>
              `,
                buttons: [
                  {
                    text: 'Bezárás',
                    role: 'cancel',
                    cssClass: 'btn btn-link btn-block btn-popup-close',
                  },
                ],
              })

              await dialog.present()

              return
            }

            ev.domEvent.cancelBubble = true
          })
        })
    })

    watch(
      () => props.showPoiCreationMarker,
      async () => {
        if (props.showPoiCreationMarker && poiCreationMarker.value === null) {
          const poiCreationMarkerImage = new window.google.maps.MarkerImage(
            getCustomMarkerIcon({ type: 'unknown-pink' }),
            new window.google.maps.Size(32, 41),
            new window.google.maps.Point(0, 0),
            new window.google.maps.Point(13, 20),
          )

          const lat = map.value.getCenter().lat()
          const lng = map.value.getCenter().lng()

          poiCreationMarker.value = new window.google.maps.Marker({
            position: {
              lat,
              lng,
            },
            map: map.value,
            optimized: false,
            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX * 2),
            icon: poiCreationMarkerImage,
          })

          map.value.addListener('center_changed', () => {
            const lat = map.value.getCenter().lat()
            const lng = map.value.getCenter().lng()

            poiCreationMarker.value.setPosition({
              lat,
              lng,
            })
          })
        } else if (!props.showPoiCreationMarker && poiCreationMarker.value !== null) {
          poiCreationMarker.value.setVisible(false)
        } else if (props.showPoiCreationMarker && poiCreationMarker.value !== null) {
          poiCreationMarker.value.setVisible(true)
        }
      },
    )

    const getPoiCreationMarkerPosition = () => {
      return map.value?.getCenter()
    }

    expose({ setMapCenter, getPoiCreationMarkerPosition })

    return {
      petsWalking,
      mapData,
      emit,
      setMapCenter,
      creationMenuActive,
      getPoiCreationMarkerPosition,
    }
  },
})
</script>

<style lang="scss" scoped>
#map {
  height: calc(100vh - 55px);
  width: 100%;

  transition: height 0.3s ease-in-out;

  &.createMenu {
    height: calc(100vh - 350px);
    transition: height 0.7s ease-in-out 0.2s;
  }
}
</style>
