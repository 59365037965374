<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <form @submit.prevent="submit" class="pet-info-list pet-edit" v-if="pet && pet.species && options" novalidate>
            <div class="field-group">
              <h2 class="field-group__title small" @click="toggleShow($event, '#main-data')">
                <img
                  :src="require(`@/assets/images/icons/${pet.species.id === 1 ? 'dog' : 'cat'}.svg`)"
                  alt="dog-icon"
                  class="title-icon"
                />
                Kedvenc adatai
                <ion-icon :icon="chevronDownCircle" class="toggle-icon opened" />
              </h2>
              <div id="main-data" class="field-group__content">
                <div class="form-group" style="margin-bottom: 32px">
                  <ion-avatar
                    class="pet-avatar"
                    :class="{ 'has-avatar': pet.avatar }"
                    width="180"
                    height="180"
                    @click="getAvatar($event)"
                  >
                    <ion-img
                      v-if="!isUploadLoading"
                      class="upload"
                      :class="{ 'upload-icon': !pet.avatar }"
                      :src="avatarSrc"
                      alt="Kép feltöltés"
                    />
                    <span v-else>Feltöltés...</span>
                    <div class="edit-pet-img">
                      <img :src="editIcon" @click.prevent="getAvatar($event)" />
                    </div>
                  </ion-avatar>
                  <ion-input type="hidden" name="image" style="display: none" v-model="validations.avatar.$model" />
                  <ErrorMessage :field="validations.avatar" />
                  <ServerValidation field="avatar" :server-validation="serverValidation" />
                </div>
                <div class="form-group">
                  <ion-label
                    for="name"
                    class="control-label"
                    style="display: flex; justify-content: center; padding-left: 0"
                    >Kedvenc neve:</ion-label
                  >
                  <ion-input
                    id="name"
                    name="name"
                    v-model="validations.name.$model"
                    class="form-control pet-name-input"
                    :class="{ 'is-invalid': validations.name.$error || hasServerValidationError('name') }"
                    autocapitalize="sentences"
                  />
                  <ErrorMessage :field="validations.name" />
                  <ServerValidation field="name" :server-validation="serverValidation" />
                </div>

                <div class="form-group">
                  <ion-label class="control-label">Bemutatkozás:</ion-label>
                  <ion-textarea
                    v-model="validations.bio.$model"
                    placeholder="Szánj rá időt és mesélj a társadról!"
                    class="form-control"
                    :class="{ 'is-invalid': validations.bio.$error || hasServerValidationError('bio') }"
                    autocapitalize="sentences"
                    :rows="4"
                  />
                  <ErrorMessage :field="validations.bio" />
                  <ServerValidation field="bio" :server-validation="serverValidation" />
                </div>

                <div style="display: flex; align-items: flex-end">
                  <div
                    v-if="countries.length"
                    @click="showCountries"
                    class="form-group form-country-select"
                    style="margin-right: 1rem"
                  >
                    <ion-img :src="selectedCountry.icon" :alt="selectedCountry.text" />
                    <ErrorMessage :field="validations.country" />
                    <ServerValidation field="country" :server-validation="serverValidation" />
                  </div>
                  <div class="form-group" style="flex: 1" v-if="cities">
                    <ion-label class="control-label">Lakhely:</ion-label>
                    <Autocomplete
                      newDesign
                      v-model="validations.city.$model"
                      @input="filterCities"
                      @on-select="selectCity"
                      :results="filteredCities"
                      placeholder="Budapest, IX. kerület"
                      :error-state="validations.city.$error || hasServerValidationError('city')"
                    />
                    <ErrorMessage :field="validations.city" />
                    <ServerValidation field="city" :server-validation="serverValidation" />
                  </div>
                </div>
                <div class="form-group" v-if="pet.breed">
                  <ion-label class="control-label">Kedvenced fajtája:</ion-label>
                  <ion-select
                    mode="ios"
                    interface="action-sheet"
                    :interface-options="{ cssClass: 'styled-sheet' }"
                    cancel-text="Mégse"
                    v-model="validations.breed.$model"
                    :selectedText="validations.breed.$model.name"
                    placeholder="Válassz a listából"
                    class="form-control"
                    :class="{ 'is-invalid': validations.breed.$error || hasServerValidationError('breed') }"
                  >
                    <ion-select-option v-for="(item, index) in options.breeds" :key="index" :value="item">
                      {{ item.name }}
                    </ion-select-option>
                  </ion-select>
                  <ErrorMessage :field="validations.breed" />
                  <ServerValidation field="breed" :server-validation="serverValidation" />
                </div>
                <div v-if="isMixBreed">
                  <div class="form-group">
                    <ion-label class="control-label">Szülők fajtái:</ion-label>
                    <ion-select
                      mode="ios"
                      interface="action-sheet"
                      :interface-options="{ cssClass: 'styled-sheet' }"
                      cancel-text="Mégse"
                      v-model="validations.parent_breeds.$model[0]"
                      :selectedText="validations.parent_breeds.$model[0].name"
                      placeholder="Válassz a listából"
                      class="form-control"
                      :class="{ 'is-invalid': hasServerValidationError('parent_breeds') }"
                    >
                      <ion-select-option v-for="(item, index) in options.breeds" :key="index" :value="item">
                        {{ item.name }}
                      </ion-select-option>
                    </ion-select>
                  </div>
                  <div class="form-group">
                    <ion-select
                      mode="ios"
                      interface="action-sheet"
                      :interface-options="{ cssClass: 'styled-sheet' }"
                      cancel-text="Mégse"
                      v-model="validations.parent_breeds.$model[1]"
                      :selectedText="validations.parent_breeds.$model[1].name"
                      placeholder="Válassz a listából"
                      class="form-control"
                      :class="{ 'is-invalid': hasServerValidationError('parent_breeds') }"
                    >
                      <ion-select-option v-for="(item, index) in options.breeds" :key="index" :value="item">
                        {{ item.name }}
                      </ion-select-option>
                    </ion-select>
                  </div>
                  <ServerValidation field="parent_breeds" :server-validation="serverValidation" />
                </div>
                <div class="form-group">
                  <ion-label class="control-label">Kedvenced neme:</ion-label>
                  <ion-select
                    mode="ios"
                    interface="action-sheet"
                    :interface-options="{ cssClass: 'styled-sheet' }"
                    cancel-text="Mégse"
                    v-model="validations.sex.$model"
                    placeholder="Válassz a listából"
                    class="form-control"
                    :class="{ 'is-invalid': validations.sex.$error || hasServerValidationError('sex') }"
                  >
                    <ion-select-option v-for="(item, index) in sex" :key="index" :value="item">
                      {{ item }}
                    </ion-select-option>
                  </ion-select>
                  <ErrorMessage :field="validations.sex" />
                  <ServerValidation field="sex" :server-validation="serverValidation" />
                </div>
                <div class="form-group">
                  <ion-label for="date_of_birth" class="control-label">Kedvenced szülinapja:</ion-label>
                  <ion-input
                    type="date"
                    id="date_of_birth"
                    name="date_of_birth"
                    v-model="validations.date_of_birth.$model"
                    min="1900-01-01"
                    :max="new Date()"
                    class="form-control"
                    :class="{
                      'is-invalid': validations.date_of_birth.$error || hasServerValidationError('date_of_birth'),
                    }"
                  />
                  <ErrorMessage :field="validations.date_of_birth" />
                  <ServerValidation field="date_of_birth" :server-validation="serverValidation" />
                </div>
              </div>
            </div>
            <div class="field-group">
              <h2 class="field-group__title small" @click="toggleShow($event, '#health-data')">
                <img :src="require('@/assets/images/icons/info-outline.svg')" alt="dog-icon" class="title-icon" />
                Egészségügyi adatok
                <ion-icon :icon="chevronDownCircle" class="toggle-icon" />
              </h2>
              <div id="health-data" class="field-group__content ion-hide" :class="{ 'no-dox': !pet.has_dox }">
                <div class="field-group__content-wrapper">
                  <div class="form-group">
                    <ion-label class="control-label">Egészségügyi állapot:</ion-label>
                    <ion-select
                      mode="ios"
                      interface="alert"
                      :interface-options="{ cssClass: 'styled-sheet custom-alert' }"
                      cancel-text="Mégse"
                      v-model="validations.health['status'].$model"
                      :compare-with="compareMultiple"
                      placeholder="Válassz a listából"
                      :disabled="!pet.has_dox"
                      multiple
                      class="form-control"
                      :class="{
                        'is-invalid': validations.health['status'].$error || hasServerValidationError('status'),
                      }"
                    >
                      <ion-select-option v-for="item in options.healthStatuses" :key="item.id" :value="item">
                        {{ item.name }}
                      </ion-select-option>
                    </ion-select>
                    <ErrorMessage :field="validations.health['status']" />
                    <ServerValidation field="status" :server-validation="serverValidation" />
                  </div>
                  <div class="form-group">
                    <ion-label class="control-label">Vércsoport:</ion-label>
                    <ion-select
                      mode="ios"
                      interface="action-sheet"
                      :interface-options="{ cssClass: 'styled-sheet' }"
                      cancel-text="Mégse"
                      v-model="pet.health.blood_type.id"
                      placeholder="Válassz a listából"
                      :disabled="!pet.has_dox"
                      class="form-control"
                    >
                      <ion-select-option v-for="(item, index) in options.bloodTypes" :key="index" :value="item.id">
                        {{ item.name }}
                      </ion-select-option>
                    </ion-select>
                  </div>
                  <div class="form-group">
                    <ion-label class="control-label">Kedvenced ivartalanított?</ion-label>
                    <ion-select
                      mode="ios"
                      interface="action-sheet"
                      :interface-options="{ cssClass: 'styled-sheet' }"
                      cancel-text="Mégse"
                      v-model="pet.health.neutered"
                      placeholder="Válassz a listából"
                      :disabled="!pet.has_dox"
                      class="form-control"
                    >
                      <ion-select-option :value="true">Igen</ion-select-option>
                      <ion-select-option :value="false">Nem</ion-select-option>
                    </ion-select>
                  </div>
                  <div class="form-group">
                    <ion-label for="last_vaccination_date" class="control-label">Utolsó oltás:</ion-label>
                    <ion-input
                      type="date"
                      id="last_vaccination_date"
                      name="last_vaccination_date"
                      v-model="pet.last_vaccination_date"
                      min="1900-01-01"
                      max="2999-12-31"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <ion-label for="chip" class="control-label">Chip kód:</ion-label>
                  <ion-input
                    id="chip"
                    name="chip"
                    v-model="pet.chip"
                    class="form-control"
                    :class="{ 'is-invalid': hasServerValidationError('chip') }"
                  />
                  <ServerValidation field="chip" :server-validation="serverValidation" />
                </div>
                <NoDox v-if="!pet.has_dox" />
              </div>
            </div>
            <div class="field-group">
              <h2 class="field-group__title small" @click="toggleShow($event, '#documents')">
                <img :src="require('@/assets/images/icons/documents.svg')" alt="dog-icon" class="title-icon" />
                Okmányok
                <ion-icon :icon="chevronDownCircle" class="toggle-icon" />
              </h2>
              <div id="documents" class="field-group__content ion-hide" :class="{ 'no-dox': !pet.has_dox }">
                <div class="field-group__content-wrapper">
                  <div class="upload-group">
                    <ion-avatar @click.prevent="getPhoto($event, 'vaccination_book')">
                      <ion-img
                        class="upload"
                        :class="{ 'upload-icon': !pet.documents.vaccination_book }"
                        :src="pet.documents.vaccination_book || require('@/assets/images/icons/camera.svg')"
                        alt="Egészségügyi könyv"
                      />
                    </ion-avatar>
                    <ion-input width="0" type="hidden" v-model="pet.documents.vaccination_book" />
                    <ion-text>
                      <strong>Egészségügyi könyv</strong>
                      Az egészségügyi könyv kizárólag a DOX leolvasásakor látható
                    </ion-text>
                  </div>
                  <div class="upload-group">
                    <ion-avatar @click.prevent="getPhoto($event, 'pedigree')">
                      <ion-img
                        class="upload"
                        :class="{ 'upload-icon': !pet.documents.pedigree }"
                        :src="pet.documents.pedigree || require('@/assets/images/icons/camera.svg')"
                        alt="Törzskönyv"
                      />
                    </ion-avatar>
                    <ion-input type="hidden" v-model="pet.documents.pedigree" />
                    <ion-text>
                      <strong>Törzskönyv</strong>
                      A törzskönyv kizárólag a DOX leolvasásakor látható
                    </ion-text>
                  </div>
                  <div class="upload-group">
                    <ion-avatar @click.prevent="getPhoto($event, 'passport')">
                      <ion-img
                        class="upload"
                        :class="{ 'upload-icon': !pet.documents.passport }"
                        :src="pet.documents.passport || require('@/assets/images/icons/camera.svg')"
                        alt="Útlevél"
                      />
                    </ion-avatar>
                    <ion-input type="hidden" v-model="pet.documents.passport" />
                    <ion-text>
                      <strong>Útlevél</strong>
                      Az Útlevél kizárólag a DOX leolvasásakor látható
                    </ion-text>
                  </div>
                </div>
                <NoDox v-if="!pet.has_dox" />
              </div>
            </div>
            <div class="field-group" v-if="user">
              <h2 class="field-group__title small" @click="toggleShow($event, '#owner')">
                <img :src="require('@/assets/images/icons/avatar-outline.svg')" alt="dog-icon" class="title-icon" />
                Gazdi adatai
                <ion-icon :icon="chevronDownCircle" class="toggle-icon" />
              </h2>
              <div id="owner" class="field-group__content ion-hide">
                <div class="form-group centered">
                  <ion-label class="owner_label owner-profile-pic-label" v-if="!userHasDox" style="padding-left: 0"
                    >Gazdifotó feltöltése</ion-label
                  >
                  <div class="user-profile-wrapper" :class="userHasDox == false ? 'noDox' : ''">
                    <div v-if="userHasDox" style="position: relative; height: 128px">
                      <div class="user-icon" :class="userPhoto ? '' : 'user-placeholder'">
                        <img
                          :class="userPhoto != null ? '' : 'user-img'"
                          :src="userPhoto != null ? userPhoto : userPhotoplaceholder"
                          @click.prevent="setUserPhoto"
                        />
                      </div>
                      <!--img :src="userPhotoplaceholder" /-->
                      <div :class="userPhoto ? 'edit-user-img' : 'edit-user-img'">
                        <img :src="editIcon" @click.prevent="setUserPhoto" />
                      </div>
                    </div>
                    <div v-else>
                      <NoDox bigExtraTopMargin />
                      <div class="photo-wrap">
                        <img :src="userPhotoplaceholder" />
                        <div class="edit-user-img">
                          <img :src="editIcon" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <ion-label
                    class="owner_label control-label"
                    v-if="userHasDox"
                    style="padding-left: 0; margin-bottom: 10px !important"
                    >Gazdifotó feltöltése</ion-label
                  >
                </div>
                <div class="form-group">
                  <ion-label for="first_name" class="control-label">Gazdi neve:</ion-label>
                  <ion-input
                    id="first_name"
                    name="first_name"
                    v-model="userValidations.first_name.$model"
                    class="form-control"
                    :class="{
                      'is-invalid': userValidations.first_name.$error || hasServerValidationError('first_name'),
                    }"
                    autocapitalize="sentences"
                  />
                  <ErrorMessage :field="userValidations.first_name" />
                  <ServerValidation field="first_name" :server-validation="serverValidation" />
                </div>

                <div class="form-group">
                  <ion-label for="email" class="control-label">Email címe:</ion-label>
                  <ion-input
                    type="email"
                    id="email"
                    name="email"
                    v-model="userValidations.email.$model"
                    class="form-control"
                    :class="{ 'is-invalid': userValidations.email.$error || hasServerValidationError('email') }"
                  />
                  <ErrorMessage :field="userValidations.email" />
                  <ServerValidation field="email" :server-validation="serverValidation" />
                </div>

                <div v-if="user.invited_users.length > 0" style="text-align: center">
                  <ion-label class="control-label" style="padding-left: 0; margin-bottom: 32px !important">
                    Családtagok:
                  </ion-label>

                  <div class="family-list">
                    <div class="family-member" v-for="member in user.invited_users" :key="member.id">
                      <ion-img :src="member.avatar ?? require('@/assets/images/owner-placeholder.jpeg')" />

                      <h1>{{ member.first_name }}</h1>

                      <div class="delete" @click="removeInvitedUser(member.id)">
                        {{ member.accepted ? 'Eltávolítás' : 'Függőben' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field-group">
              <h2 class="field-group__title small" @click="toggleShow($event, '#danger-phone')">
                <img :src="require('@/assets/images/icons/phone.svg')" alt="dog-icon" class="title-icon" />
                Vészhelyzeti telefonszám
                <ion-icon :icon="chevronDownCircle" class="toggle-icon" />
              </h2>
              <div id="danger-phone" class="field-group__content ion-hide" :class="{ 'no-dox': !pet.has_dox }">
                <div class="field-group__content-wrapper">
                  <div class="form-group">
                    <ion-label for="phone" class="control-label">Telefonszám</ion-label>
                    <ion-input
                      type="phone"
                      id="phone"
                      name="phone"
                      v-model="validations.phone.$model"
                      required
                      class="form-control"
                      :class="{ 'is-invalid': validations.phone.$error || hasServerValidationError('phone') }"
                    />
                    <ErrorMessage :field="validations.phone" />
                    <ServerValidation field="phone" :server-validation="serverValidation" />
                  </div>
                </div>
                <NoDox v-if="!pet.has_dox" />
              </div>
            </div>
            <ion-button type="submit" id="submit" expand="full" shape="round" strong="true" class="btn submit new-btn">
              Mentés
            </ion-button>
            <ion-button
              @click="$router.go(-1)"
              fill="clear"
              expand="full"
              shape="round"
              strong="true"
              class="btn btn-link"
            >
              Mégse
            </ion-button>
            <ion-button
              @click="confirmDeletePet"
              color="primary"
              fill="clear"
              expand="full"
              shape="round"
              strong="true"
              class="btn btn-link btn-delete"
            >
              Profil törlése
            </ion-button>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed, onBeforeMount, onMounted, watch, reactive, toRefs } from 'vue'
import {
  IonPage,
  IonContent,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonImg,
  IonButton,
  IonTextarea,
  IonIcon,
  IonAvatar,
  IonText,
  actionSheetController,
  alertController,
} from '@ionic/vue'

import { useRoute, useRouter } from 'vue-router'
import { diff } from 'deep-object-diff'
import { chevronDownCircle } from 'ionicons/icons'
import { useVuelidate } from '@vuelidate/core'
import { required, email, requiredIf } from '@vuelidate/validators'
import moment from 'moment'

import httpClient from '@/core/api/api.service'

import { FEED_ROUTE, PET_ROUTE, USER_PHOTO_ROUTE } from '@/constants/routes'

import usePets from '@/composables/pets'
import usePhotoGallery from '@/composables/camera'
import NoDox from '@/components/Pet/NoDox'
import Autocomplete from '@/components/Global/Autocomplete'

import useToast from '@/composables/toast'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'
import useFormControl from '@/composables/formControl'

import { useStore } from 'vuex'

import useNavigation from '@/composables/navigation'

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonImg,
    IonButton,
    IonTextarea,
    IonIcon,
    IonAvatar,
    IonText,
    NoDox,
    Autocomplete,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { PETS_STATE, fetchPet, fetchPetOptions, updatePet, fetchCountries, deletePet } = usePets()
    const { takePhoto, isLoading: isUploadLoading } = usePhotoGallery()
    const { openToast } = useToast()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { navigateTo } = useNavigation()

    const state = reactive({
      userPhoto: null,
      petCurrentData: null,
      userCurrentData: null,
      countries: [],
      cities: [],
      filteredCities: [],
      sex: ['Nőstény', 'Hím'],
      isLoading: false,
    })

    const pet = reactive({
      name: '',
      avatar: null,
      country: {
        id: null,
        name: '',
      },
      city: {
        id: null,
        name: '',
      },
      breed: {
        id: null,
        name: '',
      },
      parent_breeds: [
        {
          id: null,
          name: '',
        },
        {
          id: null,
          name: '',
        },
      ],
      sex: null,
      date_of_birth: null,
      bio: '',
      health: {
        status: [
          {
            id: null,
            name: '',
          },
        ],
      },
      phone: '',
      chip: '',
    })

    const user = reactive({
      email: '',
      first_name: '',
      invited_users: [],
    })

    const options = computed(() => PETS_STATE.options)
    const species = computed(() => (state.petCurrentData.species.id === 1 ? 'dog' : 'cat'))
    const selectedCountry = computed(() =>
      state.countries.length ? state.countries.find(item => item.id === pet.country.id) : null,
    )

    const isMixBreed = computed(() => options.value.breeds.find(breed => breed.id === pet.breed.id)?.is_mixed)
    const petId = computed(() => parseInt(route.params.id, 10) || null)
    const store = useStore()
    const userState = computed(() => store.state.auth.user)
    const userHasDox = userState.value.pets.filter(x => x.has_dox == true).length > 0 ? true : false
    const userPhotoplaceholder = require('@/assets/images/icons/profile-user-red-but-actually-gray.svg')
    const validations = useVuelidate(
      {
        name: { required },
        avatar: { required },
        country: { required },
        city: { required },
        breed: { required },
        parent_breeds: { required },
        sex: { required },
        date_of_birth: {
          isBetween: value => moment(value).isBetween(moment('1899-12-31'), moment()),
        },
        bio: { required },
        health: {
          status: {
            required: requiredIf(() => pet.has_dox),
            only_healthy: value => {
              if (!pet.has_dox) {
                return true
              }

              return value.find(item => item.name === 'Teljesen egészséges') ? value.length === 1 : value.length > 0
            },
          },
        },
        phone: { required: requiredIf(() => pet.has_dox) },
      },
      toRefs(pet),
    )

    const userValidations = useVuelidate(
      {
        email: { required, email },
        first_name: { required },
      },
      toRefs(user),
    )

    onBeforeMount(() => {
      const myPets = computed(() => PETS_STATE.pets)

      if (!myPets.value.find(item => item.id === parseInt(petId.value, 10))) {
        return router.push({ name: PET_ROUTE, params: { id: parseInt(petId.value, 10) } })
      }
    })

    watch(petId, () => {
      if (petId.value) {
        getPet()
        getUser()
      }
    })

    onMounted(() => {
      getPet()
      getUser()
    })

    const toggleShow = (event, el) => {
      event.currentTarget.querySelector('.toggle-icon').classList.toggle('opened')
      document.querySelector(el).classList.toggle('ion-hide')
    }

    const storePet = data => {
      if (!data.parent_breeds.length) {
        data.parent_breeds = [
          {
            id: null,
            name: '',
          },
          {
            id: null,
            name: '',
          },
        ]
      } else if (data.parent_breeds.length < 2) {
        data.parent_breeds.push({
          id: null,
          name: '',
        })
      }

      if (!data.health.status) {
        data.health.status = {
          id: null,
          name: '',
        }
      }

      if (!data.health.blood_type) {
        data.health.blood_type = {
          id: null,
          name: '',
        }
      }

      state.petCurrentData = data
      Object.assign(pet, JSON.parse(JSON.stringify({ ...data })))
    }

    const getPet = async () => {
      try {
        const data = await fetchPet(petId.value)
        state.userPhoto = data.user_avatar
        storePet(data)

        await fetchPetOptions(state.petCurrentData.species.id)
        await getCountries()

        if (state.petCurrentData.country) {
          await fetchCities()
        }

        return Promise.resolve()
      } catch (error) {
        router.push({ name: FEED_ROUTE })
        return Promise.reject(error)
      }
    }

    const updatePetData = async () => {
      const pet_diff = diff(state.petCurrentData, pet)

      if (Object.keys(pet_diff).length === 0) {
        return Promise.resolve()
      }

      if (pet_diff.parent_breeds) {
        if (!pet_diff.breed) {
          pet_diff.breed = {
            id: pet.breed.id,
          }
        }

        pet_diff.parent_breeds = [...pet.parent_breeds]
        pet_diff.parent_breeds[0].sex = 'Hím'
        pet_diff.parent_breeds[1].sex = 'Nőstény'
      }

      try {
        const data = await updatePet(pet, pet_diff)

        storePet(data)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const storeUser = ({ data }) => {
      state.userCurrentData = data
      Object.assign(user, JSON.parse(JSON.stringify({ ...data })))
    }

    const getUser = async () => {
      try {
        const { data } = await httpClient.get('me')

        storeUser(data)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const updateUser = async () => {
      const user_diff = diff(state.userCurrentData, user)

      if (Object.keys(user_diff).length === 0) {
        return Promise.resolve()
      }

      try {
        const { data } = await httpClient.patch('me', '', user_diff)

        storeUser(data)

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const removeInvitedUser = async id => {
      const invitedUser = user.invited_users.find(item => item.id === id)

      const dialog = await alertController.create({
        cssClass: 'confirm-dialog poi-dialog',
        message: `
          <div>
            <img src="${require('@/assets/images/icons/pet/user-revoke.svg')}" class="alert-icon revoke" />
            <h1>Biztosan töröljük?</h1>
            <p class="centered">Biztosan eltávolítod <b>${
              invitedUser.first_name
            }</b> családtagodat a profilodból? Ha meggondolod magad újra meg kell hívnod őt.</p>
          </div>
        `,
        buttons: [
          {
            text: 'Igen, eltávolítom',
            cssClass: 'btn btn-dark btn-block',
            handler: async () => {
              try {
                await httpClient.remove('users/invite', id)

                const invitedUsers = user.invited_users.filter(item => item.id !== id)

                storeUser({
                  data: {
                    ...user,
                    invited_users: invitedUsers,
                  },
                })
              } catch {
                console.error('Could not remove invited user')
              }
            },
          },
          {
            text: 'Mégsem',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block',
          },
        ],
      })

      return dialog.present()
    }

    const getCountries = async () => {
      try {
        state.countries = await fetchCountries()

        state.countries.map(
          item =>
            (item.handler = () => {
              pet.country = item
              fetchCities()
            }),
        )

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const showCountries = async () => {
      const actionSheet = await actionSheetController.create({
        mode: 'ios',
        buttons: [
          ...state.countries,
          {
            text: 'Mégse',
            role: 'cancel',
          },
        ],
        cssClass: 'styled-sheet',
      })

      return actionSheet.present()
    }

    const getPhoto = async (event, target) => {
      if (!pet.has_dox) {
        return
      }

      try {
        const img = event.currentTarget.querySelector('.upload')
        const photo = await takePhoto()

        img.src = photo.webviewPath
        img.classList.remove('upload')
        pet.documents[target] = photo.url
      } catch (error) {
        return Promise.reject(error)
      }
    }

    async function fetchCities() {
      try {
        const { data } = await httpClient.get('cities', '', { country: pet.country.id })
        state.cities = data.data

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    function filterCities(search) {
      state.filteredCities = [...state.cities]
        .filter(city => city.name.toLowerCase().includes(search.toLowerCase()))
        .map(item => item.name)
    }

    function selectCity(item) {
      pet.city = [...state.cities].find(city => city.name.toLowerCase() === item.toLowerCase())
    }

    const avatarSrc = computed(() => (pet.avatar ? pet.avatar : require('@/assets/images/icons/camera.svg')))

    const getAvatar = async event => {
      try {
        let currTarget = event?.currentTarget?.firstChild?.nextSibling?.closest('.edit-pet-img')
        if (!currTarget) {
          currTarget = event.currentTarget.closest('.edit-pet-img')
        }
        const img =
          currTarget.nextSibling != null && currTarget.nextSibling != undefined
            ? currTarget.nextSibling.querySelector('.upload')
            : currTarget.previousSibling
        const photo = await takePhoto()

        img.src = photo.webviewPath
        img.classList.remove('upload')
        pet.avatar = photo.url
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const editIcon = computed(() => {
      return require('@/assets/images/icons/circle-plus.svg')
    })

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) {
        await openToast({
          message: 'WOOF, itt valami nem stimmel',
          color: 'danger',
        })
        return
      }

      if (state.isLoading) return

      try {
        state.isLoading = true

        await updatePetData()
        await updateUser()

        await openToast({
          message: 'WOOF, frissítettük a profilodat!',
          color: 'success',
        })

        return Promise.resolve()
      } catch (error) {
        await openToast({
          color: 'danger',
          message: 'Hiba történt a profil módosítás során! Próbáld meg később!',
        })

        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    const compareMultiple = (f1, f2) => {
      if (Array.isArray(f2)) {
        if (!f1 || !f1.id) {
          return false
        }

        return f2.find(val => val && val.id === f1.id)
      }
      return f1 && f2 ? f1.id === f2.id : f1 === f2
    }

    const confirmDeletePet = async () => {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog',
        message: `<img src="${require('@/assets/images/icons/exclamation-circle.svg')}" class="alert-icon" />
                    <p class="ion-text-center">
                        Biztosan törölni szeretnéd ezt a Kedvenc-profilt? A törlést nem lehet visszaállítani.
                    </p>`,
        buttons: [
          {
            text: 'Igen',
            cssClass: 'btn btn-dark btn-block',
            handler: async () => {
              try {
                await deletePet(route.params.id)
                return Promise.resolve()
              } catch (error) {
                return Promise.reject(error)
              }
            },
          },
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block',
          },
        ],
      })

      return dialog.present()
    }

    function setUserPhoto() {
      navigateTo({
        name: USER_PHOTO_ROUTE,
        params: {
          pet: pet,
        },
      })
    }

    return {
      ...toRefs(state),
      pet,
      user,
      toggleShow,
      showCountries,
      selectedCountry,
      submit,
      chevronDownCircle,
      getPhoto,
      species,
      filterCities,
      selectCity,
      isMixBreed,
      options,
      avatarSrc,
      getAvatar,
      validations,
      userValidations,
      serverValidation,
      hasServerValidationError,
      compareMultiple,
      isUploadLoading,
      confirmDeletePet,
      editIcon,
      userHasDox,
      userPhotoplaceholder,
      setUserPhoto,
      removeInvitedUser,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Open+Sans:wght@700;800&display=swap');
.container {
  padding: 0 30px;
}

@media only screen and (max-width: 600px) {
  .content {
    margin: 100px auto 60px;
  }
}

ion-button {
  max-width: 238px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}

ion-label {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  padding-left: 22px;
  margin-bottom: 4px !important;

  color: #aeaeae;
}

ion-input,
.form-control {
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  border: none;
  min-height: 50px;
  font-weight: 700;
  font-size: 15px;
  --padding-start: 22px;
  --padding-end: 22px;
}

.family-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  .family-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 32px;

    ion-img,
    ion-img::part(image) {
      max-width: 45px;
      max-height: 45px;
      min-width: 45px;
      min-height: 45px;
      border-radius: 50%;
    }

    ion-img {
      margin-bottom: 3px;
    }

    h1 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 14px !important;
      line-height: 18px;
      margin: 0 0 8px 0;
      letter-spacing: -0.3px;
    }

    .delete {
      color: #e6224a;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 10px;
      line-height: 18px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.pet-name-input {
  max-width: 227px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  margin-bottom: 32px;
}

.form-group {
  margin-bottom: 16px;
}

.form-country-select {
  margin-bottom: 23px;
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
  font-size: 16px;
  letter-spacing: 0.86px;
}

.owner_label {
  margin-top: 20px;
  margin-bottom: 30px !important;
}
.field-group {
  &__title {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    &:after {
      content: '';
    }
  }

  &__content {
    position: relative;
  }
}

.centered {
  text-align: center;
}

.title-icon {
  width: 18px;
  height: 20px;
  margin-right: 8px;
}

.toggle-icon {
  width: 24px;
  height: 24px;
  margin-left: auto;
  transition: 0.2s ease-out;

  &.opened {
    color: #e6224a;
    transform: rotate(-180deg);
  }
}

.pet-edit {
  .edit-pet-img {
    z-index: 1;
    position: absolute;
    width: 51px;
    height: 51px;
    // left: 212px;
    cursor: pointer;
    position: absolute;
    left: 75%;
    top: 80%;

    @media only screen and (max-width: 600px) {
      position: absolute;
      left: unset;
      top: unset;
      right: 3px;
      bottom: 3px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  ion-avatar {
    width: 90px;
    height: 90px;
    margin: 40px auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    position: relative;

    &.pet-avatar {
      width: 210px;
      height: 210px;
      cursor: pointer;

      &.has-avatar {
        &:before {
          border: 12px solid #fff;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }

      &:before {
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        border-width: 2px;
        transition: 0.1s ease-out;
      }

      .upload-icon {
        position: absolute;
        width: 80px;
        height: 78px;
        border-radius: 0;
        z-index: 1;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      border-radius: 50%;
      border: 1px dashed #cccccc;
    }
  }
}

.owner-profile-pic-label {
  color: #222222;
  display: inline-block;
  font-family: var(--ion-poppins-font);
  font-size: 0.75rem;
  font-weight: var(--font-weight-bold);
}

.user-img {
  border-radius: 0 !important;
  border: none !important;
}

.user-icon {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  animation: zoom-in-zoom-out 1s ease infinite;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media only screen and (max-width: 600px) {
    position: relative;
    width: 128px;
    height: 128px;
    border-radius: 50%;
  }

  img {
    margin-top: 0px;
    margin-left: 0px;
    max-width: 128px;
    max-height: 128px;
    min-width: 128px;
    min-height: 128px;
    border-radius: 50%;
    border: 9px solid #fff;
  }

  &.user-placeholder {
    width: 95px;
    height: 95px;
  }
}

.upload-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ion-input {
    flex: 0;
  }

  ion-text {
    font-size: 10px;
    margin-left: 20px;
    flex: 1;

    strong {
      font-size: 12px;
      display: block;
      font-family: var(--ion-font-family);
    }
  }

  .upload {
    position: relative;
    z-index: 2;
  }

  .upload-icon {
    position: absolute;
    width: 42px;
    height: 40px;
    border-radius: 0;
    z-index: 1;
  }
}

.user-profile-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 46px;

  &.noDox {
    margin-top: 0;
  }

  img {
    cursor: pointer;
  }

  .edit-user-img {
    position: relative;
    width: 29px;
    height: 29px;
    left: 90px;
    bottom: 40px;
    z-index: 1;
    cursor: pointer;

    img {
      width: 29px;
      height: 29px;
      border-radius: 50%;
    }
  }

  .edit-placeholder-img {
    position: absolute;
    width: 29px;
    height: 29px;
    left: 53.5%;
    top: 28%;
    z-index: 1;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      position: absolute;
      width: 29px;
      height: 29px;
      left: 54%;
      top: 29.5%;
      z-index: 1;
    }

    img {
      width: 29px;
      height: 29px;
      border-radius: 50%;
    }
  }
}

.btn-link {
  margin-top: 0.75rem;
}

.submit {
  margin-top: 2.875rem;
}
</style>
<style lang="scss">
.user-profile-wrapper.noDox {
  div {
    height: 150px;

    .dox-shop-overlay {
      height: 150px;
    }

    .dox-shop-banner {
      height: 150px;
    }

    .photo-wrap {
      background: linear-gradient(0deg, white, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
      filter: blur(3px);

      .edit-user-img {
        top: 80%;
        left: 60%;

        img {
          position: relative;
          top: -150px;
        }
      }
    }
  }
}
</style>
