<template>
  <ion-page>
    <ion-content>
      <div class="container" style="background: white">
        <div class="content-wrapper">
          <div class="content">
            <div class="present-icon">
              <lottie-animation
                ref="anim"
                :autoPlay="true"
                :playInterval="10 * 1e3"
                :animationData="require('@/assets/images/lottie/present_camera.json')"
              />
            </div>

            <p v-if="canOpenCamera">
              Ha sikerült megtalálnod az elrejtett jutalomfalatot, a telefonod kamerájával olvasd le a rajta található
              QR-kódot, vagy ha látsz DOXOCIAL TAG-et a környéken érintsd hozzá a telefonod.
            </p>
            <p v-else>
              Ha sikerült megtalálnod az elrejtett jutalomfalatot, a telefonod beépített QR kód olvasójával olvasd le a
              rajta található QR-kódot, vagy ha látsz DOXOCIAL TAG-et a környéken érintsd hozzá a telefonod.
            </p>

            <ion-button
              color="light"
              expand="block"
              shape="round"
              strong="true"
              shadow="true"
              class="btn btn-dox"
              @click="goToPresent"
            >
              Vissza a jutalomfalathoz
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { IonContent, IonPage, IonButton, IonRippleEffect, isPlatform } from '@ionic/vue'
import { useRoute, useRouter } from 'vue-router'

import usePets from '@/composables/pets'

import { PET_WALKING_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'PresentCamera',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonRippleEffect,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { PETS_STATE } = usePets()

    const presentId = computed(() => route.params.id)
    const canOpenCamera = computed(() => !isPlatform('ios'))
    const petsWalking = computed(() => PETS_STATE.petsWalking)

    const goToPresent = async () => {
      if (petsWalking.value.length > 0) {
        await router.push({
          name: PET_WALKING_ROUTE,
          query: {
            present: presentId.value,
          },
        })
      } else {
        console.log('Nem sétálsz, hogy kerültél ide?')
      }
    }

    return {
      canOpenCamera,
      goToPresent,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.container {
  display: flex;
  align-items: center;
  height: 100%;
}

p {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: -0.64px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0;

  .present-icon {
    margin-bottom: 20px;
  }
}

ion-button {
  width: 100%;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 512px) {
  .present-icon {
    width: 100%;
  }
}
</style>
