<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div v-if="isLoading" class="content">
          <ion-spinner name="crescent"></ion-spinner>
        </div>
        <div class="content-wrapper" v-else>
          <div class="content" v-if="present">
            <div class="present-icon">
              <lottie-animation
                ref="anim"
                :autoPlay="true"
                :playInterval="10 * 1e3"
                :animationData="require('@/assets/images/lottie/present_success.json')"
              />
            </div>

            <h1>NYERTES</h1>
            <p style="margin-bottom: 30px">
              Kérjük biztosítsátok a Játékos számára a nyereménye átvételét, Köszönjük!<br /><br />
              Egyedi azonosító: <br /><b>{{ identifier }}</b>
            </p>

            <h2>{{ present.poi_name }}</h2>
            <p style="margin-top: 0">({{ present.poi_address }})</p>
          </div>
          <div class="content not-found" v-else>
            <PresentInvalid />
          </div>

          <ion-button
            color="primary"
            expand="block"
            shape="round"
            strong="true"
            @click="submitPresentHandler"
            class="btn btn-dox"
            v-if="present"
          >
            Átadtam a nyereményt
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
          <ion-button
            color="primary"
            expand="block"
            shape="round"
            strong="true"
            @click="callSupport"
            class="btn btn-dox"
            v-else
          >
            Kapcsolatfelvétel
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { CallNumber } from '@ionic-native/call-number'
import { IonContent, IonPage, IonSpinner, IonButton, IonRippleEffect } from '@ionic/vue'
import { useRoute, useRouter } from 'vue-router'

import usePois from '@/composables/pois'
import useToast from '@/composables/toast'
import useGeoLocation from '@/composables/geolocation'

import { FEED_ROUTE } from '@/constants/routes'

import PresentInvalid from '@/components/Present/PresentInvalid'

export default defineComponent({
  name: 'PresentScan',
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    IonButton,
    IonRippleEffect,
    PresentInvalid,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const present = ref(null)
    const { openToast } = useToast()
    const { getCurrentPosition } = useGeoLocation()

    const presentId = computed(() => route.params.id)
    const identifier = computed(() => route.params.code)

    const { getPresent, checkPresentQR, submitPresent } = usePois()

    const getUserCoords = async () => {
      try {
        const res = await getCurrentPosition(null)
        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const getDistanceBetweenTwoCoords = (coords1, coords2) => {
      const R = 6371e3 // metres
      const φ1 = (coords1.latitude * Math.PI) / 180
      const φ2 = (coords2.latitude * Math.PI) / 180
      const Δφ = ((coords2.latitude - coords1.latitude) * Math.PI) / 180
      const Δλ = ((coords2.longitude - coords1.longitude) * Math.PI) / 180

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      return R * c
    }

    const submitPresentHandler = async () => {
      try {
        await submitPresent(presentId.value, identifier.value)

        await openToast({
          message: 'Sikeresen átadtad a nyereményt, hamarosan átirányítunk.',
          color: 'success',
        })

        setTimeout(async () => {
          await router.push({ name: FEED_ROUTE })
        }, 1500)
      } catch (error) {
        console.log(error)

        await openToast({
          message: 'Nem sikerült átadni a nyereményt. Keress fel minket a(z) +36/20 233-3818-as telefonszámon.',
          color: 'danger',
        })
      }
    }

    const callSupport = async () => {
      try {
        await CallNumber.callNumber('+36202333818')
      } catch (error) {
        await openToast({
          message: 'Nem sikerült elindítani a hívást.',
          color: 'danger',
        })
      }
    }

    onMounted(async () => {
      try {
        present.value = await getPresent(presentId.value)

        if (present.value) {
          const { coords } = await getUserCoords()

          if (coords.latitude && coords.longitude) {
            const distance = getDistanceBetweenTwoCoords(coords, {
              latitude: present.value.latitude,
              longitude: present.value.longitude,
            })

            if (distance > present.value.minimum_distance) {
              present.value = null
            } else {
              await checkPresentQR(presentId.value, identifier.value)
            }
          }
        }

        isLoading.value = false
      } catch (error) {
        console.error(error)
        present.value = null
        isLoading.value = false
      }
    })

    return {
      present,
      presentId,
      isLoading,
      identifier,
      callSupport,
      submitPresentHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

h1,
h2 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -0.5px;
}

h2 {
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}

p {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: -0.64px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  padding: 0 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0 !important;

  .present-icon {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }
}
</style>
