<template>
  <ion-content :class="{ 'is-missing': isPetMissing }">
    <div class="distance" v-if="showDistance || refreshDistance">
      <div class="quarter-circle">
        <span class="distance-content">
          <span class="distance-text">{{ targetPet.distance }}</span>
          <span class="distance-measure" v-if="targetPet.meterDistance == false">km</span>
          <span class="distance-measure" v-else>m</span>
        </span>
      </div>
      <span class="pulse"></span>
      <span class="pulse"></span>
    </div>
    <div class="content" :class="{ blocked: pet.is_blocked }">
      <div class="pet" v-if="pet">
        <PetAvatar v-if="pet.avatar != null" :image="pet.avatar" :is-missing="isPetMissing" />

        <div>
          <div class="user-icon" :style="{ visibility: !pet.user_avatar ? 'hidden' : 'visible' }" v-if="isMyPet(pet)">
            <img v-if="pet.user_avatar != null" :src="pet.user_avatar" alt="user-icon" />
            <img v-else :src="userIcon" alt="user-icon" />
          </div>

          <div class="user-icon" :class="pet.user_avatar ? '' : 'user-placeholder'" v-else-if="!isMyPet(pet)">
            <img v-if="pet.user_avatar != null" :src="pet.user_avatar" alt="user-icon" />
          </div>
        </div>
        <h1 class="pet-name">{{ pet.name }}</h1>
        <h2 class="pet-location">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
            <path
              d="M6 0C9.308 0 12 2.54125 12 5.66562C12 10.105 6.564 14.6887 6.33267 14.8812C6.23733 14.9606 6.11867 15 6 15C5.88133 15 5.76267 14.9606 5.66733 14.8819C5.436 14.6888 0 10.105 0 5.66562C0 2.54125 2.692 0 6 0ZM6 8.75C7.838 8.75 9.33333 7.34812 9.33333 5.625C9.33333 3.90188 7.838 2.5 6 2.5C4.162 2.5 2.66667 3.90188 2.66667 5.625C2.66667 7.34812 4.162 8.75 6 8.75Z"
              fill="#48E453"
            />
          </svg>
          {{ pet.city.name }}
        </h2>

        <div v-if="pet.is_blocked" class="blocked-back" />

        <div v-if="pet.is_blocked" class="blocked-container">
          <div class="blocked">
            <ion-img :src="require('@/assets/images/icons/pet/blocked-icon.svg')" />

            <h1>Letiltott Felhasználó</h1>

            <ion-button
              color="dark"
              fill="solid"
              shape="round"
              @click="unblockPet"
              class="btn pet-action-edit blocked-btn"
            >
              Blokkolás feloldása
            </ion-button>
          </div>
        </div>

        <p class="pet-bio">{{ isPetMissing ? pet.missing.description : pet.bio }}</p>

        <div class="pet-actions">
          <div v-if="!isMyPet(pet) && isPetMissing" class="pet-action">
            <a :href="`tel:${pet.phone}`" class="pet-phone">
              <img class="pet-action-icon" src="@/assets/images/icons/call.svg" alt="follow-pet" />
              <span class="pet-action-name small">Gazdi hívása</span>
            </a>
          </div>
          <div v-if="!isMyPet(pet) && !isPetMissing" @click="petFollowAction" class="pet-action">
            <lottie-animation
              ref="anim"
              :autoPlay="false"
              :playInterval="1e3 * 12"
              :playOnClick="true"
              class="pet-action-icon lottie"
              :animationData="require('@/assets/images/lottie/follow.json')"
              v-if="!pet.is_followed"
            />
            <img class="pet-action-icon" :src="followIcon" alt="follow-pet" v-else />
            <span class="pet-action-name small">{{ pet.is_followed ? 'Követed' : 'Követem' }}</span>
          </div>
          <div v-if="isMyPet(pet) && isAuthenticated" class="pet-action">
            <ion-button
              color="dark"
              fill="solid"
              shape="round"
              @click="navigateTo({ name: PET_EDIT_ROUTE, params: { id: pet.id } })"
              class="btn pet-action-edit"
            >
              Profil szerkesztése
            </ion-button>
          </div>
          <div @click="navigateTo({ name: PET_INFO_ROUTE, params: { id: pet.id } })" class="pet-action">
            <img class="pet-action-icon" src="@/assets/images/icons/info.svg" alt="pet-info" />
            <span class="pet-action-name small" :class="{ 'ion-hide': isMyPet(pet) }">Infó</span>
          </div>
          <div v-if="!isMyPet(pet)" @click="petMessageAction" class="pet-action">
            <img class="pet-action-icon" src="@/assets/images/icons/message.svg" alt="message-pet" />
            <span class="pet-action-name small">Üzenet</span>
          </div>
        </div>

        <ul class="pet-social">
          <li class="pet-social-item">
            <img class="pet-social-icon" src="@/assets/images/icons/like-outline.svg" alt="" />
            {{ pet.like_count }} tappancs
          </li>
          <li class="pet-social-item">
            <img class="pet-social-icon" src="@/assets/images/icons/follow-outline.svg" alt="" />
            {{ pet.follow_count }} követő
          </li>
        </ul>

        <div class="pet-feed">
          <PetPosts />
        </div>

        <div class="pet-block" v-if="!isMyPet(pet) && !pet.is_blocked" @click="blockPet">
          <ion-img :src="require('@/assets/images/icons/pet/block-pet.svg')" />

          Felhasználó blokkolása
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { IonContent, IonIcon, IonButton, alertController } from '@ionic/vue'
import httpClient from '@/core/api/api.service'
import PET_STATUS from '@/constants/pet-status'
import useNavigation from '@/composables/navigation'
import { PAGE_CHAT_ROUTE, PET_INFO_ROUTE, PET_EDIT_ROUTE, LOGIN_ROUTE } from '@/constants/routes'
import usePets from '@/composables/pets'
import useAuth from '@/composables/auth'
import usePetFinder from '@/composables/petFinder'
import PetAvatar from '@/components/Pet/PetAvatar'
import PetPosts from '@/components/Pet/PetPosts'
import { Capacitor } from '@capacitor/core'
import router from '../../router'
//import { useStore } from 'vuex'

import { useStore } from 'vuex'

export default defineComponent({
  name: 'PetProfile',
  components: {
    IonContent,
    IonIcon,
    IonButton,
    PetAvatar,
    PetPosts,
  },
  setup() {
    const { getSniffDistance } = usePetFinder()
    const { navigateTo } = useNavigation()
    const route = useRoute()
    const { isMyPet, pet, PETS_STATE, petsInMap, blockPet: blockOtherPet } = usePets()
    const { isAuthenticated } = useAuth()
    const { activePet } = usePets()
    const petId = computed(() => route.params.id)
    const mustRefresh = computed(() => route.params.refresh)
    const isPetMissing = computed(() => pet.value && pet.value.status === PET_STATUS.MISSING.name)
    const followIcon = computed(() => {
      return pet.value.is_followed
        ? require(`@/assets/images/icons/followed.svg`)
        : require(`@/assets/images/icons/follow.svg`)
    })

    const targetPet = reactive({
      id: null,
      distance: 0.0,
      meterDistance: false,
    })
    //const store = useStore()
    //const userState = store.state.auth.user
    const userIcon = computed(() => {
      return require('@/assets/images/icons/profile-user-red-but-actually-gray.svg')
    })

    const refreshDistance = computed(() => {
      var isMy = false
      if (PETS_STATE) {
        isMy = PETS_STATE?.pets?.findIndex(item => item.id == petId.value) > -1
      } else {
        isMy = false
      }

      const res =
        // Capacitor.isNative &&
        mustRefresh.value &&
        !isMy &&
        router.options.history.state.back == '/pet-walking' &&
        PETS_STATE.activeWalking &&
        targetPet.distance != null
      return res
    })

    const showDistance = computed(() => {
      const res =
        Capacitor.isNative &&
        router.options.history.state.back == '/pet-finder' &&
        typeof targetPet.distance != 'undefined' &&
        targetPet.distance !== null
      return res
    })

    var refResher = null

    // https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
    const getPetDistance = () => {
      if (petsInMap.value && petsInMap.value.length && petId.value) {
        const petInMap = petsInMap.value.find(p => p.petId == petId.value)
        const myPetInMap = petsInMap.value.find(p => p.current == true)
        if (petInMap && myPetInMap) {
          const { latitude, longitude } = petInMap
          return haversineDistance(
            { latitude: myPetInMap.latitude, longitude: myPetInMap.longitude },
            { latitude, longitude },
          ).toFixed()
        } else {
          return undefined
        }
      } else {
        return undefined
      }
    }

    const haversineDistance = (mk1, mk2) => {
      console.log(mk1, mk2)
      var R = 6371.071 * 1000 // Radius of the Earth in m
      var rlat1 = mk1.latitude * (Math.PI / 180) // Convert degrees to radians
      var rlat2 = mk2.latitude * (Math.PI / 180) // Convert degrees to radians
      var difflat = rlat2 - rlat1 // Radian difference (latitudes)
      var difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180) // Radian difference (longitudes)

      var d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2),
          ),
        )
      // return m
      return d
    }
    const store = useStore()
    const userState = store.state.auth.user
    const userHasDox = userState?.pets?.filter(x => x.has_dox == true).length > 0 ? true : false

    onMounted(() => {
      console.log('profile onMounted')
      if (refreshDistance.value && !refResher) {
        console.log('refresh distance')
        var distance = getPetDistance()
        var dKilo = false
        var isKiloMeter = false
        if (distance) {
          dKilo = (distance / 1000).toFixed(1)
          isKiloMeter = dKilo >= 1
          console.log(dKilo, isKiloMeter)
          targetPet.id = petId
          targetPet.distance = isKiloMeter ? dKilo : distance
          targetPet.meterDistance = isKiloMeter ? false : true
        }

        refResher = setInterval(() => {
          var distance = getPetDistance()
          if (distance) {
            dKilo = (distance / 1000).toFixed(1)
            isKiloMeter = dKilo >= 1
            console.log(dKilo, isKiloMeter)
            targetPet.id = petId
            targetPet.distance = isKiloMeter ? dKilo : distance
            targetPet.meterDistance = isKiloMeter ? false : true
          } else {
            targetPet.id = null
            targetPet.distance = null
            targetPet.meterDistance = false
          }
          console.log('targetPet', targetPet.id, targetPet.distance)
        }, 5000)
      } else {
        getSniffDistance().then(resp => {
          if (resp === false) {
            return
          }
          targetPet.id = resp.data.data.id
          targetPet.distance =
            resp.data.data.distance != null
              ? Math.round(resp.data.data.distance / 1000) > 0.9
                ? Math.round(resp.data.data.distance / 1000)
                : resp.data.data.distance.toFixed()
              : 0
          targetPet.meterDistance =
            resp.data.data.distance != null ? (Math.round(resp.data.data.distance / 1000) > 0.9 ? false : true) : false
        })
      }
    })

    onUnmounted(() => {
      console.log('profile onUnMounted')
      if (refResher) {
        clearInterval(refResher)
        refResher = null
      }
    })

    async function followPet() {
      try {
        await httpClient.save(`pets/${petId.value}/follow`)
        pet.value.is_followed = true
        pet.value.follow_count++
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    async function unfollowPet() {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog',
        header: 'Követés leállítása',
        message: `Biztos vagy benne, hogy leállítod <strong>${pet.value.name}</strong> követését?`,
        buttons: [
          {
            text: 'Igen',
            cssClass: 'btn btn-primary',
            handler: async () => {
              try {
                await httpClient.save(`pets/${petId.value}/follow`)
                pet.value.is_followed = false
                pet.value.follow_count--
                return Promise.resolve()
              } catch (error) {
                return Promise.reject(error)
              }
            },
          },
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn',
          },
        ],
      })

      return dialog.present()
    }

    function petFollowAction() {
      if (!isAuthenticated.value) {
        return navigateTo({ name: LOGIN_ROUTE })
      }

      return pet.value.is_followed ? unfollowPet() : followPet()
    }

    function petMessageAction() {
      return isAuthenticated.value
        ? navigateTo({ name: PAGE_CHAT_ROUTE, params: { pet: pet.value.id, mypet: activePet.value.id } })
        : navigateTo({ name: LOGIN_ROUTE })
    }

    const unblockPet = async () => {
      try {
        await httpClient.save(`pets/${petId.value}/unblock`)
        pet.value.is_blocked = false
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const blockPet = async () => {
      const dialog = await alertController.create({
        cssClass: 'confirm-dialog poi-dialog',
        message: `
          <div>
            <img src="${require('@/assets/images/icons/pet/blocked-icon.svg')}" class="alert-icon" />
            <h1>Biztosan tiltsuk le?</h1>
            <p class="centered">Ha letiltod a Felhasználót, nem láthatod a tartalmait és nem léphet kapcsolatba veled, amíg fel nem oldod a korlátozást.</p>
          </div>
        `,
        buttons: [
          {
            text: 'Mégse',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block',
          },
          {
            text: 'Igen, blokkolom',
            cssClass: 'btn btn-dark btn-block',
            handler: async () => {
              try {
                await blockOtherPet(petId.value)

                pet.value.is_blocked = true
              } catch {
                //
              } finally {
                dialog.dismiss()
              }
            },
          },
        ],
      })

      return dialog.present()
    }

    return {
      pet,
      isMyPet,
      followPet,
      unfollowPet,
      isPetMissing,
      Capacitor,
      navigateTo,
      PET_INFO_ROUTE,
      PET_EDIT_ROUTE,
      followIcon,
      isAuthenticated,
      petFollowAction,
      petMessageAction,
      targetPet,
      activePet,
      userIcon,
      showDistance,
      refreshDistance,
      userHasDox,
      blockPet,
      unblockPet,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&family=Poppins:wght@800&display=swap');

.ios {
  .quarter-circle {
    margin-top: 35px;
  }
  .pulse {
    margin-top: 35px;
  }

  .distance {
    @media only screen and (max-width: 600px) {
      top: 80px;
    }
  }
}
.content {
  //margin-top: 0 !important;
  position: relative;

  // &.blocked {
  //   .pet-bio,
  //   .pet-actions,
  //   .pet-social,
  //   .pet-feed {
  //     filter: blur(5px);
  //   }
  // }
}

.blocked-container {
  display: relative;
  height: 0;
  z-index: 10;

  .blocked {
    display: absolute;

    ion-img {
      max-width: 48px;
      margin: 10px auto 0 auto;
    }

    h1 {
      font-family: 'Open Sans', sans-serif !important;
      font-weight: 800 !important;
      font-size: 13px !important;
      line-height: 17.7px;
      letter-spacing: -0.3px;
      text-align: center;
      margin: 0 0 50px 0;
    }
  }
}

.distance {
  position: absolute;
  top: var(--header-height-web);
  @media only screen and (max-width: 600px) {
    top: var(--header-height-mobile);
  }
  left: 0;
}

.quarter-circle {
  width: 50px;
  height: 50px;
  background-color: #89e653;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 0px;
  border-bottom: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.distance-content {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  line-height: 1;
}

.distance-text,
.distance-measure {
  color: #fff;
  font-weight: 700;
  font-size: 12px;
}

.is-missing {
  @media (max-width: 767px) {
    --background: linear-gradient(to bottom, #ffffff, #ffffff 56px, #fbb7b8 56px, #ffffff);

    &.ios {
      --background: linear-gradient(to bottom, #ffffff, #ffffff 76px, #fbb7b8 76px, #ffffff);

      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 12 Mini, iPhone 11 Pro, iPhone Xs, and iPhone X
        --background: linear-gradient(to bottom, #ffffff, #ffffff 105px, #fbb7b8 105px, #ffffff);
      }

      @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 12 and iPhone 12 Pro
        --background: linear-gradient(to bottom, #ffffff, #ffffff 105px, #fbb7b8 105px, #ffffff);
      }

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
        // iPhone 11 and iPhone XR
        --background: linear-gradient(to bottom, #ffffff, #ffffff 105px, #fbb7b8 105px, #ffffff);
      }

      @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
        // iPhone 11 Pro Max and iPhone Xs Max
        --background: linear-gradient(to bottom, #ffffff, #ffffff 105px, #fbb7b8 105px, #ffffff);
      }
    }
  }

  @media (min-width: 768px) {
    --background: linear-gradient(to bottom, #ffffff, #ffffff 80px, #fbb7b8 80px, #ffffff);
  }

  .pet-location {
    ion-icon {
      color: #f64242;
    }
  }
}

.pet {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  max-width: 285px;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding-top: 30px;
  }

  @media (min-width: 768px) {
    padding-top: 40px;
  }
}

.pet-avatar {
  border: 12px solid #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    min-width: 234px;
    min-height: 234px;
  }

  @media (min-width: 768px) {
    min-width: 280px;
    min-height: 280px;
  }

  &__ribbon {
    background: #f64242;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    height: 34px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.04167em;
  }
}

.user-icon {
  min-height: 84px;
  border-radius: 50%;
  position: relative;
  right: -100%;
  top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 7px solid #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  @media only screen and (max-width: 600px) {
    border-radius: 50%;
    position: relative;
    //right: -26%;
    //top: -75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  &.user-placeholder {
    border: none;
  }
}

.pet-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  margin: calc(-90px + 34px) 0 4px 0;
  letter-spacing: -0.8px;
  line-height: 36.57px;

  @media (max-width: 767px) {
    font-size: 30px !important;
  }

  @media (min-width: 768px) {
    font-size: 2.5em !important;
  }
}

.pet-phone {
  text-decoration: none;
}

.pet-location {
  display: flex;
  align-items: center;
  color: #333;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  line-height: 14px;
  font-size: 13px;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;

  svg {
    margin-right: 5px;
  }
}

.pet-bio {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #6f6f6f;
  margin: 0;
  line-height: 20px;

  @media (max-width: 767px) {
    font-size: 13px;
  }

  @media (min-width: 768px) {
    font-size: 0.875em;
  }
}

.pet-actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}

.pet-action {
  cursor: pointer;
  min-width: 80px;

  &:not(:last-child) {
    margin-right: 25px;
  }

  &-edit {
    height: 40px;
    max-height: 40px;
  }

  &-name {
    display: block;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 14px;
      margin-top: 12px;
    }

    @media (min-width: 768px) {
      font-size: 0.875em;
      margin-top: 18px;
    }
  }

  &-icon {
    &.lottie {
      min-width: 60px;
      min-height: 60px;
      top: -10px;

      margin: 0 auto;

      & ~ .pet-action-name {
        margin-top: -2px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 40px;
      margin-top: 7px;
    }

    @media (min-width: 768px) {
      width: 50px;
      height: 50px;
    }
  }
}

.pet-social {
  display: flex;
  list-style: none;
  margin: 50px 0 0 0;
  padding: 0;
}

.pet-social-item {
  color: #9e9e9e;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 14px;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    font-size: 0.875em;
  }

  &:first-child {
    padding-right: 22px;
    border-right: 2px solid #9e9e9e;
  }

  &:last-child {
    padding-left: 22px;
  }
}

.pet-social-icon {
  margin-right: 11px;
}

.pet-feed {
  min-width: calc(((100vw - 285px) / -2) + 1rem);
}

.pet-block {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 13px;
  line-height: 17.7px;
  letter-spacing: -0.3px;
  color: #c2c2c2;

  ion-img {
    max-width: 21px;
    min-width: 21px;
    margin-right: 8px;
  }
}

.pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 260px;
    height: 260px;
    border: 2px solid #06ff00;
    border-radius: 50%;
    animation: pulse 5s infinite;
    content: '';
    z-index: -1;
    transform-origin: center;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  &:after {
    animation-delay: 1s;
  }

  &:nth-child(odd) {
    animation-delay: 1.5s;

    &:before {
      animation-delay: 2s;
    }

    &:after {
      animation-delay: 3s;
    }
  }
}

.blocked-back {
  position: absolute;
  left: 0;
  right: 0;
  top: 350px;
  bottom: 0;
  z-index: 2;
  backdrop-filter: blur(6px);
  background: linear-gradient(180deg, rgba(249, 249, 249, 0.6) 0%, #f9f9f9 100%);

  @media screen and (min-width: 767px) {
    top: 410px;
  }
}

.blocked-btn {
  font-size: 14px;
  min-height: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  letter-spacing: -0.64px;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  25% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
</style>
