<template>
  <IonPage>
    <IonContent>
      <div class="content" v-if="selectedPoi?.type">
        <div class="poi__icon">
          <ion-img :src="getCustomMarkerIcon(selectedPoi)" class="marker" />

          <ion-img :src="activePet.avatar" class="pet" />
        </div>
        <div class="poi__header">
          <div class="poi__header__content">
            <h1 class="poi__name">{{ poiName || 'A Te helyed' }}</h1>
            <p class="poi__address">{{ selectedPoi.location }}</p>
          </div>
        </div>
        <div class="poi__images">
          <h1 class="poi__subtitle">Képek a helyről:</h1>
          <div class="poi__images__list">
            <div
              class="poi__images__list__item"
              v-for="(image, key) in photos"
              :key="key"
              :style="`background-image: url(${image.src});`"
            ></div>
            <div class="poi__images__list__item" @click="handlePhotoTake">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>
          </div>
          <p class="poi__images__help">
            Kérjük, hogy általatok készített képeket töltsetek föl a helyről. A legjobb, ha a Kedvencetek is szerepel
            rajta!
          </p>
          <p v-if="showImagesError" style="padding: 0 34px; color: #e6224a; font-weight: 600; text-align: center">
            Kötelező legalább egy képet feltölteni.
          </p>
        </div>
        <div class="poi__about">
          <h1 class="poi__subtitle">Hely neve:</h1>
          <IonInput type="text" class="form-control" v-model="poiName" required placeholder="Add meg a hely nevét." />
        </div>
        <div class="poi__about">
          <h1 class="poi__subtitle">Információk:</h1>
          <IonTextarea
            rows="5"
            required
            class="form-control"
            v-model="poiDescription"
            placeholder="Mesélj néhány szóban a helyről."
          />
        </div>

        <div class="poi__about" style="display: flex; justify-content: center">
          <IonButton class="btn-strong" shape="round" strong="true" @click="handlePoiCreation"
            >Hely beküldése</IonButton
          >
        </div>
      </div>
    </IonContent>
  </IonPage>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import { IonPage, IonContent, IonTextarea, IonButton, IonInput, alertController } from '@ionic/vue'

import usePets from '@/composables/pets'
import usePois from '@/composables/pois'
import usePhotoGallery from '@/composables/camera'
import useToast from '@/composables/toast'
import { useRoute, useRouter } from 'vue-router'

import { MAPS_API } from '@/constants/types'
import { Loader } from '@googlemaps/js-api-loader'

export default defineComponent({
  name: 'PoiCreation',
  components: {
    IonPage,
    IonContent,
    IonTextarea,
    IonButton,
    IonInput,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { activePet } = usePets()
    const { createPoi } = usePois()
    const { openToast } = useToast()
    const { takePhoto } = usePhotoGallery()
    const selectedPoi = ref({})
    const showImagesError = ref(false)
    const type = route.params.type
    const lat = route.params.lat
    const lng = route.params.lng

    //MAPS_API_IOS
    const loader = new Loader({
      apiKey: MAPS_API,
      version: 'weekly',
      libraries: [],
    })

    onMounted(async () => {
      await loader.load()
      const geocoder = new window.google.maps.Geocoder()

      try {
        const geocodeResponse = await geocoder.geocode({
          location: new window.google.maps.LatLng(lat, lng),
        })

        const zipCode = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('postal_code'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const cityName = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('locality'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const roadName = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('route'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const houseNumber = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('street_number'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const poiName = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('point_of_interest'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        selectedPoi.value = {
          type: type,
          name: null,
          location: `${zipCode} ${cityName ?? ''}, ${roadName ?? ''} ${houseNumber ?? poiName ?? ''}`,
          images: [],
          description: null,
          latitude: lat,
          longitude: lng,
        }
      } catch (error) {
        console.error(error)
      }
    })

    const poiDescription = ref(selectedPoi.value.description)
    const poiName = ref(selectedPoi.value.name)

    const photos = ref([])

    const getCustomMarkerIcon = marker => {
      if (marker.type === 'walkplace') {
        return require('@/assets/images/icons/walk/walkplace-marker.svg')
      } else if (marker.type === 'dogbeach') {
        return require('@/assets/images/icons/walk/dogbeach-marker.svg')
      } else if (marker.type === 'dogfriendly') {
        return require('@/assets/images/icons/walk/dogfriendly-marker.svg')
      }

      return require('@/assets/images/icons/walk/unknown-marker.svg')
    }

    const handlePhotoTake = async () => {
      try {
        const photo = await takePhoto()

        photos.value.push({
          src: photo.webviewPath,
          value: photo.url,
        })

        console.log(photos.value)
      } catch (error) {
        console.error(error)
      }
    }

    const handlePoiCreation = async () => {
      showImagesError.value = false

      if (!poiName.value || !photos.value.length) {
        if (!photos.value.length) {
          showImagesError.value = true
        }

        await openToast({
          message: 'A kötelező mezők nincsenek kitöltve. Biztos mindent kitöltöttél?',
          color: 'warning',
        })

        return
      }

      try {
        await createPoi({
          name: poiName.value,
          type: selectedPoi.value.type,
          latitude: selectedPoi.value.latitude,
          longitude: selectedPoi.value.longitude,
          location: selectedPoi.value.location,
          description: poiDescription.value,
          images: photos.value,
        })

        const icon = getCustomMarkerIcon(selectedPoi.value)

        const dialog = await alertController.create({
          cssClass: 'confirm-dialog poi-dialog',
          message: `
            <div class="poi-info">
              <img src="${icon}" class="icon" />
              <h1>${poiName.value}</h1>
            </div>
            <p>Sikeresen elküldted nekünk a helyszínt. Most átvizsgájuk, hogy megfelel-e az irányelveinknek és értesítünk az állapotáról!</p>
          `,
          buttons: [
            {
              text: 'Bezárás',
              role: 'cancel',
              cssClass: 'btn btn-link btn-block btn-popup-close',
            },
          ],
        })

        await router.go(-1)
        await dialog.present()
      } catch (error) {
        console.error(error)

        await openToast({
          message: 'Sajnos nem sikerült a helyet létrehozni :(',
          color: 'danger',
        })
      }
    }

    return {
      photos,
      poiName,
      activePet,
      selectedPoi,
      poiDescription,
      handlePhotoTake,
      handlePoiCreation,
      getCustomMarkerIcon,
      showImagesError,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.content {
  margin-top: -50px;
}

h1 {
  font-weight: 700;
  font-size: 12px !important;
  text-align: left;
  margin: 0 34px 5px 34px;
}

.poi {
  &__icon {
    width: fit-content;
    margin: 160px auto 0 auto;
    position: relative;

    .marker,
    .marker::part(image) {
      min-height: 65px;
      max-height: 65px;
      min-width: 50px;
      max-width: 50px;
    }

    .pet {
      position: absolute;
      right: -10px;
      bottom: -16px;
      border: 4px solid #fff;
      border-radius: 99999px;
      min-width: 38px;
      max-width: 38px;
    }

    .pet::part(image) {
      min-height: 30px;
      max-height: 30px;
      border-radius: 99999px;
    }
  }

  &__name {
    margin-top: 26px;
    margin-bottom: 2px;
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.3px;
    font-size: 15px !important;
    font-family: 'Poppins', sans-serif;
  }

  &__address {
    margin-top: 0;
  }

  &__header {
    text-align: center;
    word-wrap: break-word;
    padding: 0 34px;
    margin-bottom: 40px;
  }

  &__subtitle {
    padding: 0 16px;
  }

  &__images {
    margin-bottom: 27px;

    &__list {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 5px 34px 0 34px;
      margin-bottom: -27px;
      padding-bottom: 27px;

      &__item {
        background: #fff;
        min-width: 140px;
        aspect-ratio: 1 / 1;

        background-position: center center;
        background-size: cover;
        border-radius: 25px;

        margin-right: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &__help {
      padding: 0 34px;
      text-align: center;
      color: #afafaf;
    }
  }

  &__about {
    text-align: left;
    padding: 0 34px;
    margin-bottom: 27px;

    h1 {
      font-weight: 700;
      font-size: 12px !important;
      text-align: left;
      margin: 0 0 5px 0;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }
}

ion-input,
ion-textarea {
  border: none;
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  font-weight: 700;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  font-size: 14px;
  --placeholder-font-weight: 400;
}

ion-textarea {
  height: 124px;
}

ion-button {
  height: 50px;
  --background: #e6224a;
  max-width: 238px;
  --box-shadow: none;
}
</style>
