<template>
  <ion-img :src="require('@/assets/images/icons/present/404.svg')" />

  <h1>HIBÁS KÓD</h1>

  <p style="margin-bottom: 30px">
    Valami nem stimmel a kóddal, kérjük olvassátok le újra és vegyétek fel velünk a kapcsolatot a lenti gombra
    kattintva!
  </p>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg } from '@ionic/vue'

export default defineComponent({
  name: 'PresentInvalid',
  components: {
    IonImg,
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

h1,
h2 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: 900;
}

h2 {
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}

p {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: -0.64px;
}

ion-img {
  max-width: 100px;
  margin: 0 0 50px 0;
}
</style>
