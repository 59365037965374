<template>
  <IonPage>
    <IonContent>
      <div class="content" v-if="selectedEvent?.date && page === 'edit-event'">
        <div class="poi__icon">
          <ion-img :src="require('@/assets/images/icons/walk/event-active.svg')" class="marker" />
        </div>
        <div class="poi__header">
          <div class="poi__header__content">
            <h1 class="poi__name">{{ poiName || 'A Te eseményed' }}</h1>
            <p class="poi__address">{{ selectedEvent.location }}</p>

            <div class="picker-group">
              <VueScrollPicker :options="selectHours" v-model="selectedHour" />
              <VueScrollPicker :options="selectMinutes" v-model="selectedMinute" />
            </div>

            <div class="date-selector">
              <div class="display" @click="dateSelectorOpen = true">
                <p>{{ date }}.</p>
                <div class="edit">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <path
                      d="M0.843719 12.1618C0.663359 12.047 0.637593 11.9838 0.691467 11.7706C0.913989 10.8759 1.13651 9.97875 1.36372 9.08398C1.38011 9.01605 1.41993 8.94343 1.47146 8.89425C3.54443 6.8166 5.61974 4.74129 7.69505 2.66598C7.71847 2.64256 7.74424 2.62148 7.76063 2.60742C8.58279 3.42958 9.40026 4.24706 10.2412 5.08795C10.2131 5.10669 10.1732 5.12309 10.1451 5.1512C8.08387 7.21011 6.02496 9.26902 3.96371 11.3256C3.89812 11.3912 3.80677 11.4427 3.71776 11.4661C2.82768 11.6933 1.93525 11.9159 1.04282 12.1384C1.02174 12.1431 1.00065 12.1548 0.981916 12.1618C0.93507 12.1618 0.888223 12.1618 0.843719 12.1618Z"
                      fill="#686868"
                    />
                    <path
                      d="M12.1663 2.77319C12.1499 2.83409 12.1429 2.89968 12.1172 2.95355C12.0422 3.10581 11.986 3.28148 11.8735 3.40094C11.5737 3.72418 11.2458 4.02166 10.9366 4.32382C10.1285 3.51571 9.31103 2.69824 8.50293 1.89013C8.80509 1.58095 9.09554 1.24833 9.42347 0.9532C9.85914 0.564373 10.5876 0.573742 11.0233 0.964912C11.3208 1.23194 11.5948 1.52473 11.8735 1.81284C12.0445 1.99085 12.1125 2.22275 12.1663 2.45698C12.1663 2.56238 12.1663 2.66779 12.1663 2.77319Z"
                      fill="#686868"
                    />
                  </svg>
                </div>
              </div>

              <div class="picker-group date" v-if="dateSelectorOpen">
                <div style="width: 100%; display: flex">
                  <VueScrollPicker :options="selectableYears" v-model="selectedYear" />
                  <VueScrollPicker :options="selectableMonths" v-model="selectedMonth" />
                  <VueScrollPicker :options="selectableDays" v-model="selectedDay" />
                </div>

                <div
                  class="checkmark"
                  @click="
                    () => {
                      dateSelectorOpen = false
                      date = `${selectedYear}.${selectedMonth}.${selectedDay}`
                    }
                  "
                >
                  <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="11" fill="#E6224A" />
                    <path
                      d="M9.32679 16.5L4.40112 11.9094L6.75031 9.72022L9.32679 12.1212L15.2519 6.59998L17.6011 8.78918L9.32679 16.5Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="poi__pet__list">
          <h1>Szervezők:</h1>
          <div class="poi__pet__list__container">
            <div class="poi__pet__list__list">
              <div class="poi__pet__list__list__item">
                <ion-img :src="activePet.avatar" />
              </div>

              <div
                class="poi__pet__list__list__item"
                v-for="pet in selectedPets"
                :key="pet.id"
                @click="removePet(pet.id)"
              >
                <ion-img :src="pet.avatar" />

                <ion-img class="remove-pet" :src="require('@/assets/images/icons/circle-close.svg')" />
              </div>
            </div>
            <p @click="page = 'pet-selector'">Szervező meghívása</p>
          </div>

          <p class="poi__images__help">A Szervezők szerkeszthetik az eseményedet. Maximum négyet hívhatsz meg.</p>
        </div>

        <div class="poi__about">
          <h1 class="poi__subtitle">Az eseményed neve:</h1>
          <IonInput
            type="text"
            class="form-control"
            v-model="poiName"
            @ion-input="validations.title.$touch"
            required
            placeholder="pl. Esti séta"
            :class="{ 'is-invalid': validations.title.$error }"
          />
          <ErrorMessage :field="validations.title" />
        </div>

        <div class="poi__about">
          <h1 class="poi__subtitle">Esemény leírása:</h1>
          <IonTextarea
            rows="5"
            required
            class="form-control"
            v-model="poiDescription"
            placeholder="Mesélj néhány szóban az eseményedről."
          />
        </div>

        <div class="poi__images">
          <h1 class="poi__subtitle">Képek:</h1>
          <div class="poi__images__list">
            <div
              class="poi__images__list__item"
              v-for="(image, key) in photos"
              :key="key"
              :style="`background-image: url(${image.src});`"
            >
              <div class="poi__images__list__item__remove" @click="handleRemovePhoto(image)">
                <ion-img :src="require('@/assets/images/icons/poi/bin.svg')" />
              </div>
            </div>

            <div class="poi__images__list__item" @click="handlePhotoTake" v-if="photos.length < 1">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>
            <div class="poi__images__list__item" @click="handlePhotoTake" v-if="photos.length < 2">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>

            <div class="poi__images__list__item" @click="handlePhotoTake">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>
          </div>
          <p class="poi__images__help">
            Hozzáadhatsz az eseményedhez kapcsolódó, vagy akár a helyszínről készült képeket is.
          </p>
        </div>

        <div class="poi__about" style="display: flex; justify-content: center">
          <IonButton class="btn-strong" shape="round" strong="true" @click="handleEventCreation"
            >Esemény módosítása</IonButton
          >
        </div>
      </div>

      <PetSelector
        v-show="page === 'pet-selector'"
        :key="selectedPets"
        :petTitle="'Csak a követőidet tudod meghívni:'"
        :inputLabel="'Barátok keresése'"
        :buttonLabel="'Szervezők meghívása'"
        :maxPets="4"
        :selectedPetList="selectedPets ?? []"
        @on-submit="
          pets => {
            page = 'edit-event'
            selectedPets = pets
          }
        "
      />
    </IonContent>
  </IonPage>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { IonPage, IonContent, IonTextarea, IonButton, IonInput, alertController } from '@ionic/vue'

import usePets from '@/composables/pets'
import useToast from '@/composables/toast'
import useEvents from '@/composables/events'
import usePhotoGallery from '@/composables/camera'
import useNavigation from '@/composables/navigation'
import { useRoute, useRouter } from 'vue-router'

import { PET_WALKING_ROUTE } from '@/constants/routes'

import ErrorMessage from '@/components/Global/ErrorMessage'
import PetSelector from '@/components/Posts/Upload/PetSelector'
import { required, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
  name: 'EditEvent',
  components: {
    IonPage,
    IonContent,
    IonTextarea,
    IonButton,
    IonInput,
    PetSelector,
    ErrorMessage,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { activePet } = usePets()
    const { openToast } = useToast()
    const { navigateTo } = useNavigation()
    const { takePhoto } = usePhotoGallery()
    const { getEvent, editEvent } = useEvents()
    const page = ref('edit-event')
    const selectedPets = ref([])
    const selectedEvent = ref({})
    const eventId = route.params.id
    const date = ref('')
    const dateSelectorOpen = ref(false)
    const removeImageIds = ref([])

    const selectedHour = ref('14')
    const selectedMinute = ref('30')
    const selectHours = computed(() => Array.from({ length: 24 }, (_, i) => (i < 10 ? `0${i}` : i)))
    const selectMinutes = computed(() => Array.from({ length: 60 }, (_, i) => (i < 10 ? `0${i}` : i)))

    const selectedYear = ref(new Date().getFullYear())
    const selectedMonth = ref('01')
    const selectedDay = ref('01')
    const selectableYears = computed(() => {
      const currYear = new Date().getFullYear() + 5
      const lastYear = new Date().getFullYear()
      return Array.from({ length: currYear - lastYear + 1 }, (_, index) => lastYear + index).reverse()
    })
    const selectableMonths = computed(() =>
      Array.from({ length: 12 }, (_, i) => {
        i = i + 1
        return i < 10 ? `0${i}` : i
      }),
    )
    const selectableDays = computed(() => {
      const lastDay = new Date(selectedYear.value, selectedMonth.value, 0).getDate()
      return Array.from({ length: lastDay }, (_, index) => index + 1)
    })

    Number.prototype.pad = function (n) {
      return ('0' + this).slice(n || 2 * -1)
    }

    onMounted(async () => {
      const event = await getEvent(eventId)

      const isEditor = event?.editors.find(pet => activePet.value?.id === pet.pet_id) !== undefined

      if (!isEditor) {
        return await navigateTo({ name: PET_WALKING_ROUTE })
      }

      const parsedDate = new Date(event.date)

      selectedEvent.value = event
      poiName.value = event.name
      poiDescription.value = event.description
      selectedHour.value = parsedDate.getHours().pad()
      selectedMinute.value = parsedDate.getMinutes().pad()
      selectedYear.value = parsedDate.getFullYear()
      selectedMonth.value = (parsedDate.getMonth() + 1).pad()
      selectedDay.value = parsedDate.getDate().pad()
      date.value = `${parsedDate.getFullYear()}.${(parsedDate.getMonth() + 1).pad()}.${parsedDate.getDate().pad()}`

      photos.value = event.images.map(image => ({
        id: image.id,
        src: image.url,
        value: image.url,
      }))

      selectedPets.value = event.editors
        .map(editor => ({
          ...editor.pet,
          avatar: event.pets.find(pet => pet.id === editor.pet.id)?.avatar,
        }))
        .filter(pet => pet.avatar && pet.id !== activePet.value.id)
    })

    const poiDescription = ref(selectedEvent.value.description)
    const poiName = ref(selectedEvent.value.name)

    const rules = {
      title: { required, maxLength: maxLength(22) },
    }

    const validations = useVuelidate(rules, {
      title: poiName,
    })

    const photos = ref([])

    const handlePhotoTake = async () => {
      try {
        const photo = await takePhoto()

        photos.value.push({
          id: 't-' + new Date().toString(),
          src: photo.webviewPath,
          value: photo.url,
        })
      } catch (error) {
        console.error(error)
      }
    }

    const handleRemovePhoto = photo => {
      if (typeof photo.id === 'number') {
        removeImageIds.value.push(photo.id)
      }
      photos.value = photos.value.filter(item => item.id !== photo.id)
    }

    const handleEventCreation = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (!poiName.value) {
        await openToast({
          message: 'A kötelező mezők nincsenek kitöltve. Biztos mindent kitöltöttél?',
          color: 'warning',
        })

        return
      }

      try {
        await editEvent(eventId, {
          name: poiName.value,
          date: `${date.value.replaceAll('.', '-')} ${selectedHour.value}:${selectedMinute.value}:00`,
          description: poiDescription.value,
          new_images: photos.value.filter(image => typeof image.id === 'string' && image.id.startsWith('t-')),
          deleted_images: removeImageIds.value,
          editors: selectedPets.value.map(pet => pet.id),
        })

        const dialog = await alertController.create({
          cssClass: 'confirm-dialog poi-dialog',
          message: `
            <div class="poi-info">
              <img src="${require('@/assets/images/icons/walk/event-active.svg')}" class="icon" />
              <h1>${poiName.value}</h1>
            </div>
            <p>Sikeresen módosítottad az eseményt!</p>
          `,
          buttons: [
            {
              text: 'Bezárás',
              role: 'cancel',
              cssClass: 'btn btn-link btn-block btn-popup-close',
            },
          ],
        })

        await router.go(-1)
        await dialog.present()
      } catch (error) {
        console.error(error)

        await openToast({
          message: 'Sajnos nem sikerült az eseményt módosítani :(',
          color: 'danger',
        })
      }
    }

    const removePet = id => {
      selectedPets.value = selectedPets.value.filter(pet => pet.id !== id)
    }

    return {
      page,
      date,
      photos,
      poiName,
      activePet,
      selectedEvent,
      selectedPets,
      poiDescription,
      handlePhotoTake,
      handleEventCreation,
      dateSelectorOpen,
      selectedHour,
      selectHours,
      selectedMinute,
      selectMinutes,
      selectedYear,
      selectedMonth,
      selectedDay,
      selectableYears,
      selectableMonths,
      selectableDays,
      removePet,
      handleRemovePhoto,
      validations,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.content {
  margin-top: -50px;
}

.picker-group {
  display: flex;
  height: 75px;
  max-width: 103px;
  margin: 0 auto;

  &.date {
    margin-top: 15px;
    max-width: 278px;
    position: relative;

    .checkmark {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
    }
  }
}

h1 {
  font-weight: 700;
  font-size: 12px !important;
  text-align: left;
  margin: 0 34px 5px 34px;
}

.poi {
  &__icon {
    width: fit-content;
    margin: 160px auto 0 auto;
    position: relative;

    .marker,
    .marker::part(image) {
      min-height: 65px;
      max-height: 65px;
      min-width: 50px;
      max-width: 50px;
    }

    .pet {
      position: absolute;
      right: -10px;
      bottom: -16px;
      border: 4px solid #fff;
      border-radius: 99999px;
      min-width: 38px;
      max-width: 38px;
    }

    .pet::part(image) {
      min-height: 30px;
      max-height: 30px;
      border-radius: 99999px;
    }
  }

  &__name {
    margin-top: 26px;
    margin-bottom: 2px;
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.3px;
    font-size: 15px !important;
    font-family: 'Poppins', sans-serif;
  }

  &__address {
    margin-top: 0;
  }

  &__header {
    text-align: center;
    word-wrap: break-word;
    padding: 0 34px;
    margin-bottom: 40px;

    .date-selector {
      .display {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .edit {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -33px;
          width: 25px;
          height: 25px;
          background: white;
          border-radius: 99999px;
          box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
        }

        svg {
          width: 11.5px;
        }
      }
    }
  }

  &__subtitle {
    padding: 0 16px;
  }

  &__images {
    margin-bottom: 27px;

    &__list {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 5px 34px 0 34px;
      margin-bottom: -27px;
      padding-bottom: 27px;

      &__item {
        background: #fff;
        min-width: 140px;
        aspect-ratio: 1 / 1;
        position: relative;

        background-position: center center;
        background-size: cover;
        border-radius: 25px;

        margin-right: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);

        &:last-of-type {
          margin-right: 0;
        }

        &__remove {
          position: absolute;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
          right: 10px;
          bottom: -7px;
          cursor: pointer;

          ion-img {
            width: 10.5px;
          }
        }
      }
    }

    &__help {
      padding: 0 34px;
      text-align: center;
      color: #afafaf;
    }
  }

  &__about {
    text-align: left;
    padding: 0 34px;
    margin-bottom: 27px;

    h1 {
      font-weight: 700;
      font-size: 12px !important;
      text-align: left;
      margin: 0 0 5px 0;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }

  &__pet__list {
    margin-bottom: 27px;
    padding: 0 34px;

    h1 {
      margin: 0 0 5px 0;
      padding: 0 16px;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 700;
        color: #e6224a;
        font-size: 10px;
        letter-spacing: -0.3px;
      }
    }

    &__list {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-left: 10px;

      &__item {
        min-width: 52px;
        min-height: 52px;
        max-width: 52px;
        max-height: 52px;
        border-radius: 9999px;
        margin-left: -10px;

        border: 5px solid #fff;
        filter: drop-shadow(3px 1px 5px rgba(0, 0, 0, 0.2));

        &:nth-of-type(1) {
          order: 9999;
          z-index: 2;
        }

        ion-img::part(image) {
          border-radius: 9999px;
        }

        .remove-pet {
          width: 16px !important;
          height: 16px !important;
          position: absolute;
          right: 0px;
          bottom: -5px;
        }
      }
    }
  }
}

ion-input,
ion-textarea {
  border: none;
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  font-weight: 700;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  font-size: 14px;
  --placeholder-font-weight: 400;
}

ion-textarea {
  height: 124px;
}

ion-button {
  height: 50px;
  --background: #e6224a;
  max-width: 238px;
  --box-shadow: none;
}
</style>
