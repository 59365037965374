<script setup>
import { useRoute } from 'vue-router'
import usePois from '@/composables/pois'
import useEvents from '@/composables/events'
import useNavigation from '@/composables/navigation'

import { PET_WALKING_ROUTE, POI_REDIRECT_ROUTE } from '@/constants/routes'
import { alertController } from '@ionic/vue'

const icon = require(`@/assets/images/icons/sad-dog.png`)

const route = useRoute()
const { getPoiBySlug } = usePois()
const { navigateTo } = useNavigation()
const { getEventBySlug } = useEvents()

const slug = route.params.slug

let redirectType = 'event'
let redirectData = null

if (route.name === POI_REDIRECT_ROUTE) {
  redirectType = 'poi'
  redirectData = getPoiBySlug(slug)
} else {
  redirectData = getEventBySlug(slug)
}

redirectData
  .then(result => {
    if (redirectType === 'event') {
      navigateTo({
        name: PET_WALKING_ROUTE,
        query: {
          openEvent: result.id,
        },
      })
    } else {
      navigateTo({
        name: PET_WALKING_ROUTE,
        query: {
          openPoi: result.id,
        },
      })
    }
  })
  .catch(() => {
    if (redirectType === 'event') {
      alertController
        .create({
          cssClass: 'confirm-dialog poi-dialog',
          message: `
        <div class="poi-info">
          <img src="${icon}" class="icon" />
          <p>Ez az esemény még elfogadásra vár.</p>
        </div>
      `,
          buttons: [
            {
              text: 'Bezárás',
              role: 'cancel',
              cssClass: 'btn btn-link btn-block btn-popup-close',
            },
          ],
        })
        .then(dialog => dialog.present())
    }

    navigateTo({ name: PET_WALKING_ROUTE })
  })
</script>
