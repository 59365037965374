<template>
  <ion-avatar class="pet-avatar">
    <ion-img :src="image"></ion-img>
    <ion-text v-if="isMissing" class="pet-avatar__ribbon">Elveszett</ion-text>
  </ion-avatar>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg, IonAvatar, IonText } from '@ionic/vue'

export default defineComponent({
  name: 'PetAvatar',
  props: {
    image: {
      type: String,
      required: true,
    },
    isMissing: {
      type: Boolean,
    },
  },
  components: {
    IonAvatar,
    IonImg,
    IonText,
  },
})
</script>

<style lang="scss" scoped>
.pet-avatar {
  min-width: 225px;
  min-height: 225px;
  border: 8px solid #fff;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;

  &__ribbon {
    background: #f64242;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    height: 34px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.04167em;
  }
}
</style>
