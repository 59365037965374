<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <div class="dox-data-wrapper">
            <div class="avatar-wrapper">
              <ion-thumbnail slot="start" class="dox-tag-img">
                <img :src="petData.tag" />
              </ion-thumbnail>
              <ion-avatar class="pet-avatar">
                <ion-img class="upload" :class="'upload-icon'" :src="petData.avatar" alt="Kép " />
              </ion-avatar>
            </div>
          </div>

          <h1 class="page-title">{{ petData.name }} elveszett?</h1>
          <ion-text class="description">
            Ha eltűnt a Kedvenced <span class="danger">vészhelyzet módba</span> állítjuk a<br />
            hozzá tartozó <span class="dox">DOX</span>-od, hogy a megtalálója azonnal<br />
            felhívhasson és értesítjük a környékbeli Gazdikat is,<br />
            hogy segíthessenek a megtalálásában.
          </ion-text>

          <form @submit.prevent="submit" class="form" novalidate>
            <div class="form-group">
              <ion-label for="date" class="control-label">{{ petData.name }} eltűnésének napja:</ion-label>
              <ion-input
                type="date"
                id="date"
                v-model="lostDate"
                @ion-input="validations.date.$touch"
                name="date"
                max="2999-12-31"
                inputmode="text"
                required="true"
                enterkeyhint="next"
                :class="{ 'is-invalid': validations.date.$error || hasServerValidationError('date') }"
                class="form-control"
              />
              <ErrorMessage :field="validations.date" />
              <ServerValidation field="date" :server-validation="serverValidation" />
            </div>
            <div class="form-group">
              <ion-label for="location" class="control-label">Eltűnés helyszíne:</ion-label>
              <ion-input
                type="text"
                id="location"
                v-model="validations.location.$model"
                name="location"
                inputmode="text"
                required="true"
                enterkeyhint="next"
                :class="{ 'is-invalid': validations.location.$error || hasServerValidationError('location') }"
                class="form-control"
                autocapitalize="sentences"
              />
              <ErrorMessage :field="validations.location" />
              <ServerValidation field="location" :server-validation="serverValidation" />
            </div>
            <div class="form-group">
              <ion-label for="description" class="control-label">Eltűnés körülményei:</ion-label>
              <ion-textarea
                rows="5"
                id="decription"
                v-model="validations.description.$model"
                name="description"
                required="true"
                enterkeyhint="go"
                :class="{ 'is-invalid': validations.description.$error || hasServerValidationError('description') }"
                class="form-control"
                autocapitalize="sentences"
              />
              <ErrorMessage :field="validations.description" />
              <ServerValidation field="description" :server-validation="serverValidation" />
            </div>
            <ion-button
              type="submit"
              id="submit"
              expand="full"
              :disabled="isLoading"
              shape="round"
              strong
              class="btn submit"
            >
              eltűnés beküldése
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
            <ion-button
              @click.prevent="$router.go(-1)"
              type="button"
              id="cancel"
              expand="full"
              shape="round"
              class="btn btn-link cancel"
            >
              Mégsem
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import moment from 'moment'
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonPage, IonContent, IonText, IonInput, IonTextarea, IonLabel, IonButton, IonRippleEffect } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required, maxValue } from '@vuelidate/validators'
import { useStore } from 'vuex'

import { PET_ROUTE } from '@/constants/routes'
import PET_STATUS from '@/constants/pet-status'
import usePets from '@/composables/pets'
import useDoxData from '@/composables/doxData'
import useNavigation from '@/composables/navigation'
import useFormControl from '@/composables/formControl'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

import { SET_ACTIVE_PET_STATUS } from '@/constants/types'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PetLost',
  components: {
    IonPage,
    IonContent,
    IonText,
    IonInput,
    IonTextarea,
    IonLabel,
    IonButton,
    IonRippleEffect,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { getDoxData } = useDoxData()
    const { navigateTo } = useNavigation()
    const { updatePet } = usePets()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const lostDate = ref(moment().format('YYYY-MM-DD'))
    const location = ref('')
    const description = ref('')
    const isLoading = ref(false)
    const hasFoundPetState = ref(0) // 0: loading; 1: true; 2: false
    const petData = ref({})

    const { id: petId } = route.params

    const doxTagSources = [
      { black: require('@/assets/images/doxTags/black.png') },
      { deepblue: require('@/assets/images/doxTags/deepblue.png') },
      { green: require('@/assets/images/doxTags/green.png') },
      { ocean: require('@/assets/images/doxTags/ocean.png') },
      { pastelred: require('@/assets/images/doxTags/pastelred.png') },
      { purple: require('@/assets/images/doxTags/purple.png') },
      { rookie: require('@/assets/images/doxTags/Rookie.png') },
      { yellow: require('@/assets/images/doxTags/yellow.png') },
    ]

    const date = computed(() => (lostDate.value.length ? new Date(lostDate.value) : null))

    const rules = {
      date: { date: maxValue(new Date()) },
      location: { required },
      description: { required },
    }

    const validations = useVuelidate(rules, {
      date,
      location,
      description,
    })

    onMounted(async () => {
      const { data } = await getDoxData()

      const doxDataPets = Object.values(data)[0]

      const doxData = doxDataPets.find(pet => pet.id === parseInt(petId))

      if (!doxData || (doxData && !doxData.rfids.length)) return (hasFoundPetState.value = 2)

      if (doxData) {
        petData.value.avatar = doxData.avatar.cdn_url
        petData.value.name = doxData.name
        setPetDataByRfid(doxData.rfids[0])

        hasFoundPetState.value = 1
      }
    })

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        const payload = {
          status: PET_STATUS.MISSING.id,
          missing: {
            date: lostDate.value,
            location: location.value,
            description: description.value,
          },
        }

        await updatePet(
          {
            id: parseInt(petId),
          },
          payload,
        )
        store.commit(SET_ACTIVE_PET_STATUS)

        navigateTo({ name: PET_ROUTE, params: { id: parseInt(petId) } })
        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    const setPetDataByRfid = rfid => {
      let tag_name = rfid.id.toLowerCase().split('-', 1)
      petData.value.tag_identifier = rfid.id.toLowerCase().split('-', 2)[1]

      petData.value.tag = ''
      for (let i of doxTagSources) {
        if (tag_name.toString() == Object.keys(i).toString()) {
          petData.value.tag = Object.values(i)
          petData.value.tag_name = capital(tag_name.toString())
          break
        } else {
          petData.value.tag = Object.values(doxTagSources[0])
          petData.value.tag_name = capital(tag_name.toString())
        }
      }
    }

    const capital = string => string.replace(/^./, string[0].toUpperCase())

    return {
      submit,
      lostDate,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
      petData,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.content {
  padding-top: 15px;
  text-align: center;
}

ion-text {
  display: block;
  font-size: 12px;
}

.page-title {
  font-family: var(--ion-montserrat-font);
  font-weight: 900;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.description {
  margin-bottom: 25px;
  font-size: 12px;
  color: #222;

  .danger {
    color: #e6224a;
    font-weight: 700;
  }

  .dox {
    font-weight: 700;
  }
}

.form {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 50px;
}

.submit {
  margin-top: 46px;
}

.dox-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-wrapper {
  display: flex;

  .dox-tag-img {
    width: 100px;
    height: 150px;
    position: relative;
    left: 15%;
    transform: rotate(5deg);
  }

  .pet-avatar {
    width: 59px;
    height: 59px;
    z-index: 10;
    position: relative;
    left: -22px;
    top: 109px;
    border: 4px solid #fff;
    box-shadow: 5px 2px 22px rgb(212 212 212 / 51%);
    background-color: #fff;
  }
}

ion-input {
  margin-bottom: 25px;
}

ion-input,
ion-textarea {
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 31px;
  border: none;
  min-height: 50px;
  --padding-start: 22px;
  --padding-end: 22px;
}

ion-label {
  padding-left: 22px;
}

ion-button {
  max-width: 242px;
  margin: 0 auto;
  --box-shadow: none;
  font-family: var(--ion-poppins-font);
  font-weight: 900;
  font-size: 16px;
  letter-spacing: -0.64px;

  &.submit {
    --background: #e6224a;
    --color: #fff;
    margin-bottom: 16px;
  }

  &.cancel {
    --background: #e7e7e7;
    --color: #333;
    text-decoration: none;
  }
}
</style>
