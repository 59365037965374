<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <div class="pet-info" v-if="pet">
            <div class="pet-info-header ion-text-center">
              <img src="@/assets/images/icons/info.svg" alt="heart-icon" />
              <h1>{{ pet.name }}</h1>
            </div>
            <div class="pet-info-list">
              <h2 class="pet-info-title small">
                <img src="@/assets/images/icons/dog.svg" alt="dog-icon" />
                Főbb adatok
              </h2>
              <table class="table">
                <tbody class="table-body">
                  <tr class="table-item">
                    <td><strong>Fajta</strong></td>
                    <td>
                      {{ pet.breed.name }}
                      <span class="pet-parent" v-if="pet.parent_breeds && pet.parent_breeds.length">
                        : {{ parentBreeds }}
                      </span>
                    </td>
                  </tr>
                  <tr class="table-item">
                    <td><strong>Nem</strong></td>
                    <td>{{ pet.sex }}</td>
                  </tr>
                  <tr class="table-item">
                    <td><strong>Születésnap</strong></td>
                    <td>{{ pet.date_of_birth }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pet-info-list dox-function" :class="{ 'no-dox': !isDoxRead }">
              <h2 class="pet-info-title small">
                <img src="@/assets/images/icons/health.svg" alt="dog-icon" />
                Egészségügyi adatok
              </h2>
              <table class="table">
                <tbody class="table-body">
                  <tr class="table-item" v-if="pet.health && pet.health.status">
                    <td><strong>Egészségügyi állapota</strong></td>
                    <td>{{ healthStatus }}</td>
                  </tr>
                  <tr class="table-item" v-if="pet.health && pet.health.blood_type">
                    <td><strong>Vércsoport</strong></td>
                    <td>{{ pet.health.blood_type.name }}</td>
                  </tr>
                  <tr class="table-item" v-if="pet.health && pet.health.neutered">
                    <td><strong>Ivartalanított</strong></td>
                    <td>{{ pet.health.neutered ? 'Igen' : 'Nem' }}</td>
                  </tr>
                  <tr class="table-item" v-if="pet.last_vaccination_date">
                    <td><strong>Utolsó oltás</strong></td>
                    <td>{{ pet.last_vaccination_date }}</td>
                  </tr>
                  <tr class="table-item" v-if="pet.chip">
                    <td><strong>Chip kód</strong></td>
                    <td>{{ pet.chip }}</td>
                  </tr>
                </tbody>
              </table>
              <NoDox action-disabled v-if="!isDoxRead" />
            </div>
            <div class="pet-info-list dox-function" :class="{ 'no-dox': !isDoxRead }">
              <h2 class="pet-info-title small">
                <img src="@/assets/images/icons/documents.svg" alt="dog-documents-icon" />
                Okmányok
              </h2>
              <table class="table">
                <tbody class="table-body">
                  <tr class="table-item">
                    <td><strong>Törzskönyv</strong></td>
                    <td>{{ pet.documents.pedigree ? 'Van' : 'Nincs' }}</td>
                    <td class="ion-text-right">
                      <ion-button
                        v-if="pet.documents && pet.documents.pedigree"
                        @click.prevent="openBrowserLink(pet.documents.pedigree)"
                        class="btn"
                        size="small"
                        color="dark"
                        shape="round"
                      >
                        Mutasd
                      </ion-button>
                    </td>
                  </tr>
                  <tr class="table-item">
                    <td><strong>Egészségügyi könyv</strong></td>
                    <td>{{ pet.documents.vaccination_book ? 'Van' : 'Nincs' }}</td>
                    <td class="ion-text-right">
                      <ion-button
                        v-if="pet.documents && pet.documents.vaccination_book"
                        @click.prevent="openBrowserLink(pet.documents.vaccination_book)"
                        class="btn"
                        size="small"
                        color="dark"
                        shape="round"
                      >
                        Mutasd
                      </ion-button>
                    </td>
                  </tr>
                  <tr class="table-item">
                    <td><strong>Útlevél</strong></td>
                    <td>{{ pet.documents.passport ? 'Van' : 'Nincs' }}</td>
                    <td class="ion-text-right">
                      <ion-button
                        v-if="pet.documents && pet.documents.passport"
                        @click.prevent="openBrowserLink(pet.documents.passport)"
                        class="btn"
                        size="small"
                        color="dark"
                        shape="round"
                      >
                        Mutasd
                      </ion-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <NoDox action-disabled v-if="!isDoxRead" />
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import lodash from 'lodash'
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { IonPage, IonContent, IonButton } from '@ionic/vue'

import useNavigation from '@/composables/navigation'
import usePets from '@/composables/pets'
import useRegistration from '@/composables/registration'

import NoDox from '@/components/Pet/NoDox'

export default defineComponent({
  name: 'PetInfo',
  components: {
    IonPage,
    IonContent,
    IonButton,
    NoDox,
  },
  setup() {
    const route = useRoute()
    const { openBrowserLink } = useNavigation()
    const { pet, fetchPet } = usePets()
    const { isDoxRead } = useRegistration()

    const petId = computed(() => route.params.id)
    const healthStatus = computed(() => pet.value.health.status.map(status => status.name).join(', '))
    const parentBreeds = computed(() => pet.value.parent_breeds.map(item => item.name).join(' - '))

    const ionViewWillEnter = () => {
      if (!lodash.isEmpty(pet.value)) {
        return Promise.resolve(pet)
      }

      fetchPet(petId.value)
    }

    return {
      pet,
      ionViewWillEnter,
      openBrowserLink,
      healthStatus,
      isDoxRead,
      parentBreeds,
    }
  },
})
</script>

<style lang="scss" scoped>
.content {
  padding: 0 20px;
}

h1 {
  margin-top: 8px;
  margin-bottom: 35px;
}

.pet-info-list {
  margin-bottom: 20px;
  position: relative;

  &.dox-function {
    min-height: 130px;
  }

  &.no-dox {
    .table {
      filter: blur(4px);
    }
  }

  .btn {
    height: 22px;
  }
}
.app-header {
  display: block;
}

.pet-info-title {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  img {
    margin-right: 8px;
  }
}
</style>
