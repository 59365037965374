<template>
  <ion-page>
    <PetProfile v-if="pet" />
    <ion-content v-else>
      <div class="content">
        <Unavailable v-if="unavailable" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, computed, watch } from 'vue'
import { IonContent, IonPage } from '@ionic/vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import useRegistration from '@/composables/registration'

import { PET_ROUTE, PET_INFO_ROUTE } from '@/constants/routes'

import usePets from '@/composables/pets'

import PetProfile from '@/components/Pet/PetProfile'
import Unavailable from '@/components/Global/Unavailable'

export default defineComponent({
  name: 'Pet',
  components: {
    IonContent,
    IonPage,
    PetProfile,
    Unavailable,
  },
  setup() {
    const route = useRoute()
    const { clearCurrentDox } = useRegistration()

    const petId = computed(() => route.params.id)
    const isPetRoute = computed(() => route.name === PET_ROUTE)

    const { fetchPetPosts, resetPetPosts, fetchPet, pet, unavailable } = usePets()

    onBeforeRouteLeave(to => {
      if (to.name !== PET_INFO_ROUTE) {
        clearCurrentDox()
      }
    })

    watch(petId, () => {
      if (petId.value && isPetRoute.value) {
        fetchPet(petId.value)
      }
    })

    const ionViewWillEnter = () => {
      if (petId.value) {
        fetchPet(petId.value)
        resetPetPosts()
        fetchPetPosts(petId.value)
      }
    }

    return {
      ionViewWillEnter,
      pet,
      unavailable,
    }
  },
})
</script>
