<template>
  <Post v-if="review.post" :review="true" :postData="review.post" />
  <ion-card class="review" v-else>
    <ion-item class="review-header" lines="none">
      <ion-img
        class="avatar link"
        :class="!review.post && 'small'"
        :src="review.petAvatar"
        :alt="review.petName"
        @click.prevent="petRoute"
      />
      <div class="header-content">
        <ion-label @click.prevent="petRoute" class="review-header__pet-name link">
          <h2>{{ review.petName }}</h2>
          <img v-if="review.petHasDox" class="review-dox-badge" src="@/assets/images/dox-badge.png" alt="dox-badge" />
          <div class="review-paws">
            <ion-img
              v-for="n in 5"
              :key="n + '-review'"
              :src="
                review.rating >= n
                  ? require('@/assets/images/icons/walk/paw_green.svg')
                  : require('@/assets/images/icons/walk/paw_grey.svg')
              "
              alt="értékelés"
            />
          </div>
        </ion-label>
        <h2 style="font-size: 10px; margin: 0; line-height: 16px">{{ poi.name }}</h2>
      </div>
      <ion-label class="review-date" slot="end">{{ moment(review.updated_at).from(moment()) }}</ion-label>
    </ion-item>
    <ion-card-content class="review-content" v-if="review.text">
      <b>{{ review.petName }}</b> {{ review.text }}
    </ion-card-content>
  </ion-card>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { IonCard, IonItem, IonCardContent, IonImg, IonLabel } from '@ionic/vue'

import Post from '@/components/Posts/Post'

import moment from 'moment'
import 'moment/locale/hu'

import useNavigation from '@/composables/navigation'
import { PET_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'PoiReview',
  components: {
    IonCard,
    IonItem,
    IonCardContent,
    IonImg,
    IonLabel,
    Post,
  },
  props: {
    poiData: {
      type: Object,
      required: true,
    },
    reviewData: {
      type: Object,
      required: true,
    },
  },
  emits: ['scroll-to-hash'],
  setup(props, { emit }) {
    moment.locale('hu')

    const { navigateTo } = useNavigation()

    const poi = ref({ ...props.poiData })
    const review = ref({ ...props.reviewData })

    const petRoute = function () {
      navigateTo({
        name: PET_ROUTE,
        params: {
          id: review.value.petId,
        },
      })
    }

    onMounted(() => emit('scroll-to-hash'))

    return {
      poi,
      review,
      moment,
      petRoute,
    }
  },
})
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

.review {
  overflow-x: visible;
  margin: 0;
  box-shadow: none;
  width: 100%;

  --background: #fff;

  .review-header {
    --inner-padding-end: 0;
    --padding-start: 0;
    --background: transparent;
    --border-color: transparent;

    &__pet-name {
      display: flex;
      align-items: center;
      font-family: 'Poppins', sans-serif;
      font-size: 9px;
      font-weight: 600;
      line-height: 16px;
      pointer-events: all;

      h2 {
        margin: 0;
        font-weight: 800;
        text-align: left;
        font-size: 11px !important;
        font-family: 'Poppins', sans-serif;
        line-height: 12px;
        margin-right: 5px;
      }
    }
  }

  .review-content {
    padding: 0;
    text-align: left;
    color: var(--ion-text-color);
    font-size: 11px !important;

    b {
      font-family: 'Poppins', sans-serif;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}

.avatar {
  position: relative;
  white-space: nowrap;
  border: 4px solid #fff;
  background: #fff;
  width: 56px;
  height: 56px;

  margin: 0 12px;

  box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.2);

  &.small {
    aspect-ratio: 1 / 1;
    margin-left: 0;
    margin-right: 10px;
    border-radius: 9999px;
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;

    border: 3px solid #fff;

    &::part(image) {
      border-radius: 9999px;
    }
  }
}

.review-paws {
  flex-grow: 1;
  display: flex;

  ion-img {
    height: 14px;
    margin-right: 3px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.review-date {
  text-align: right;
  margin-bottom: 15px;
  margin-right: 0;

  @media (max-width: 767px) {
    font-size: 9px;
  }

  @media (min-width: 768px) {
    font-size: 12px;
  }
}

.review-dox-badge {
  width: 15px;
  margin-right: 5px;
}
</style>
