<template>
  <IonPage>
    <IonContent>
      <div class="content" v-if="selectedPoi?.type && page === 'create-event'">
        <div class="poi__icon">
          <ion-img :src="require('@/assets/images/icons/walk/event.svg')" class="marker" />
        </div>
        <div class="poi__header">
          <div class="poi__header__content">
            <h1 class="poi__name">{{ poiName || 'A Te eseményed' }}</h1>
            <p class="poi__address">{{ selectedPoi.location }}</p>

            <div class="picker-group">
              <VueScrollPicker :options="selectHours" v-model="selectedHour" />
              <VueScrollPicker :options="selectMinutes" v-model="selectedMinute" />
            </div>

            <p>{{ date }}.</p>
          </div>
        </div>

        <div class="poi__pet__list">
          <h1>Szervezők:</h1>
          <div class="poi__pet__list__container">
            <div class="poi__pet__list__list">
              <div class="poi__pet__list__list__item">
                <ion-img :src="activePet.avatar" />
              </div>

              <div
                class="poi__pet__list__list__item"
                v-for="pet in selectedPets"
                :key="pet.id"
                @click="removePet(pet.id)"
              >
                <ion-img :src="pet.avatar" />

                <ion-img class="remove-pet" :src="require('@/assets/images/icons/circle-close.svg')" />
              </div>
            </div>
            <p @click="page = 'pet-selector'">Szervező meghívása</p>
          </div>

          <p class="poi__images__help">A Szervezők szerkeszthetik az eseményedet. Maximum négyet hívhatsz meg.</p>
        </div>

        <div class="poi__about">
          <h1 class="poi__subtitle">Az eseményed neve:</h1>
          <IonInput
            type="text"
            class="form-control"
            v-model="poiName"
            @ion-input="validations.title.$touch"
            required
            placeholder="pl. Esti séta"
            :class="{ 'is-invalid': validations.title.$error }"
          />
          <ErrorMessage :field="validations.title" />
        </div>

        <div class="poi__about">
          <h1 class="poi__subtitle">Esemény leírása:</h1>
          <IonTextarea
            rows="5"
            required
            class="form-control"
            v-model="poiDescription"
            placeholder="Mesélj néhány szóban az eseményedről."
          />
        </div>

        <div class="poi__images">
          <h1 class="poi__subtitle">Képek:</h1>
          <div class="poi__images__list">
            <div
              class="poi__images__list__item"
              v-for="(image, key) in photos"
              :key="key"
              :style="`background-image: url(${image.src});`"
            ></div>

            <div class="poi__images__list__item" @click="handlePhotoTake" v-if="photos.length < 1">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>
            <div class="poi__images__list__item" @click="handlePhotoTake" v-if="photos.length < 2">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>

            <div class="poi__images__list__item" @click="handlePhotoTake">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>
          </div>
          <p class="poi__images__help">
            Hozzáadhatsz az eseményedhez kapcsolódó, vagy akár a helyszínről készült képeket is.
          </p>
        </div>

        <div class="poi__about" style="display: flex; justify-content: center">
          <IonButton class="btn-strong" shape="round" strong="true" @click="handleEventCreation"
            >Esemény beküldése</IonButton
          >
        </div>
      </div>

      <PetSelector
        v-show="page === 'pet-selector'"
        :key="selectedPets"
        :petTitle="'Csak a követőidet tudod meghívni:'"
        :inputLabel="'Barátok keresése'"
        :buttonLabel="'Szervezők meghívása'"
        :maxPets="4"
        :selectedPetList="selectedPets ?? []"
        @on-submit="
          pets => {
            page = 'create-event'
            selectedPets = pets
          }
        "
      />
    </IonContent>
  </IonPage>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { IonPage, IonContent, IonTextarea, IonButton, IonInput, alertController } from '@ionic/vue'

import usePets from '@/composables/pets'
import useEvents from '@/composables/events'
import usePhotoGallery from '@/composables/camera'
import useToast from '@/composables/toast'
import { useRoute, useRouter } from 'vue-router'

import { MAPS_API } from '@/constants/types'
import { Loader } from '@googlemaps/js-api-loader'

import ErrorMessage from '@/components/Global/ErrorMessage'
import PetSelector from '@/components/Posts/Upload/PetSelector'
import { required, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
  name: 'CreateEvent',
  components: {
    IonPage,
    IonContent,
    IonTextarea,
    IonButton,
    IonInput,
    PetSelector,
    ErrorMessage,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { activePet } = usePets()
    const { createEvent } = useEvents()
    const { openToast } = useToast()
    const { takePhoto } = usePhotoGallery()
    const page = ref('create-event')
    const selectedPets = ref([])
    const selectedPoi = ref({})
    const lat = route.params.lat
    const lng = route.params.lng
    const date = ref(route.query.date)

    const selectedHour = ref('12')
    const selectedMinute = ref('30')
    const selectHours = computed(() => Array.from({ length: 24 }, (_, i) => (i < 10 ? `0${i}` : i)))
    const selectMinutes = computed(() => Array.from({ length: 60 }, (_, i) => (i < 10 ? `0${i}` : i)))

    //MAPS_API_IOS
    const loader = new Loader({
      apiKey: MAPS_API,
      version: 'weekly',
      libraries: [],
    })

    onMounted(async () => {
      await loader.load()
      const today = new Date()
      const geocoder = new window.google.maps.Geocoder()

      if (!date.value) {
        date.value = `${today.getFullYear()}.${
          today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
        }.${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`
      }

      try {
        const geocodeResponse = await geocoder.geocode({
          location: new window.google.maps.LatLng(lat, lng),
        })

        const zipCode = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('postal_code'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const cityName = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('locality'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const roadName = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('route'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const houseNumber = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('street_number'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        const poiName = geocodeResponse.results
          .map(result => {
            return result.address_components.find(component => component.types.includes('point_of_interest'))
          })
          .filter(element => element !== null)
          .shift()?.long_name

        selectedPoi.value = {
          type: 'event',
          name: null,
          location: `${zipCode} ${cityName ?? ''}, ${roadName ?? ''} ${houseNumber ?? poiName ?? ''}`,
          images: [],
          description: null,
          latitude: lat,
          longitude: lng,
        }
      } catch (error) {
        console.error(error)
      }
    })

    const poiDescription = ref(selectedPoi.value.description)
    const poiName = ref(selectedPoi.value.name)

    const rules = {
      title: { required, maxLength: maxLength(22) },
    }

    const validations = useVuelidate(rules, {
      title: poiName,
    })

    const photos = ref([])

    const handlePhotoTake = async () => {
      try {
        const photo = await takePhoto()

        photos.value.push({
          src: photo.webviewPath,
          value: photo.url,
        })

        console.log(photos.value)
      } catch (error) {
        console.error(error)
      }
    }

    const handleEventCreation = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      try {
        await createEvent({
          name: poiName.value,
          date: `${date.value.replaceAll('.', '-')} ${selectedHour.value}:${selectedMinute.value}:00`,
          latitude: selectedPoi.value.latitude,
          longitude: selectedPoi.value.longitude,
          location: selectedPoi.value.location,
          description: poiDescription.value,
          images: photos.value,
          editors: selectedPets.value.map(pet => pet.id),
        })

        const dialog = await alertController.create({
          cssClass: 'confirm-dialog poi-dialog',
          message: `
            <div class="poi-info">
              <img src="${require('@/assets/images/icons/walk/event.svg')}" class="icon" />
              <h1>${poiName.value}</h1>
            </div>
            <p>Sikeresen elküldted nekünk az eseményt. Most átvizsgájuk, hogy megfelel-e az irányelveinknek és értesítünk az állapotáról!</p>
          `,
          buttons: [
            {
              text: 'Bezárás',
              role: 'cancel',
              cssClass: 'btn btn-link btn-block btn-popup-close',
            },
          ],
        })

        await router.go(-1)
        await dialog.present()
      } catch (error) {
        console.error(error)

        await openToast({
          message: 'Sajnos nem sikerült az eseményt létrehozni :(',
          color: 'danger',
        })
      }
    }

    const removePet = id => {
      selectedPets.value = selectedPets.value.filter(pet => pet.id !== id)
    }

    return {
      page,
      date,
      photos,
      poiName,
      activePet,
      selectedPoi,
      selectedPets,
      poiDescription,
      handlePhotoTake,
      handleEventCreation,
      selectedHour,
      selectHours,
      selectedMinute,
      selectMinutes,
      removePet,
      validations,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.content {
  margin-top: -50px;
}

.picker-group {
  display: flex;
  height: 75px;
  max-width: 103px;
  margin: 0 auto;
}

h1 {
  font-weight: 700;
  font-size: 12px !important;
  text-align: left;
  margin: 0 34px 5px 34px;
}

.poi {
  &__icon {
    width: fit-content;
    margin: 160px auto 0 auto;
    position: relative;

    .marker,
    .marker::part(image) {
      min-height: 65px;
      max-height: 65px;
      min-width: 50px;
      max-width: 50px;
    }

    .pet {
      position: absolute;
      right: -10px;
      bottom: -16px;
      border: 4px solid #fff;
      border-radius: 99999px;
      min-width: 38px;
      max-width: 38px;
    }

    .pet::part(image) {
      min-height: 30px;
      max-height: 30px;
      border-radius: 99999px;
    }
  }

  &__name {
    margin-top: 26px;
    margin-bottom: 2px;
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.3px;
    font-size: 15px !important;
    font-family: 'Poppins', sans-serif;
  }

  &__address {
    margin-top: 0;
  }

  &__header {
    text-align: center;
    word-wrap: break-word;
    padding: 0 34px;
    margin-bottom: 40px;
  }

  &__subtitle {
    padding: 0 16px;
  }

  &__images {
    margin-bottom: 27px;

    &__list {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 5px 34px 0 34px;
      margin-bottom: -27px;
      padding-bottom: 27px;

      &__item {
        background: #fff;
        min-width: 140px;
        aspect-ratio: 1 / 1;

        background-position: center center;
        background-size: cover;
        border-radius: 25px;

        margin-right: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &__help {
      padding: 0 34px;
      text-align: center;
      color: #afafaf;
    }
  }

  &__about {
    text-align: left;
    padding: 0 34px;
    margin-bottom: 27px;

    h1 {
      font-weight: 700;
      font-size: 12px !important;
      text-align: left;
      margin: 0 0 5px 0;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }

  &__pet__list {
    margin-bottom: 27px;
    padding: 0 34px;

    h1 {
      margin: 0 0 5px 0;
      padding: 0 16px;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 700;
        color: #e6224a;
        font-size: 10px;
        letter-spacing: -0.3px;
      }
    }

    &__list {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-left: 10px;

      &__item {
        min-width: 52px;
        min-height: 52px;
        max-width: 52px;
        max-height: 52px;
        border-radius: 9999px;
        margin-left: -10px;

        border: 5px solid #fff;
        filter: drop-shadow(3px 1px 5px rgba(0, 0, 0, 0.2));

        &:nth-of-type(1) {
          order: 9999;
          z-index: 2;
        }

        ion-img::part(image) {
          border-radius: 9999px;
        }

        .remove-pet {
          width: 16px !important;
          height: 16px !important;
          position: absolute;
          right: 0px;
          bottom: -5px;
        }
      }
    }
  }
}

ion-input,
ion-textarea {
  border: none;
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  font-weight: 700;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  font-size: 14px;
  --placeholder-font-weight: 400;
}

ion-textarea {
  height: 124px;
}

ion-button {
  height: 50px;
  --background: #e6224a;
  max-width: 238px;
  --box-shadow: none;
}
</style>
