<template>
  <ion-page>
    <ion-content>
      <div class="container" :class="{ centered: !isServiceRunning || (dataLoaded && petsInView.length <= 0) }">
        <div class="content ion-text-center">
          <div class="settings">
            <ion-img
              class="pet-finder-settings-icon"
              :src="require('@/assets/images/icons/settings.svg')"
              alt="Beállítások"
              @click="navigateTo({ name: SNIFF_SETTINGS_ROUTE })"
            />
          </div>
          <ion-avatar class="active-pet">
            <ion-img :src="activePet.avatar" :alt="activePet.name" />
            <span class="pulse"></span>
            <span class="pulse"></span>
          </ion-avatar>
          <div class="sniff">
            <div class="start-content" v-if="!isServiceRunning">
              <ion-button
                expand="block"
                shape="round"
                strong="true"
                class="btn btn-pet-finder"
                @click.prevent="startService"
              >
                Szimat indítása
              </ion-button>
              <p>
                A SZIMAT funkciót akkor érdemes használnod, ha veled van a kedvenced. Segítségével megtalálhatod a
                hozzád legközelebb eső felhasználókat távolság alapján rendezve. A funkció használatához engedélyezned
                kell a hozzáférést a helymeghatározáshoz.
              </p>
            </div>
            <div v-else>
              <div v-if="petsInView.length > 0" class="pet-list">
                <div
                  class="pet-list-item"
                  @click="$router.push({ name: PET_ROUTE, params: { id: pet.id } })"
                  v-for="(pet, index) in petsInView"
                  :key="index"
                  :class="itemClasses[index]"
                >
                  <ion-avatar class="pet-avatar">
                    <ion-img :src="pet.avatar" :alt="pet.name"></ion-img>
                  </ion-avatar>
                  <ion-label class="pet-name">{{ pet.name }}</ion-label>
                </div>
              </div>
              <p v-if="dataLoaded && !petsInView.length" class="ion-text-center">
                <ion-img
                  :src="require('@/assets/images/icons/refresh-page-option.svg')"
                  alt="Frissítés"
                  class="refresh-icon"
                  :class="refreshClasses"
                  @click.prevent="refresh"
                />
                Jelenleg nem tudtunk kiszimatolni a környékeden kedvenceket. Kattints a frissítés ikonra, vagy próbáld
                meg később újra.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, watch, ref, onMounted } from 'vue'
import { IonPage, IonAvatar, IonImg, IonButton, IonContent, IonLabel } from '@ionic/vue'
import { SNIFF_SETTINGS_ROUTE } from '@/constants/routes'
import usePets from '@/composables/pets'
import usePetFinder from '@/composables/petFinder'

import { PET_ROUTE } from '@/constants/routes'

import useNavigation from '@/composables/navigation'
export default defineComponent({
  name: 'PetFinder',
  components: {
    IonPage,
    IonContent,
    IonAvatar,
    IonImg,
    IonButton,
    IonLabel,
  },

  setup() {
    const { navigateTo, replace } = useNavigation()
    const { activePet } = usePets()

    const {
      isServiceRunning,
      startService,
      resetService,
      setCallbackInterval,
      setCallbackParams,
      GENERAL_PET_FINDER_INTERVAL,
      GENERAL_PET_FINDER_PARAMS,
      pets,
      dataLoaded,
    } = usePetFinder()

    const petsInView = ref([])
    const itemClasses = ref([])
    const refreshClasses = ref([])

    onMounted(() => resetService())

    watch(pets, () => {
      pets.value.map((item, index) => {
        if (!petsInView.value[index]) {
          petsInView.value[index] = item
          setTimeout(() => {
            itemClasses.value[index] = 'updated'
          }, Math.random() * 1000) // 0-1000
        } else if (petsInView.value[index].id !== item.id) {
          setTimeout(() => {
            itemClasses.value[index] = 'updating'
            setTimeout(() => {
              petsInView.value[index] = item
              itemClasses.value[index] = 'updated'
            }, 500) // Animation duration when hiding
          }, Math.random() * 3000) // 0-3000
        }
      })

      // remove orphans
      if (pets.value.length < petsInView.value.length) {
        let i = petsInView.value.length - 1
        do {
          setTimeout(() => {
            itemClasses.value[i] = 'updating'
            setTimeout(() => {
              petsInView.value.splice(i, 1)
              itemClasses.value.splice(i, 1)
            }, 500) // animation duration when hiding
          }, Math.random() * 3000) // 0-3000
        } while (pets.value.length <= --i)
      }
    })

    const ionViewWillEnter = () => {
      setCallbackInterval()
      setCallbackParams()
      resetService()
    }

    const ionViewWillLeave = () => {
      setTimeout(() => {
        setCallbackInterval(GENERAL_PET_FINDER_INTERVAL)
        setCallbackParams(GENERAL_PET_FINDER_PARAMS)
        resetService()
        petsInView.value = []
        itemClasses.value = []
        refreshClasses.value = []
      }, 1000)
    }

    const refresh = () => {
      refreshClasses.value = 'refreshing'
      setTimeout(() => {
        refreshClasses.value = []
      }, 3000)
    }

    return {
      activePet,
      isServiceRunning,
      ionViewWillEnter,
      ionViewWillLeave,
      resetService,
      startService,
      pets,
      dataLoaded,
      petsInView,
      itemClasses,
      refresh,
      refreshClasses,
      SNIFF_SETTINGS_ROUTE,
      PET_ROUTE,
      navigateTo,
      replace,
    }
  },
})
</script>
<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.ios {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.container {
  transition: 0.4s ease-in;

  &.centered {
    display: flex;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
  margin: var(--content-margin-mobile);
}

.settings {
  position: absolute;
  top: 4px;
  left: 10px;

  .pet-finder-settings-icon {
    color: #ccc;
    @media (max-width: 767px) {
      width: 25px;
      min-width: 25px;
      height: 25px;
    }

    @media (min-width: 768px) {
      width: 31px;
      min-width: 31px;
      height: 31px;
    }
  }
}

.sniff {
  transform: translateY(10vh);
}

.refresh-icon {
  position: relative;
  width: 20px;
  margin: 0 auto 20px;
  z-index: 999;

  &.refreshing {
    animation: rotate 3s cubic-bezier(0.1, 1.18, 0.8, -0.31) forwards;
  }
}

.start-content {
  margin: 80px 10%;

  p {
    font-size: 9px;
  }
}

.btn-pet-finder {
  font-size: 16px;
  margin: 0 0 10px;
  --padding-start: 50px;
  --padding-end: 50px;
}

.active-pet {
  position: relative;
  width: 74px;
  height: 74px;
  margin: 2.5rem auto 55px;

  ion-img {
    position: relative;
    z-index: 1;
  }
}

.pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 260px;
    height: 260px;
    border: 2px solid #06ff00;
    border-radius: 50%;
    animation: pulse 5s infinite;
    content: '';
    z-index: -1;
    transform-origin: center;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  &:after {
    animation-delay: 1s;
  }

  &:nth-child(odd) {
    animation-delay: 1.5s;

    &:before {
      animation-delay: 2s;
    }

    &:after {
      animation-delay: 3s;
    }
  }
}

.steps {
  display: flex;
  justify-content: center;
  width: 100%;

  .step-img {
    &:first-child {
      opacity: 0;
      animation: walk 1s linear forwards;
    }

    &:nth-child(2) {
      opacity: 0;
      transform: translate3d(10px, -5px, 0);
      animation: walk 1s linear forwards;
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      opacity: 0;
      transform: translate3d(20px, -10px, 0);
      animation: walk 1s linear forwards;
      animation-delay: 1s;
    }
  }
}

.pet-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: -105px;

  .pet-list-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    opacity: 0;
    transform: scale(0);

    .pet-name {
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden !important;
      color: #000;
    }

    &:nth-child(3n + 2) {
      margin-top: 35px;
    }

    &.updating {
      animation: pet-updating 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s 1 normal forwards;
    }

    &.updated {
      animation: pet-updated 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s 1 normal forwards;
    }
  }
}
.pet-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.pet-avatar {
  width: 86px;
  height: 86px;
  flex: 0 0 auto;
  border: 6px solid #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}

.pet-name {
  font-family: var(--ion-poppins-font);
  font-weight: 700;
  font-size: 0.75rem;

  .pet-list-item & {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  25% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes walk {
  to {
    opacity: 1;
  }
}

@keyframes pet-updating {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes pet-updated {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  25% {
    opacity: 0;
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
