<template>
  <div>
    <h1 class="title" style="margin-top: 50px">Nyitvatartás:</h1>

    <div class="day-row" v-for="day in days" :key="day.day">
      <h1>{{ day.day }}</h1>

      <div class="hours">
        <div class="hour-content">
          <span v-if="day.open == '1'">{{ day.start }} - {{ day.close }}</span>
          <span v-else class="closed">Zárva</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'PoiBusinessHours',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const days = ref(props.data)

    return {
      days,
    }
  },
})
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
  font-size: 12px !important;
  text-align: left;
  margin: 0 34px 5px 34px;

  &.title {
    padding: 0 0 10px 0;
    text-align: center;
  }
}

.day-row {
  display: flex;
  align-items: center;
  padding: 0 80px;

  h1 {
    flex-grow: 1;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    margin: 0;
  }

  .hours {
    display: flex;
    align-items: center;

    .hour-content {
      flex-grow: 1;
      min-width: 100px;
      display: flex;
      justify-content: flex-end;
      font-weight: 800;
      letter-spacing: -0.05em;

      .closed {
        color: #c8c8c8;
      }
    }
  }
}
</style>
