<template>
  <IonPage v-if="poi">
    <IonContent>
      <div class="content">
        <div class="poi__icon">
          <ion-img :src="getCustomMarkerIcon(poi)" class="marker" />

          <ion-img :src="activePet.avatar" class="pet" />
        </div>

        <div class="poi__header">
          <div class="poi__header__content">
            <h1 class="poi__name">{{ poi.name }}</h1>
            <p class="poi__address">{{ poi.location }}</p>
          </div>
        </div>

        <div class="poi__pet__list" v-if="poi.editors?.length">
          <h1 class="poi__subtitle">Szerkesztők:</h1>
          <div class="poi__pet__list__list">
            <div class="poi__pet__list__list__item" v-for="pet in poi.editors" :key="pet.id">
              <ion-img :src="pet.avatar" />
            </div>
          </div>
        </div>

        <div class="poi__images" v-if="poi.images?.length">
          <h1 class="poi__subtitle">Szerkesztői képek:</h1>
          <div class="poi__images__list">
            <div
              class="poi__images__list__item"
              v-for="(image, key) in poi.images"
              :key="key"
              :style="`background-image: url(${image.url});`"
            >
              <div
                class="poi__images__list__item__remove"
                v-if="image.pet_id === activePet.id"
                @click="handleRemovePhoto(image)"
              >
                <ion-img :src="require('@/assets/images/icons/poi/bin.svg')" />
              </div>
            </div>

            <div class="poi__images__list__item" @click="handlePhotoTake">
              <ion-img :src="require('@/assets/images/icons/walk/poi/plus.svg')" :width="20" />
            </div>
          </div>
          <p class="poi__images__help">
            Kérjük, hogy általatok készített képeket töltsetek föl a helyről. A legjobb, ha a Kedvencetek is szerepel
            rajta!
          </p>
        </div>

        <div class="poi__about">
          <h1 class="poi__subtitle">Hely neve:</h1>
          <IonInput type="text" class="form-control" v-model="poi.name" readonly />
        </div>

        <div class="poi__about">
          <h1 class="poi__subtitle">Információk:</h1>
          <IonTextarea
            rows="5"
            readonly
            class="form-control"
            v-model="poi.description"
            placeholder="Mesélj néhány szóban a helyről."
          />
        </div>

        <div style="padding: 0 34px; margin-top: 41px; margin-bottom: 60px; display: flex; justify-content: center">
          <IonButton class="btn-strong" shape="round" strong="true" @click.prevent="submitChange"
            >Hely módosítása</IonButton
          >
        </div>
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { IonPage, IonContent, IonInput, IonTextarea, IonButton, IonImg, alertController } from '@ionic/vue'

import usePois from '@/composables/pois'
import usePets from '@/composables/pets'
import usePhotoGallery from '@/composables/camera'

export default defineComponent({
  name: 'EditPoi',
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonTextarea,
    IonButton,
    IonImg,
  },
  setup() {
    const route = useRoute()
    const poi = ref({})
    const poiId = computed(() => route.params.id)
    const removeImageIds = ref([])

    const { activePet } = usePets()
    const { getPoi } = usePois()
    const { takePhoto } = usePhotoGallery()
    const { editPoiImages } = usePois()

    onMounted(async () => {
      poi.value = await getPoi(poiId.value)
    })

    const getCustomMarkerIcon = marker => {
      if (marker.type === 'walkplace') {
        return require('@/assets/images/icons/walk/walkplace-marker.svg')
      } else if (marker.type === 'dogbeach') {
        return require('@/assets/images/icons/walk/dogbeach-marker.svg')
      } else if (marker.type === 'dogfriendly') {
        return require('@/assets/images/icons/walk/dogfriendly-marker.svg')
      }

      return require('@/assets/images/icons/walk/unknown-marker.svg')
    }

    const handlePhotoTake = async () => {
      if (poi.value.images.length >= 12) return

      try {
        const photo = await takePhoto()

        poi.value.images.push({
          id: 't-' + new Date().toString(),
          url: photo.url,
          value: photo.url,
          pet_id: activePet.value.id,
        })
      } catch (error) {
        console.error(error)
      }
    }

    const handleRemovePhoto = photo => {
      if (typeof photo.id === 'number') {
        removeImageIds.value.push(photo.id)
      }
      poi.value.images = poi.value.images.filter(item => item.id !== photo.id)
    }

    const submitChange = async () => {
      try {
        await editPoiImages(poiId.value, {
          new_images: poi.value.images.filter(image => typeof image.id === 'string' && image.id.startsWith('t-')),
          deleted_images: removeImageIds.value,
        })

        removeImageIds.value = []

        await showSuccessModal()
      } catch {
        await showFailModal()
      }
    }

    const showSuccessModal = async () => {
      const icon = require(`@/assets/images/animated/dog-head.gif`)

      const dialog = await alertController.create({
        cssClass: 'confirm-dialog poi-dialog',
        message: `
          <div class="poi-info">
            <img src="${icon}" class="icon" />
            <p>Sikeresen módosítottad a helyet!</p>
          </div>
        `,
        buttons: [
          {
            text: 'Bezárás',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block btn-popup-close',
          },
        ],
      })

      return await dialog.present()
    }

    const showFailModal = async () => {
      const icon = require(`@/assets/images/icons/sad-dog.png`)

      const dialog = await alertController.create({
        cssClass: 'confirm-dialog poi-dialog',
        message: `
          <div class="poi-info">
            <img src="${icon}" class="icon" />
            <p>Sajnos nem sikerült módosítani a helyet. Próbáld meg később.</p>
          </div>
        `,
        buttons: [
          {
            text: 'Bezárás',
            role: 'cancel',
            cssClass: 'btn btn-link btn-block btn-popup-close',
          },
        ],
      })

      return await dialog.present()
    }

    return {
      poi,
      activePet,
      getCustomMarkerIcon,
      handlePhotoTake,
      handleRemovePhoto,
      submitChange,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.content {
  margin-top: -50px;
}

.poi-title {
  padding: 0 16px;
}

h1 {
  font-weight: 700;
  font-size: 12px !important;
  text-align: left;
  margin: 0 34px 5px 34px;
}

.poi {
  &__icon {
    width: fit-content;
    margin: 160px auto 0 auto;
    position: relative;

    .marker,
    .marker::part(image) {
      min-height: 65px;
      max-height: 65px;
      min-width: 50px;
      max-width: 50px;
    }

    .pet {
      position: absolute;
      right: -10px;
      bottom: -16px;
      border: 4px solid #fff;
      border-radius: 99999px;
      min-width: 38px;
      max-width: 38px;
    }

    .pet::part(image) {
      min-height: 30px;
      max-height: 30px;
      border-radius: 99999px;
    }
  }

  &__name {
    margin-top: 26px;
    margin-bottom: 2px;
    font-weight: 800;
    text-align: center;
    letter-spacing: -0.3px;
    font-size: 15px !important;
    font-family: 'Poppins', sans-serif;
  }

  &__address {
    margin-top: 0;
  }

  &__header {
    text-align: center;
    word-wrap: break-word;
    padding: 0 34px;
    margin-bottom: 40px;
  }

  &__subtitle {
    padding: 0 16px;
  }

  &__images {
    margin-bottom: 27px;

    &__list {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      padding: 5px 34px 0 34px;
      margin-bottom: -27px;
      padding-bottom: 27px;

      &__item {
        background: #fff;
        min-width: 140px;
        aspect-ratio: 1 / 1;
        position: relative;

        background-position: center center;
        background-size: cover;
        border-radius: 25px;

        margin-right: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);

        &:last-of-type {
          margin-right: 0;
        }

        &__remove {
          position: absolute;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
          right: 10px;
          bottom: -7px;
          cursor: pointer;

          ion-img {
            width: 10.5px;
          }
        }
      }
    }

    &__help {
      padding: 0 34px;
      text-align: center;
      color: #afafaf;
    }
  }

  &__pet__list {
    margin-bottom: 27px;
    padding: 0 34px;

    h1 {
      margin: 0 0 5px 0;
    }

    &__list {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-left: 10px;

      &__item {
        min-width: 52px;
        min-height: 52px;
        max-width: 52px;
        max-height: 52px;
        border-radius: 9999px;
        margin-left: -10px;

        border: 5px solid #fff;
        filter: drop-shadow(3px 1px 5px rgba(0, 0, 0, 0.2));

        ion-img::part(image) {
          border-radius: 9999px;
        }
      }
    }
  }

  &__about {
    text-align: left;
    padding: 0 34px;
    margin-bottom: 27px;

    h1 {
      font-weight: 700;
      font-size: 12px !important;
      text-align: left;
      margin: 0 0 5px 0;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }
}

ion-input,
ion-textarea {
  border: none;
  box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  font-weight: 700;
  background: #f0f0f0;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  font-size: 14px;
  --placeholder-font-weight: 400;
}

ion-textarea {
  height: 124px;
}

ion-button {
  height: 50px;
  --background: #e6224a;
  max-width: 238px;
  --box-shadow: none;
}
</style>
