<template>
  <IonPage v-if="poi">
    <IonContent>
      <div class="container">
        <div class="content">
          <div class="header">
            <img :src="getCustomMarkerIcon(poi)" class="icon" />

            <h1 class="name">{{ poi.name }}</h1>

            <p>{{ poi.location }}</p>
          </div>

          <div>
            <div class="review__self">
              <ion-img :src="activePet.avatar" class="review__self__avatar avatar" />
              <h2>A Te értékelésed:</h2>
              <div class="review__self__paws">
                <ion-img
                  v-for="n in 5"
                  :key="n + '-review'"
                  :src="
                    ownRating >= n
                      ? require('@/assets/images/icons/walk/paw_green.svg')
                      : require('@/assets/images/icons/walk/paw_grey.svg')
                  "
                  alt="értékelés"
                  @click.prevent="ownRating = n"
                />
              </div>
            </div>
          </div>

          <div class="post-upload" :class="{ hasImage: image }">
            <ion-avatar
              v-if="!image"
              @click.prevent="getPhoto"
              :class="{ 'is-invalid': validations.image.$error || hasServerValidationError('file') }"
            >
              <lottie-animation
                v-if="!isUploadLoading"
                ref="anim"
                :autoPlay="true"
                :playInterval="10 * 1e3"
                :animationData="require('@/assets/images/lottie/postupload.json')"
              />
              <span v-else>Feltöltés...</span>
            </ion-avatar>
            <ion-input type="hidden" name="image" v-model="image" />

            <div class="post-upload-preview">
              <ion-img v-if="image" :src="image" @click="removePhoto" />
            </div>

            <ErrorMessage :field="validations.image" />
            <ServerValidation field="file" :server-validation="serverValidation" />
          </div>

          <div style="padding: 30px 10px 20px 10px" class="textarea-box">
            <ion-img :src="activePet.avatar" class="review__self__avatar avatar" />
            <IonTextarea
              rows="10"
              required
              class="form-control"
              style="text-align: left"
              v-model="reviewText"
              placeholder="Mondd el a véleményed a helyről."
            />
          </div>

          <div style="padding: 0 10px; margin-bottom: 60px; display: flex; justify-content: center">
            <IonButton class="btn-strong" shape="round" strong="true" @click="reviewPoi">Értékelés beküldése</IonButton>
          </div>
        </div>
      </div>
    </IonContent>
  </IonPage>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { IonPage, IonContent, IonInput, IonTextarea, IonButton, IonAvatar, IonImg } from '@ionic/vue'
import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

import usePois from '@/composables/pois'
import usePets from '@/composables/pets'
import usePosts from '@/composables/posts'
import useToast from '@/composables/toast'
import usePhotoGallery from '@/composables/camera'
import useFormControl from '@/composables/formControl'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  name: 'ReviewPoi',
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonTextarea,
    IonButton,
    IonAvatar,
    IonImg,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const poi = ref({})
    const poiId = computed(() => route.params.id)
    const ownRating = ref(0)
    const reviewText = ref(null)
    const image = ref(null)

    const { activePet } = usePets()
    const { openToast } = useToast()
    const { getPoi, ratePoi } = usePois()
    const { fetchPost } = usePosts()
    const { serverValidation, hasServerValidationError } = useFormControl()
    const { takePhoto, isLoading: isUploadLoading } = usePhotoGallery()

    const rules = {
      image: { required },
    }

    const validations = useVuelidate(rules, {
      image,
    })

    const getPhoto = async () => {
      try {
        serverValidation.value = {}

        const photo = await takePhoto()
        image.value = photo.url
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      }
    }

    const removePhoto = () => {
      image.value = null
      getPhoto()
    }

    onMounted(async () => {
      poi.value = await getPoi(poiId.value)
      ownRating.value = poi.value?.ownRating?.rating ?? 0
      reviewText.value = poi.value?.ownRating?.text ?? null

      if (!poi.value?.ownRating?.post_id) return

      const post = await fetchPost(poi.value?.ownRating?.post_id)

      image.value = post.image
    })

    const getCustomMarkerIcon = marker => {
      if (marker.type === 'walkplace') {
        return require('@/assets/images/icons/walk/walkplace-marker.svg')
      } else if (marker.type === 'dogbeach') {
        return require('@/assets/images/icons/walk/dogbeach-marker.svg')
      } else if (marker.type === 'dogfriendly') {
        return require('@/assets/images/icons/walk/dogfriendly-marker.svg')
      } else if (marker.logo) {
        return marker.logo
      }

      return require('@/assets/images/icons/walk/unknown-marker.svg')
    }

    const reviewPoi = async () => {
      try {
        await ratePoi(poiId.value, ownRating.value, reviewText.value, image.value)

        await router.go(-1)
      } catch (error) {
        console.error(error)

        openToast({
          message: 'Sajnos nem sikerült leadnod az értékelésedet :(',
          color: 'warning',
        })
      }
    }

    return {
      poi,
      activePet,
      reviewPoi,
      ownRating,
      reviewText,
      getCustomMarkerIcon,
      validations,
      isUploadLoading,
      image,
      getPhoto,
      removePhoto,
      hasServerValidationError,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.review-title {
  font-size: 13px !important;
  font-weight: bold;
  padding: 0 0.75rem;
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
  max-width: 294px;
}

.textarea-box {
  display: flex;

  .avatar {
    margin-bottom: 0;
    margin-right: 10px;
  }

  ion-textarea {
    border: none;
    box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    height: 136px;
    font-weight: 700;
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 8px;
    --padding-bottom: 8px;
    font-size: 13px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 30px;

  .icon {
    max-height: 72px;
    min-height: 72px;
    margin: 25px auto 15px auto;
    border-radius: 99999px;
    object-fit: cover;
  }

  h1.name {
    font-family: 'Poppins', sans-serif;
    line-height: 1;
    margin: 0;
    font-size: 15px !important;
    font-weight: 800;
  }

  p {
    margin-top: 3px;
    font-size: 10px;
  }
}

.review {
  padding: 0 34px;

  h1 {
    margin: 0 0 5px 0;
  }

  &__self {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 9px;
    font-weight: 600;
    line-height: 16px;

    &__avatar {
      aspect-ratio: 1 / 1;
      margin-right: 0;
      border-radius: 9999px;
      margin-bottom: 9px;
      width: 46px;
      height: 46px;

      border: 4px solid #fff;
      filter: drop-shadow(-3px 5px 11px rgba(0, 0, 0, 0.15));

      &::part(image) {
        border-radius: 9999px;
        min-width: 38px;
        min-height: 38px;
        max-width: 38px;
      }
    }

    h2 {
      font-size: 12px !important;
      font-weight: 700;
      text-align: center;
      line-height: 16px;
      margin-top: 0;
      margin-bottom: 10px;
      font-family: 'Open Sans', sans-serif;
    }

    &__paws {
      flex-grow: 1;
      display: flex;
      justify-content: end;

      ion-img {
        height: 25px;
        margin-right: 5px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.post-upload {
  margin-top: 20px;
}

.post-upload-preview {
  position: relative;

  ion-img {
    border-radius: 24px;
    overflow: hidden;
  }
}

.lottie-animation {
  width: 36px;
  height: 34px;
}

ion-avatar {
  width: 54px;
  height: 54px;
  margin: 40px auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -5px 12px 19px 1px rgba(0, 0, 0, 0.13);
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border-radius: 50%;
    border: 1px dashed #cccccc;
  }

  &.is-invalid {
    &:before {
      border-width: 2px;
      border-color: #e45043;
    }
  }
}

.post-selectors {
  display: flex;
  justify-content: end;
  margin-bottom: 11px;

  .selector-box {
    position: relative;

    .tooltip {
      position: absolute;
      display: flex;
      opacity: 0;
      font-size: 14px;
      align-items: center;
      height: 50px;
      padding-left: 18px;
      padding-right: 25px;
      box-shadow: -7px 11px 29px -10px rgba(0, 0, 0, 0.2);
      border-radius: 28px;
      background-color: white;
      white-space: nowrap;
      bottom: 53px;
      right: -10px;
      transition: all 0.15s ease-in-out;

      ion-img {
        width: 13px;
        height: 13px;
        min-width: 13px;
        min-height: 13px;
        margin-right: 10px;
      }
    }

    &:hover .tooltip {
      opacity: 1;
      transition: all 0.15s ease-in-out;
    }

    &:first-of-type {
      margin-right: 5px;
    }
  }

  .main-selector {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    border: 4px solid white;
    border-radius: 99999px;
  }
}
</style>
