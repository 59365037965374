<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <PetSelector disabled />

          <div class="cards">
            <div class="card">
              <h1>{{ walkingPoints }}</h1>
              <p>pontot gyűjtöttél ma</p>
            </div>
            <div class="card">
              <h1>{{ totalWalkingPoints }}</h1>
              <p>pont összesen</p>
            </div>
            <!-- <div class="card" @click="previousWalks">
              <ion-img :src="require('@/assets/images/icons/walkgray.svg')" style="height: 24px; margin-bottom: 5px" />
              <p>korábbi sétáid</p>
            </div> -->
          </div>

          <div class="roles">
            <div class="role">
              <div class="icon">
                <svg class="chart-circle active" id="chart-beginner" width="50%" viewBox="0 0 100 100">
                  <linearGradient id="gradient-leaderboard-active">
                    <stop offset="0" stop-color="#05FF00" />
                    <stop offset="100" stop-color="#14AF11" />
                  </linearGradient>
                  <linearGradient id="gradient-leaderboard-inactive">
                    <stop offset="0" stop-color="#C1C1C1" />
                  </linearGradient>
                  <path class="underlay" d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50" />
                </svg>
                <lottie-animation
                  ref="anim"
                  :autoPlay="false"
                  :playInterval="6 * 1e3"
                  :animationData="require('@/assets/images/lottie/leaderboard/beginner.json')"
                />
              </div>

              <p>Kezdő</p>

              <span>100</span>
            </div>

            <div class="role">
              <div class="icon">
                <svg
                  class="chart-circle"
                  :class="{ active: ['newbie', 'adventurer', 'explorer'].includes(activePet.level) }"
                  id="chart-newbie"
                  width="50%"
                  viewBox="0 0 100 100"
                >
                  <path class="underlay" d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50" />
                </svg>
                <lottie-animation
                  ref="anim"
                  :autoPlay="false"
                  :playInterval="6 * 1e3"
                  :animationData="require('@/assets/images/lottie/leaderboard/newbie.json')"
                  v-if="['newbie', 'adventurer', 'explorer'].includes(activePet.level)"
                />
                <ion-img :src="require('@/assets/images/icons/leaderboard/newbie-gray.svg')" v-else />
              </div>

              <p>Újonc</p>

              <span>300</span>
            </div>

            <div class="role">
              <div class="icon">
                <svg
                  class="chart-circle"
                  :class="{ active: ['adventurer', 'explorer'].includes(activePet.level) }"
                  id="chart-adventurer"
                  width="50%"
                  viewBox="0 0 100 100"
                >
                  <path class="underlay" d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50" />
                </svg>
                <lottie-animation
                  ref="anim"
                  :autoPlay="false"
                  :playInterval="6 * 1e3"
                  :animationData="require('@/assets/images/lottie/leaderboard/adventurer.json')"
                  v-if="['adventurer', 'explorer'].includes(activePet.level)"
                />
                <ion-img :src="require('@/assets/images/icons/leaderboard/adventurer-gray.svg')" v-else />
              </div>

              <p>Kalandor</p>

              <span>600</span>
            </div>

            <div class="role">
              <div class="icon">
                <svg
                  class="chart-circle"
                  :class="{ active: activePet.level === 'explorer' }"
                  id="chart-explorer"
                  width="50%"
                  viewBox="0 0 100 100"
                >
                  <path class="underlay" d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50" />
                </svg>
                <lottie-animation
                  ref="anim"
                  :autoPlay="false"
                  :playInterval="6 * 1e3"
                  :animationData="require('@/assets/images/lottie/leaderboard/explorer.json')"
                  v-if="activePet.level === 'explorer'"
                />
                <ion-img :src="require('@/assets/images/icons/leaderboard/explorer-gray.svg')" v-else />
              </div>

              <p>Felfedező</p>

              <span>1000</span>
            </div>
          </div>

          <h1 class="top">A héten legaktívabbak:</h1>

          <div class="pets" v-if="isRunning">
            <p style="text-align: center" v-if="!topPets.length">Nem találtunk senkit... {{ ':(' }}</p>
            <div class="pet" v-for="pet in topPets" :key="pet.id" @click="goToPet(pet.id)">
              <ion-avatar class="pet-avatar">
                <ion-img :src="pet.avatar.cdn_url" alt="Kép" />
              </ion-avatar>
              <p>
                <b>
                  {{ pet.name }}
                  <lottie-animation
                    ref="anim"
                    :autoPlay="false"
                    :playInterval="6 * 1e3"
                    v-if="pet.level === 'beginner'"
                    :animationData="require('@/assets/images/lottie/leaderboard/beginner.json')"
                  />
                  <lottie-animation
                    ref="anim"
                    :autoPlay="false"
                    :playInterval="6 * 1e3"
                    v-if="pet.level === 'newbie'"
                    :animationData="require('@/assets/images/lottie/leaderboard/newbie.json')"
                  />
                  <lottie-animation
                    ref="anim"
                    :autoPlay="false"
                    :playInterval="6 * 1e3"
                    v-if="pet.level === 'adventurer'"
                    :animationData="require('@/assets/images/lottie/leaderboard/adventurer.json')"
                  />
                  <lottie-animation
                    ref="anim"
                    :autoPlay="false"
                    :playInterval="6 * 1e3"
                    v-if="pet.level === 'explorer'"
                    :animationData="require('@/assets/images/lottie/leaderboard/explorer.json')"
                  /> </b
                ><br />
                {{ pet.city.name }}
              </p>
            </div>
          </div>

          <div class="pets-inactive" v-else>
            <ion-img :src="require(`@/assets/images/icons/sad-dog.png`)" alt="Szomorú kutyafej" />
            <p>A legaktívabb Kedvenceket csak sétálás<br />közben láthatod.</p>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { IonPage, IonContent, IonImg, IonAvatar } from '@ionic/vue'

import { PET_ROUTE } from '@/constants/routes'

import useToast from '@/composables/toast'
import usePets from '@/composables/pets'
import useNavigation from '@/composables/navigation'

import PetSelector from '@/components/Pet/PetSelector'

import 'snapsvg-cjs/dist/snap.svg.js'
const Snap = window.Snap

export default defineComponent({
  name: 'Leaderboard',
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonAvatar,
    PetSelector,
  },
  setup() {
    const { openToast } = useToast()
    const { navigateTo } = useNavigation()
    const { getCurrentLeaderboard, activePet, PETS_STATE } = usePets()

    const walkingPoints = ref('-')
    const totalWalkingPoints = ref('-')
    const topPets = ref([])

    const isRunning = computed(() => {
      return PETS_STATE.activeWalking ?? false
    })

    const previousWalks = async () => {
      await openToast({
        message: 'Ez a funkció jelenleg nem elérhető :(',
        color: 'secondary',
      })
    }

    const goToPet = async petId => {
      await navigateTo({ name: PET_ROUTE, params: { id: petId, refresh: true } })
    }

    const levels = [
      {
        name: 'beginner',
        points: 100,
      },
      {
        name: 'newbie',
        points: 300,
      },
      {
        name: 'adventurer',
        points: 600,
      },
      {
        name: 'explorer',
        points: 1000,
      },
    ]

    onMounted(async () => {
      const { total, points, top_pets } = await getCurrentLeaderboard()
      walkingPoints.value = points
      totalWalkingPoints.value = total
      topPets.value = Object.values(top_pets).map(pet => {
        let orgImg = pet.avatar.cdn_url.split('/')
        orgImg = orgImg[orgImg.length - 1]
        orgImg = orgImg.substring(0, orgImg.length - 4)

        const newUri = `https://res.cloudinary.com/doxocial/image/upload/w_280,h_280,c_fill/${orgImg}.png`

        return {
          ...pet,
          avatar: {
            cdn_url: newUri,
          },
        }
      })

      levels.forEach(l => updateProgress(l.name, parseInt(total), l.points))
    })

    const updateProgress = (levelName, current, max) => {
      const paper = Snap(`#chart-${levelName}`)
      if (!paper) return

      const currentLevel = levels.find(l => l.points > current)

      if (current < max && levelName !== (currentLevel?.name ?? 'explorer')) current = max / 10

      const myID = levelName
      let prog = null
      if (document.getElementById(myID)) {
        prog = paper.select('#' + myID)
      } else {
        prog = paper.path('M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50')
      }

      const percent = Math.ceil((current / max) * 100)

      const lineL = prog.getTotalLength()
      const oneUnit = lineL / 100
      const toOffset = lineL - oneUnit * (percent > 100 ? 100 : percent)
      const animTime = 0

      prog.attr({
        'stroke-dashoffset': lineL,
        'stroke-dasharray': lineL,
        id: myID,
      })

      prog.animate(
        {
          'stroke-dashoffset': toOffset,
        },
        animTime,
        a => Math.pow(a, 0.48),
      )
    }

    return {
      goToPet,
      previousWalks,
      topPets,
      walkingPoints,
      activePet,
      totalWalkingPoints,
      isRunning,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&family=Open+Sans:wght@400;800&display=swap');

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;

  &.top {
    font-size: 14px !important;
    text-align: center;
    margin-bottom: 18px;
  }
}

.cards {
  margin-top: -120px;
  margin-bottom: 27px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 13px;
  grid-row-gap: 0px;
  padding: 0 40px;

  .card {
    padding: 14px 10px;
    background: #fff;
    box-shadow: 4px 6px 17px -3px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    text-align: center;

    h1 {
      font-weight: 800;
      font-family: 'Montserrat', sans-serif;
      margin: 0;
      color: #00de1f;
      font-size: 30px;
    }

    p {
      margin: 0;
      font-weight: 800;
      font-size: 10px;
      margin-top: 2px;
      font-family: 'Open Sans', sans-serif;
    }

    ion-img {
      max-height: 24px;
    }
  }
}

.roles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  margin-bottom: 47px;
  padding: 0 25px;

  .role {
    text-align: center;

    .icon {
      position: relative;
      margin: 0 auto 10px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 999999px;
      min-width: 39px;
      max-width: 39px;
      min-height: 39px;
      max-height: 39px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);

      .lottie-animation {
        max-height: 35px;
        min-height: 35px;
        max-width: 35px;
        min-width: 35px;
      }
    }

    p {
      margin: 0;
      font-weight: 800;
      font-size: 10px;
      margin-top: 2px;
      font-family: 'Open Sans', sans-serif;
    }

    span {
      font-size: 12px;
      color: #999;
      margin-top: 12px;
    }
  }
}

.pet-avatar {
  width: 39px;
  height: 39px;
  border: 3px solid #fff;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.16));
  background-color: #fff;
  margin-right: 13px;
}

.pets {
  .pet {
    display: flex;
    margin-bottom: 12px;
    align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      font-size: 10px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;

      b {
        font-weight: 800;
        font-size: 12px;
        line-height: 0;
        display: inline-flex;
        align-items: center;

        .lottie-animation {
          max-height: 15px;
          min-height: 15px;
          max-width: 15px;
          min-width: 15px;
          margin-left: 4px;
        }
      }
    }
  }
}

.pets-inactive {
  margin-top: 47px;
  text-align: center;
  font-size: 14px;
  color: #333333;

  ion-img {
    height: 35px;
  }
}

.chart-circle {
  position: absolute;
  left: -3.5px;
  bottom: -3.5px;
  min-width: calc(40px + 6px);
  min-height: calc(40px + 6px);

  stroke: url(#gradient-leaderboard-inactive);
  stroke-width: 6;
  fill: transparent;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-90deg);

  &.active {
    stroke: url(#gradient-leaderboard-active);
  }

  .underlay {
    stroke-width: 6;
    fill: transparent;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: transparent;
  }
}
</style>
