<template>
  <ion-list
    class="reads-list"
    lines="none "
    v-for="(item, index) in readEvents"
    :key="index"
    :class="Capacitor.isNative ? '' : 'swiper-no-swiping'"
  >
    <ion-item class="reads-item">
      <div v-if="index != 'older'" class="card-div">
        <ion-label class="control-label reads-label" @click="item.open = !item.open">
          {{ item.date }}
          <ion-icon v-if="item.open == true" :icon="caretUpOutline" class="caret-down-icon reads-label"></ion-icon>
          <ion-icon v-else :icon="caretDownOutline" class="caret-down-icon reads-label"></ion-icon>
        </ion-label>

        <div v-if="item.open == true && index != 'older'" class="ion-card-wrapper">
          <ion-card class="ion-card-styled" v-for="element in item" :key="element.id">
            <ion-card-content class="detectedContent clickable" @click.prevent="event => doSomething(event, element)">
              <div class="left-wrapper">
                <ion-thumbnail
                  slot="start"
                  class="ionThumbnail"
                  :class="element.maps_img != null ? '' : 'noMapThumbnail'"
                >
                  <div v-if="element.maps_img != null" class="map-img-wrapper-div">
                    <ion-img :src="pawnIcon" class="mapImage-icon-nomap mapImage-overlay"></ion-img>
                    <ion-img :src="element.maps_img" class="mapImage"></ion-img>
                  </div>
                  <div v-else class="no-location-wrapper-div">
                    <span class="mapImageSpan">
                      <ion-icon :icon="pawnIconGray" class="mapImage-icon-nomap noLocation"></ion-icon>
                    </span>
                  </div>
                </ion-thumbnail>
                <div
                  class="location-wrapper"
                  :class="
                    (element.address == null || element.address == '') &&
                    (element.city == null || element.city == '') &&
                    element.location.latitude == null &&
                    element.location.longitude == null
                      ? 'nolocation-text'
                      : ''
                  "
                >
                  <p
                    :class="
                      (element.address == null || element.address == '') &&
                      (element.location.latitude == null || element.location.longitude == null)
                        ? 'no-location'
                        : ''
                    "
                    v-if="element.city != null"
                  >
                    {{ element.city }}
                  </p>
                  <p
                    :class="
                      (element.city == null || element.city == '') &&
                      (element.location.latitude == null || element.location.longitude == null)
                        ? 'no-location'
                        : ''
                    "
                    v-if="element.address != null"
                  >
                    {{ element.address }}
                  </p>
                  <p
                    :class="
                      (element.address == null || element.address == '') && (element.city == null || element.city == '')
                        ? 'no-location'
                        : ''
                    "
                    v-if="element.location.latitude != null || element.location.longitude != null"
                  >
                    <span> {{ element.location.latitude.toFixed(4) }} , </span>
                    <span>
                      {{ element.location.longtitude.toFixed(4) }}
                    </span>
                  </p>

                  <p
                    class="no-location"
                    v-if="
                      (element.address == null || element.address == '') &&
                      (element.city == null || element.city == '') &&
                      element.location.latitude == null &&
                      element.location.longitude == null
                    "
                  >
                    Nincs helyadat
                  </p>
                </div>
              </div>

              <div class="right-wrapper">
                <div>
                  <ion-icon
                    :icon="
                      element.device != null &&
                      element.device != 'unknown' &&
                      ((element.address != null && element.city != null) ||
                        (element.location?.latitude != null && element.location?.longitude != null)) &&
                      (element.device.includes('iPhone') || element.device == 'iOS')
                        ? appleIcon
                        : element.device != null &&
                          element.device != 'unknown' &&
                          ((element.address != null && element.city != null) ||
                            (element.location?.latitude != null && element.location?.longitude != null)) &&
                          element.device == 'Android'
                        ? androidIcon
                        : element.device != null &&
                          element.device != 'unknown' &&
                          ((element.address != null && element.city != null) ||
                            (element.location?.latitude != null && element.location?.longitude != null)) &&
                          element.device == 'Windows'
                        ? windowsIcon
                        : infoIcon
                    "
                    class="device-icon"
                    :class="Capacitor.isNative ? '' : 'swiper-no-swiping'"
                  ></ion-icon>
                </div>
                <ion-label class="control-label time-stamp"> {{ element.update_time }}</ion-label>
              </div>
            </ion-card-content>
          </ion-card>
        </div>
      </div>

      <div v-else class="card-div card-div-older">
        <ion-label
          class="control-label reads-label"
          @click="item.open = !item.open"
          v-if="Object.entries(item).length > 0"
        >
          Régebbiek
          <ion-icon v-if="item.open == true" :icon="caretUpOutline" class="caret-down-icon reads-label"></ion-icon>
          <ion-icon v-else :icon="caretDownOutline" class="caret-down-icon reads-label"></ion-icon>
        </ion-label>

        <div v-if="item.open == true && index == 'older'" class="ion-card-wrapper-older-item">
          <ion-list class="reads-list" lines="none " v-for="element in item" :key="element.id">
            <ion-item class="reads-item reads-item-older">
              <ion-label
                class="control-label reads-label reads-label-older"
                @click="element.open = !element.open"
                v-if="element.date"
              >
                <span> {{ element.date }} </span>
                <ion-icon
                  v-if="element.open == true"
                  :icon="caretUpOutline"
                  class="caret-down-icon reads-label-older"
                ></ion-icon>
                <ion-icon v-else :icon="caretDownOutline" class="caret-down-icon reads-label-older"></ion-icon>
              </ion-label>
              <div v-if="element.open == true" class="ion-card-wrapper ion-card-wrapper-older">
                <ion-card class="ion-card-styled" v-for="el in element" :key="el.id">
                  <ion-card-content class="detectedContent clickable" @click.prevent="event => doSomething(event, el)">
                    <div class="left-wrapper">
                      <ion-thumbnail
                        slot="start"
                        class="ionThumbnail"
                        :class="el.maps_img != null ? '' : 'noMapThumbnail'"
                      >
                        <div v-if="el.maps_img != null" class="map-img-wrapper-div">
                          <ion-img
                            :src="pawnIcon"
                            class="mapImage-icon-nomap mapImage-overlay"
                            v-if="el.maps_img != null"
                            color="success"
                          ></ion-img>
                          <ion-img v-if="el.maps_img != null" :src="el.maps_img" class="mapImage"></ion-img>
                        </div>
                        <div v-else class="no-location-wrapper-div">
                          <span class="mapImageSpan">
                            <ion-icon
                              :icon="pawnIconGray"
                              class="mapImage-icon-nomap noLocation"
                              color="default"
                            ></ion-icon>
                          </span>
                        </div>
                      </ion-thumbnail>

                      <div
                        class="location-wrapper"
                        :class="
                          (el.address == null || el.address == '') &&
                          (el.city == null || el.city == '') &&
                          el.location.latitude == null &&
                          el.location.longitude == null
                            ? 'nolocation-text'
                            : ''
                        "
                      >
                        <p
                          :class="
                            el.address == null && (el.location.latitude == null || el.location.longitude == null)
                              ? 'no-location'
                              : ''
                          "
                          v-if="el.city != null"
                        >
                          {{ el.city }}
                        </p>
                        <p
                          :class="
                            (el.city == null || el.city == '') &&
                            (el.location.latitude == null || el.location.longitude == null)
                              ? 'no-location'
                              : ''
                          "
                          v-if="el.address != null"
                        >
                          {{ el.address }}
                        </p>
                        <p
                          :class="
                            (el.address == null || el.address == '') && (el.city == null || el.city == '')
                              ? 'no-location'
                              : ''
                          "
                          v-if="el.location.latitude != null || el.location.longitude != null"
                        >
                          <span> {{ el.location.latitude.toFixed(4) }} , </span>
                          <span>
                            {{ el.location.longtitude.toFixed(4) }}
                          </span>
                        </p>

                        <p
                          class="no-location"
                          v-if="
                            (el.address == null || el.address == '') &&
                            (el.city == null || el.city == '') &&
                            el.location.latitude == null &&
                            el.location.longitude == null
                          "
                        >
                          Nincs helyadat
                        </p>
                      </div>
                    </div>

                    <div class="right-wrapper">
                      <div>
                        <ion-icon
                          :icon="
                            el.device != null &&
                            el.device != 'unknown' &&
                            ((el.address != null && el.city != null) ||
                              (el.location?.latitude != null && el.location?.longitude != null)) &&
                            (el.device.includes('iPhone') || el.device == 'iOS')
                              ? appleIcon
                              : el.device != null &&
                                el.device != 'unknown' &&
                                ((el.address != null && el.city != null) ||
                                  (el.location?.latitude != null && el.location?.longitude != null)) &&
                                el.device == 'Android'
                              ? androidIcon
                              : el.device != null &&
                                el.device != 'unknown' &&
                                ((el.address != null && el.city != null) ||
                                  (el.location?.latitude != null && el.location?.longitude != null)) &&
                                el.device == 'Windows'
                              ? windowsIcon
                              : infoIcon
                          "
                          class="device-icon"
                          :class="Capacitor.isNative ? '' : 'swiper-no-swiping'"
                        ></ion-icon>
                      </div>
                      <ion-label class="control-label time-stamp"> {{ el.update_time }}</ion-label>
                    </div>
                  </ion-card-content>
                </ion-card>
              </div>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ion-item>
  </ion-list>
</template>

<script>
import { defineComponent, reactive, watch, toRefs, onMounted } from 'vue'
import moment from 'moment'
import {
  IonIcon,
  IonList,
  IonItem,
  IonCard,
  IonThumbnail,
  IonImg,
  IonCardContent,
  IonLabel,
  alertController,
} from '@ionic/vue'
import { caretDownOutline, caretUpOutline } from 'ionicons/icons'
import { MAPS_API } from '@/constants/types'

import { DOX_READ_MAP_ROUTE } from '@/constants/routes'
import useNavigation from '@/composables/navigation'

import { Capacitor } from '@capacitor/core'

export default defineComponent({
  name: 'Reads',
  components: {
    IonCardContent,
    IonIcon,
    IonList,
    IonItem,
    IonCard,
    IonThumbnail,
    IonImg,
    IonLabel,
  },
  props: {
    readItems: {
      type: [Array, Object],
    },
  },

  setup(props) {
    const today = moment().format('YYYY.MM.DD')

    const { navigateTo } = useNavigation()

    const state = reactive({
      readEvents: [],
      appleIcon: require('@/assets/images/icons/apple.svg'),
      androidIcon: require('@/assets/images/icons/android.svg'),
      infoIcon: require('@/assets/images/icons/info-full.svg'),
      windowsIcon: require('@/assets/images/os/windows-logo.svg'),

      markerIcon: require('@/assets/images/follow-outline-png.png'),
      pawnIcon: require('@/assets/images/icons/marker.png'),
      pawnIconGray: require('@/assets/images/icons/Dogs-marker-gray.svg'),
    })

    onMounted(() => {
      state.readEvents =
        props.readItems.length > 0
          ? groupBy(
              mapReads(props.readItems).sort(function (a, b) {
                return new Date(b.create) - new Date(a.create)
              }),
              'create',
            )
          : {}
      if (Object.entries(state.readEvents) != undefined && Object.entries(state.readEvents).length > 0) {
        for (let i in state.readEvents) {
          state.readEvents[i].date = i

          Object.values(state.readEvents).indexOf(state.readEvents[i]) == 0
            ? (state.readEvents[i].open = true)
            : (state.readEvents[i].open = false)

          for (let el of state.readEvents[i]) {
            el.maps_img =
              el.location.longtitude != null && el.location.latitude != null
                ? 'https://maps.googleapis.com/maps/api/staticmap?center=' +
                  el.location.latitude +
                  ',' +
                  el.location.longtitude +
                  '&zoom=15&scale=1&size=320x320&map_id=9e2b4f5760285ed5&markers=color:green|' +
                  el.location.latitude +
                  ',' +
                  el.location.longtitude +
                  ' &maptype=roadmap&key=' +
                  MAPS_API
                : null
          }
        }
      }

      if (Object.entries(state.readEvents) != undefined && Object.entries(state.readEvents).length > 0) {
        // destructuring and restructuring of state.readEvents to have " older " entities
        let a, b, rest
        ;[a, b, ...rest] = Object.entries(state.readEvents)

        let older = Object.fromEntries(rest)

        state.readEvents = {}
        if (a != undefined && b != undefined && older.length > 0) {
          Object.assign(state.readEvents, Object.fromEntries([a]), Object.fromEntries([b]), { older })
        } else if (b == undefined) {
          Object.assign(state.readEvents, Object.fromEntries([a]), { older })
        } else if (older == undefined) {
          Object.assign(state.readEvents, Object.fromEntries([a]), Object.fromEntries([b]))
        } else {
          Object.assign(state.readEvents, Object.fromEntries([a]), Object.fromEntries([b]), { older })
        }
      }
    })

    watch(props.readItems, () => {
      state.readEvents = props.readItems.length > 0 ? groupBy(mapReads(props.readItems), 'create') : {}
      if (Object.entries(state.readEvents) != undefined && Object.entries(state.readEvents).length > 0) {
        for (let i in state.readEvents) {
          state.readEvents[i].date = i

          for (let el of state.readEvents[i]) {
            el.maps_img =
              el.location.longtitude != null && el.location.latitude != null
                ? 'https://maps.googleapis.com/maps/api/staticmap?center=' +
                  el.location.latitude +
                  ',' +
                  el.location.longtitude +
                  '&zoom=20&scale=1&size=320x320&markers=icon:' +
                  state.markerIcon +
                  '|' +
                  el.location.latitude +
                  ',' +
                  el.location.longtitude +
                  ' &maptype=roadmap&key=' +
                  MAPS_API
                : null
          }
        }
      }
      if (Object.entries(state.readEvents) != undefined && Object.entries(state.readEvents).length > 0) {
        // destructuring and restructuring of state.readEvents to have " older " entities
        let a, b, rest
        ;[a, b, ...rest] = Object.entries(state.readEvents)

        let older = Object.fromEntries(rest)

        state.readEvents = {}
        if (a != undefined && b != undefined && older.length > 0) {
          Object.assign(state.readEvents, Object.fromEntries([a]), Object.fromEntries([b]), { older })
        } else if (b == undefined) {
          Object.assign(state.readEvents, Object.fromEntries([a]), { older })
        } else if (older == undefined) {
          Object.assign(state.readEvents, Object.fromEntries([a]), Object.fromEntries([b]))
        } else {
          Object.assign(state.readEvents, Object.fromEntries([a]), Object.fromEntries([b]), { older })
        }
      }
    })

    function groupBy(list, property) {
      return list.reduce((groups, item) => {
        const val = item[property]
        groups[val] = groups[val] || []
        groups[val].push(item)
        return groups
      }, {})
    }

    function IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }

    function mapReads(data) {
      return data.map(read => {
        return {
          ...{ original_data: read },
          id: read.id,
          name: read.rfid_id,
          city: read.city,
          address: read.address,
          location: {
            longtitude: read.location != null && read.location.coordinates ? read.location.coordinates[0] : null,
            latitude: read.location != null && read.location.coordinates ? read.location.coordinates[1] : null,
          },
          device:
            read.device_info != null && !IsJsonString(read.device_info)
              ? read.device_info
              : read.device_info != null && IsJsonString(read.device_info)
              ? JSON.parse(read.device_info)['device']
              : null,
          create: read.created_at ? moment(read.created_at).format('YYYY.MM.DD') : read.create ? read.create : null,
          update_time: read.created_at
            ? moment.utc(read.created_at).local().format('HH:mm')
            : read.update_time
            ? read.update_time
            : null,
          update_date: read.created_at
            ? moment(read.created_at).format('YYYY.MM.DD')
            : read.update_date
            ? read.update_date
            : null,
          open: false,
        }
      })
    }

    const openMap = coords => {
      let coordinates = coords
      setTimeout(() => {
        navigateTo({
          name: DOX_READ_MAP_ROUTE,
          params: {
            lat: coordinates.latitude,
            long: coordinates.longtitude,
          },
        })
      }, 500)
    }

    const openModal = async element => {
      let content = ''

      if (
        (element.address == null || element.address == '') &&
        (element.city == null || element.city == '') &&
        element.location?.latitude == null &&
        element.location?.longitude == null &&
        (element.device == null || element.device == 'unknown')
      ) {
        content =
          'A leolvasó készülék típusa nem beazonosítható és a lokáció pontos megjelenítéséhez a leolvasó telefonon engedélyezni kell a helyadatokhoz való hozzáférést is.'
      } else if (element.device == null || element.device == 'unknown') {
        content = 'A leolvasó készülék típusa nem beazonosítható.'
      } else {
        content =
          'A lokáció pontos megjelenítéséhez a leolvasó telefonon engedélyezni kell a helyadatokhoz való hozzáférést.'
      }

      const dialog = await alertController.create({
        cssClass: 'notice-alert',
        message: content,
        buttons: [
          {
            text: '',
            role: 'cancel',
            cssClass: 'btn close-btn',
            handler: () => {
              dialog.dismiss()
            },
          },
        ],
      })

      return dialog.present()
    }

    function doSomething(event, element) {
      if (
        event.target.classList[0] == 'device-icon' &&
        (element.device != null || element.device != 'unknown') &&
        (element.address == null ||
          element.address == '' ||
          element.city == null ||
          element.city == '' ||
          (element.location?.latitude == null && element.location?.longitude == null))
      ) {
        openModal(element)
      } else if (
        event.target.classList[0] != 'device-icon' &&
        (element.address == null ||
          element.address == '' ||
          element.city == null ||
          element.city == '' ||
          (element.location?.latitude == null && element.location?.longitude == null))
      ) {
        openModal(element)
      } else if (
        event.target.classList[0] == 'device-icon' &&
        (element.device == null || element.device == 'unknown')
      ) {
        openModal(element)
      } else if (event.target.classList[0] != 'device-icon') {
        openMap(element.location)
      } else if (
        event.target.classList[0] == 'device-icon' &&
        (element.address != null ||
          element.address != '' ||
          element.city != null ||
          element.city != '' ||
          (element.location?.latitude != null && element.location?.longitude != null))
      ) {
        openMap(element.location)
      }
    }

    return {
      today,
      caretDownOutline,
      caretUpOutline,
      ...toRefs(state),
      MAPS_API,
      openMap,
      openModal,
      Capacitor,
      doSomething,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Poppins:wght@400;700&family=Roboto:wght@400;900&display=swap');

.clickable {
  cursor: pointer;
}
.reads-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -15px;
  font-family: 'Poppins';
  width: 100%;
  padding: 0;

  .bold-item {
    font-weight: 700;
  }

  .reads-label,
  .reads-label-older {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reads-label {
    font-size: 14px !important;
    font-family: 'Poppins';
  }

  .reads-label-older {
    justify-content: center;
    font-size: 12px !important;
    font-family: 'Poppins';
  }

  .caret-down-icon {
    color: #ccc;
    position: absolute;
    right: 35%;
  }

  .reads-item {
    width: 80%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .reads-item-older {
    width: 100%;
  }

  .reads-item::part(native) {
    display: flex;
    flex-direction: column;
    padding: 0;

    .item-inner {
      width: 100%;
      padding: 0 !important;
      padding-right: 0 !important;
    }
  }

  .no-location {
    line-height: 1.5;
    font-family: 'Poppins';
  }

  .card-div {
    width: 100%;
    &:first-child {
      margin-top: 10px;
    }
  }
  .card-div-older {
    width: 100%;
  }

  .ion-card-wrapper-older-item {
    margin-right: -15px;
  }

  .ion-card-wrapper,
  .ion-card-wrapper-older {
    padding-left: 15px;
    padding-right: 6px;
  }

  .ion-card-wrapper-older {
    width: 100%;
  }

  .ion-card-styled {
    border-radius: 50px;
    margin-left: 0;
    margin-right: 3px;
    margin-bottom: 25px;
    box-shadow: 0 1px 11px 3px #efefef;

    &:last-of-type {
      margin-bottom: 25px;
    }

    .detectedContent {
      display: flex !important;
      justify-content: space-between;
      padding: 10px;

      .left-wrapper {
        display: flex;

        .location-wrapper {
          margin-left: 15px;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          font-family: var(--ion-poppins-font);
          font-weight: 400 !important;

          p {
            font-size: 12px;
          }
        }
      }

      .mapImage {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #ccc;

        &::part(image) {
          border-radius: 50%;
          width: 70px;
          height: 70px;
        }
      }
      .mapImageSpan {
        display: flex;
        background-color: transparent;

        &::part(image) {
          border-radius: 50%;
        }
      }

      .ionThumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70px;
        max-height: 70px;
        min-width: 70px;
        min-height: 70px;
      }

      .no-location-wrapper-div {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70px;
        max-height: 70px;
        min-width: 70px;
        min-height: 70px;
        background-color: #ccc;
        border-radius: 50%;
      }

      .map-img-wrapper-div {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70px;
        max-height: 70px;
        min-width: 70px;
        min-height: 70px;

        .mapImage-overlay {
          background-color: transparent;
          width: 35px;
          height: 35px;
          z-index: 10;
          position: absolute;
          top: 25px;
          // filter: drop-shadow(0px 6px 5px rgba(0, 0, 0, 0.25));
        }
      }

      .mapImage-icon-nomap.noLocation {
        position: relative;
        left: 0;
        top: 0;
        background-color: transparent;
        width: 25px;
        height: 25px;
        filter: drop-shadow(0px 6px 5px rgba(0, 0, 0, 0.1));
      }

      ion-icon:not(.mapImage-overlay) {
        color: #a1a1a1;
      }
      .nolocation-text {
        margin-left: 20px !important;
      }
      ion-icon.mapImage-overlay {
        color: #058305;
      }

      .right-wrapper {
        position: relative;
        top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Poppins';

        .device-icon {
          font-size: 24px;
          color: #ccc;
        }

        .time-stamp {
          font-size: 16px;
          padding-right: 15px;
          color: #000;
          font-family: 'Poppins';
        }
      }
    }
  }
}
</style>
<style lang="scss">
@font-face {
  font-family: 'Ionicons';
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1');
  src: url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix')
      format('embedded-opentype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1') format('truetype'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1') format('woff'),
    url('https://code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
.notice-alert {
  .alert-head {
    display: none;
  }
  .alert-wrapper {
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    overflow: visible !important;
    border-radius: 0 !important;
    max-width: fit-content !important;
    max-width: -moz-fit-content !important;
    min-width: initial !important;
    padding: 40px !important;

    .alert-message {
      box-shadow: 0 11px 15px -7px rgb(199 199 199 / 20%), 0 24px 20px 0px rgb(144 144 144 / 14%),
        0 9px 16px 0px rgb(43 43 43 / 12%) !important;
      border-radius: 10px !important;
      background-color: #fff !important;
      overflow: hidden !important;
      padding: 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .alert-button-group {
      position: absolute !important;
      top: 27px !important;
      right: 27px !important;
      max-width: fit-content !important;
      max-width: -moz-fit-content !important;
      padding: 0 !important;
      @supports (-webkit-touch-callout: none) {
        top: 25px !important;
        right: 25px !important;
      }

      .close-btn {
        width: 30px !important;
        height: 30px !important;
        border-radius: 50% !important;
        -webkit-border-radius: 50% 50% 50% 50% !important;
        margin: 0 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        color: #000 !important;
        background-color: #fff !important;
        font-weight: 700 !important;
        box-shadow: 3px 3px 25px rgb(0 0 0 / 30%) !important;
        padding: 0;
        @supports (-webkit-touch-callout: none) {
          border-radius: 50% !important;
          -webkit-border-radius: 50% 50% 50% 50% !important;
        }

        span {
          justify-content: center;
          align-items: center;
          justify-self: center;
          align-self: center;
          &::after {
            font-family: 'Ionicons';
            content: '\f129';
          }
        }
      }
    }
  }
}
</style>
