<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div v-if="isLoading" class="content">
          <ion-spinner name="crescent"></ion-spinner>
        </div>
        <div class="content-wrapper" v-else>
          <div class="content" v-if="present">
            <template v-if="present.multiple">
              <div class="present-icon" style="margin-bottom: 50px">
                <QrcodeVue :value="qrCodeUri" :foreground="'#333'" :size="150" level="H" />
              </div>

              <h1>Gratulálunk!</h1>
              <p style="margin-bottom: 30px">
                Ezt az elrejtett jutalomfalatot profin kiszagoltátok! A nyereményed átvételéhez mutasd meg a fenti
                QR-kódot a személyzetnek ezen a helyen:
              </p>

              <h2>{{ present.poi_name }}</h2>
              <p style="margin-top: 0">({{ present.poi_address }})</p>
            </template>
            <template v-else>
              <div class="present-icon">
                <lottie-animation
                  ref="anim"
                  :autoPlay="true"
                  :playInterval="10 * 1e3"
                  :animationData="require('@/assets/images/lottie/present_success.json')"
                />
              </div>

              <h1>Gratulálunk!</h1>
              <p>
                Ezt az elrejtett jutalomfalatot profin kiszagoltátok!
                <template v-if="user">Oszd meg barátaiddal és 10 sétapontot is kapsz!</template>
              </p>

              <ion-button
                color="primary"
                expand="block"
                shape="round"
                strong="true"
                class="btn btn-dox"
                style="margin: 30px 0 30px 0"
                v-if="user"
                @click="shareToSocial"
              >
                Megosztom
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>

              <ion-button
                color="light"
                expand="block"
                shape="round"
                strong="true"
                shadow="true"
                class="btn btn-dox"
                @click="goToFeed"
              >
                Köszi, tovább az appra
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
            </template>
          </div>
          <div class="content not-found" v-else>
            <PresentInvalid />
          </div>

          <ion-button
            color="primary"
            expand="block"
            shape="round"
            strong="true"
            href="mailto:hello@dox-tag.eu"
            class="btn btn-dox"
            v-if="!present"
          >
            Kapcsolatfelvétel
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { IonContent, IonPage, IonSpinner, IonButton, IonRippleEffect, isPlatform } from '@ionic/vue'
import { useRoute, useRouter } from 'vue-router'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'

import { FEED_ROUTE } from '@/constants/routes'

import usePois from '@/composables/pois'
import useToast from '@/composables/toast'

import QrcodeVue from 'qrcode.vue'
import PresentInvalid from '@/components/Present/PresentInvalid'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Present',
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    IonButton,
    IonRippleEffect,
    QrcodeVue,
    PresentInvalid,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isLoading = ref(true)
    const present = ref(null)

    const presentId = computed(() => route.params.id)
    const qrCodeUri = ref(null)

    const { getPresent, generatePresentQR, sharePresent, claimPresent } = usePois()
    const { openToast } = useToast()

    const store = useStore()
    const user = store.state.auth.user

    // const getDistanceBetweenTwoCoords = (coords1, coords2) => {
    //   const R = 6371e3 // metres
    //   const φ1 = (coords1.latitude * Math.PI) / 180
    //   const φ2 = (coords2.latitude * Math.PI) / 180
    //   const Δφ = ((coords2.latitude - coords1.latitude) * Math.PI) / 180
    //   const Δλ = ((coords2.longitude - coords1.longitude) * Math.PI) / 180

    //   const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    //   return R * c
    // }

    const goToFeed = async () => {
      await router.push({ name: FEED_ROUTE })
    }

    const shareToSocial = async () => {
      try {
        await Share.share({
          title: 'Nézd mit találtam!',
          text: 'Tudtad, hogy a doxocial alkalmazásban nagyon király ajándékokat lehet szerezni? Próbáld ki te is!',
          url: 'https://doxocial.com',
        })

        await sharePresent(presentId.value)
      } catch (error) {
        await openToast({
          message: 'Sajnos nem sikerült megosztani a sikeredet :(',
          color: 'danger',
        })
      }
    }

    onMounted(async () => {
      try {
        present.value = await getPresent(presentId.value)

        if (present.value) {
          if (present.value.multiple && !user) {
            present.value = null
            return
          }

          if (present.value.multiple) {
            const { code } = await generatePresentQR(presentId.value)

            qrCodeUri.value = `doxocial.com/poi/present/${presentId.value}/scan/${code}`
          } else {
            await claimPresent(presentId.value)
          }
        }

        isLoading.value = false
      } catch (error) {
        goToDownload()
        present.value = null
        isLoading.value = false
      }
    })

    const goToDownload = () => {
      if (Capacitor.isNative) return

      if (isPlatform('ios')) {
        window.open('https://apps.apple.com/hu/app/doxocial/id1541389635')
      } else if (isPlatform('android')) {
        window.open('https://play.google.com/store/apps/details?id=org.doxocial.application')
      } else {
        window.open('https://dox-tag.eu/doxocial')
      }
    }

    return {
      present,
      presentId,
      isLoading,
      qrCodeUri,
      goToFeed,
      shareToSocial,
      user,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.container {
  display: flex;
  align-items: center;
  height: 100%;
}

h1,
h2 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -0.5px;
}

h2 {
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
}

p {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16.34px;
  letter-spacing: -0.64px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  padding: 0 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0;

  .present-icon {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }
}

ion-button {
  width: 100%;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
</style>
