<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content">
          <ion-avatar class="active-pet">
            <ion-img :src="activePet.avatar" :alt="activePet.name" />
            <span class="pulse"></span>
            <span class="pulse"></span>
          </ion-avatar>
          <div class="form-group">
            <div class="range-wrapper">
              <ion-label class="label">
                <span class="text">Távolság:</span>
                <span class="value">{{ form.distance }} km</span>
              </ion-label>
              <div class="description">Milyen messze szimatoljunk?</div>
              <ion-range id="distance" min="1" max="320" v-model="form.distance" />

              <div class="spacer"></div>

              <ion-label class="label">
                <span class="text">Kedvencek:</span>
                <span class="value">max {{ form.numberOfPets }} </span>
              </ion-label>
              <div class="description">Hány kedvencet jelenítsünk meg?</div>
              <ion-range id="numberOfPets" min="3" max="180" v-model="form.numberOfPets" />
            </div>

            <div class="button-group">
              <ion-button
                id="save"
                fill="solid"
                shape="round"
                class="btn save"
                @click="saveFinderSettings(form.distance, form.numberOfPets)"
              >
                Mentés
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { IonPage, IonAvatar, IonContent, IonImg, IonRange, IonLabel, IonButton } from '@ionic/vue'
import usePets from '@/composables/pets'
import usePetFinder from '@/composables/petFinder'

export default {
  name: 'PetFinderSettings',
  components: {
    IonPage,
    IonAvatar,
    IonImg,
    IonContent,
    IonRange,
    IonLabel,
    IonButton,
  },

  setup() {
    onMounted(() => {
      initSniffSettings().then(response => {
        form.distance = response.data.data.szimat_range
        form.numberOfPets = response.data.data.szimat_max_pets
      })
    })

    const { activePet } = usePets()

    const { initSniffSettings, saveFinderSettings } = usePetFinder()

    const form = reactive({
      distance: 30,
      numberOfPets: 15,
    })

    return {
      activePet,
      form,
      initSniffSettings,
      saveFinderSettings,
    }
  },
}
</script>

<style lang="scss" scoped>
ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.spacer {
  margin: 24px;
}

.content {
  margin: var(--content-margin-mobile);
  margin-top: 92px;
}

.form-group {
  margin-top: 10rem;

  .range-wrapper {
    margin-top: -1rem;
  }

  .description {
    padding: 0 12px;
    font-size: 12px;
    margin-bottom: -4px;
  }

  .label {
    font-family: var(--ion-poppins-font);
    padding: 0 12px;
    display: flex;
    justify-content: left;
    letter-spacing: 0em;
    font-weight: 700;
    margin-bottom: -2px;
    .value {
      margin-left: auto;
      margin-bottom: -4px;
      font-weight: 700;
    }
  }

  .button-group {
    /*position: absolute;
    bottom: 40px;
    left: 50%;*/
    position: relative;
    top: 70px;
    right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    .save {
      font-size: 14px;
      width: 150px;
    }
    //text-align: center;
  }
}

.ios {
  .container {
    .content {
      .active-pet {
        position: relative;
        width: 74px;
        height: 74px;
        margin: 11rem auto 80px;

        ion-img {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}

.active-pet {
  position: relative;
  width: 74px;
  height: 74px;
  margin: 7.89rem auto 80px;

  ion-img {
    position: relative;
    z-index: 1;
  }
}

.pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 260px;
    height: 260px;
    border: 2px solid #06ff00;
    border-radius: 50%;
    animation: pulse 5s infinite;
    content: '';
    z-index: -1;
    transform-origin: center;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  &:after {
    animation-delay: 1s;
  }

  &:nth-child(odd) {
    animation-delay: 1.5s;

    &:before {
      animation-delay: 2s;
    }

    &:after {
      animation-delay: 3s;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  25% {
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

ion-range {
  --bar-height: 6px;
  --knob-size: 20px;
  --knob-border-radius: 50%;
  --bar-border-radius: 25px;
  --knob-background: white;
  --knob-box-shadow: 0px 0px 0px 6px #f64242;
}
</style>
