<template>
  <ion-page>
    <ion-content>
      <div class="container">
        <div class="content ion-text-center">
          <iframe src="https://dox-tag.eu/ai/" @load="loading = false" :class="{ 'fade-in': loading }" />

          <lottie-animation
            ref="anim"
            :loop="true"
            :animationData="require('@/assets/images/lottie/doxoo-splash.json')"
            :class="{ 'fade-out': !loading }"
          />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { IonPage, IonContent } from '@ionic/vue'

export default defineComponent({
  name: 'AIPage',
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const loading = ref(true)
    return {
      loading,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}

.container {
  padding: 0;
  background-color: #fff;
}

.container,
.content {
  overflow: hidden;
  background: #fff;
  height: 100%;
  margin-top: 0 !important;
  padding-bottom: 50px;

  @media (min-width: 768px) {
    height: calc(100vh - 140px);
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: 65px !important;
}

.ios {
  .content {
    margin-top: 115px !important;
  }
}

iframe {
  border: 0;
  height: 100%;
  width: 100%;

  &.fade-in {
    animation: fadeIn 0.5s forwards;
  }
}

.lottie-animation {
  margin: auto auto;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 320px;
  width: 100%;

  &.fade-out {
    animation: fadeOut 0.5s forwards;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
