<template>
  <ion-page id="dox-read-map-page">
    <ion-content class="ion-padding map-content">
      <div class="map-wrapper">
        <div id="mapDoxData"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, reactive, toRefs, computed } from 'vue'
import moment from 'moment'
import { IonPage, IonContent } from '@ionic/vue'
import { MAPS_API } from '@/constants/types'
import { Loader } from '@googlemaps/js-api-loader'

import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'MapDoxData',
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const route = useRoute()

    const today = moment().format('YYYY.MM.DD')

    //  const markerIcon = require('@/assets/images/icons/Dogs-marker.svg')

    const state = reactive({
      lat: computed(() => route.params.lat),
      long: computed(() => route.params.long),
    })

    const loader = new Loader({
      apiKey: MAPS_API,
      version: 'weekly',
    })

    loader.load().then(() => {
      setTimeout(() => {
        var mapDoxData = new window.google.maps.Map(document.getElementById('mapDoxData'), {
          center: { lat: Number(state.lat), lng: Number(state.long) },
          zoom: 18,
          mapId: '67167da0effa1878',
          scaleControl: false,
          zoomControl: false,
          streetViewControl: false,
        })
        var icon = {
          url: require('@/assets/images/icons/marker.png'),
          scaledSize: new window.google.maps.Size(48, 48),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(0, 0),
        }

        var marker = new window.google.maps.Marker({
          position: { lat: Number(state.lat), lng: Number(state.long) },
          icon: icon,
          map: mapDoxData,
        })
        return mapDoxData, marker
      }, 1000)
    })

    return {
      today,
      ...toRefs(state),
    }
  },
})
</script>

<style lang="scss" scoped>
#dox-read-map-page {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 15px;

  max-width: 800px;
  width: 100%;
  margin: var(--content-margin-web);
  margin-left: calc((100vw - 850px) / 1.57);

  @media (min-width: 1025px) and (max-width: 1199px) {
    margin-right: calc((100vw - 800px) / 4.6);
  }

  @media (min-width: 1199px) and (max-width: 1600px) {
    margin-left: calc((100vw - 850px) / 1.38);
  }
  /* width: 100%;
  margin: 35px auto 0;*/

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin: 50px auto 0;
  }
}

.map-wrapper {
  z-index: 9;

  #mapDoxData {
    position: inherit !important;
    z-index: 9;
  }
}
.close-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  margin-right: -10px;
  .closeMap {
    z-index: 100;
  }
}
</style>
