<template>
  <div class="dox-shop-overlay" :class="extraTopMargin ? 'margin-extra' : ''"></div>
  <div
    class="dox-shop-banner"
    :class="extraTopMargin ? 'margin-extra' : bigExtraTopMargin ? 'margin-extra-big' : ''"
    @click="openBrowserLink('https://dox-tag.eu')"
  >
    <ion-img :src="require('@/assets/images/icons/dox-red.svg')" alt="Dox logo" class="dox-logo" />
    <span class="">
      <ion-img :src="require('@/assets/images/icons/dox-label.svg')" alt="Buy dox" class="dox-label" />
      funkció
    </span>
    <ion-button v-if="!actionDisabled" color="dark" fill="solid" shape="round" class="btn">
      Vásárolj doxot
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg, IonButton, IonRippleEffect } from '@ionic/vue'

import useNavigation from '@/composables/navigation'

export default defineComponent({
  name: 'NoDox',
  props: {
    actionDisabled: {
      type: Boolean,
      default: false,
    },
    extraTopMargin: {
      type: Boolean,
      default: false,
    },
    bigExtraTopMargin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonImg,
    IonButton,
    IonRippleEffect,
  },
  setup() {
    const { openBrowserLink } = useNavigation()

    return {
      openBrowserLink,
    }
  },
})
</script>

<style lang="scss" scoped>
.dox-shop-overlay,
.dox-shop-banner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.margin-extra {
  margin-top: 20px;
}

.margin-extra-big {
  margin-top: 50px;
}

.dox-shop-banner {
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  span {
    font-family: var(--ion-poppins-font);
    font-size: 12px;
  }

  .dox-label {
    height: 16px;
    width: 30px;
    display: inline-flex;
  }
}

.dox-shop-overlay {
  z-index: 1;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  filter: blur(3px);
}

.dox-logo {
  height: 30px;
  margin-bottom: 6px;
}

ion-button {
  margin-top: 16px;
}
</style>
