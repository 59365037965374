<template>
  <div ref="menuEl" class="menu" v-bind:style="{ height: menuHeightComp }" v-bind:class="{ menuActive: isActive }">
    <slot name="petSelector"></slot>
    <div class="menu-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { createGesture } from '@ionic/vue'
export default {
  name: 'BottomArchMenu',
  components: {},
  props: {
    isActive: Boolean,
    menuHeight: String,
    canClose: Boolean,
  },
  emits: ['menuClosed'],
  setup(props, { emit }) {
    const menuHeightComp = computed(() => (props.menuHeight ? props.menuHeight : '230px'))
    const menuEl = ref(props.isActive)

    onMounted(() => {
      // console.log(menuEl.value)
      // const style = menuEl.value.style
      // console.log(style)
      const option = {
        el: menuEl.value,
        gestureName: 'close-down',
        direction: 'y',
        onStart: () => {
          // style.transition = 'none'
        },
        onMove: () => {
          // style.transform = `translateY(${ev.deltaY}px)`
        },
        onEnd: ev => {
          if (props.canClose && ev.deltaY > 50) {
            emit('menuClosed', true)
          }
          // style.transition = '0.3s ease-out'
          // if (ev.deltaY > menuHeightComp.value / 2) {
          //   style.transform = `translateY(${menuHeightComp.value * 1.5}px)`
          //   emit('menuClosed', true)
          // } else {
          //   style.transform = ''
          // }
        },
      }

      const gesture = createGesture(option)

      gesture.enable()
    })
    return {
      props,
      emit,
      menuHeightComp,
      menuEl,
    }
  },
}
</script>

<style lang="scss" scoped>
.menu {
  border-radius: 50% 50% 0 0;
  overflow: hidden;
  background: #fff;
  position: absolute;
  bottom: -100%;
  width: 125%;
  padding-top: 30px;
  transform: translateX(-9%);
  min-height: 150px;
  box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.7s ease-in-out;

  .menu-content {
    width: 55%;
    margin: 0 auto;
    font-size: 0.8rem !important;

    button {
      font-family: var(--ion-poppins-font);
      font-size: 12px;
      letter-spacing: 0.03571em;
    }
  }

  &.menuActive {
    bottom: 0;
  }
}
</style>
