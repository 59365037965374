<template>
  <div class="pet-list-item" @click="$router.push({ name: PET_ROUTE, params: { id: pet.id } })">
    <ion-avatar class="pet-avatar">
      <ion-img :src="pet.avatar" :alt="pet.name"></ion-img>
    </ion-avatar>
    <ion-label class="pet-name">{{ pet.name }}</ion-label>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonImg, IonAvatar, IonLabel } from '@ionic/vue'

import { PET_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'PetListItem',
  props: {
    pet: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonAvatar,
    IonImg,
    IonLabel,
  },
  setup() {
    return {
      PET_ROUTE,
    }
  },
})
</script>

<style lang="scss" scoped>
.pet-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.pet-avatar {
  width: 90px;
  height: 90px;
  flex: 0 0 auto;
  border: 6px solid #fff;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
  position: relative;
  overflow: visible;
  margin-bottom: 13px;
}

.pet-name {
  font-family: var(--ion-poppins-font);
  font-weight: 800;
  font-size: 13px;
  color: #222;

  .pet-list-item & {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
  }
}
</style>
