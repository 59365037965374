<template>
  <div class="present__icon">
    <lottie-animation
      ref="anim"
      :autoPlay="true"
      :loop="true"
      :animationData="require('@/assets/images/lottie/walk-present.json')"
    />
  </div>
  <div v-if="present.reward_image" class="branding-image">
    <ion-img :src="present.reward_image" />
  </div>
  <h1>{{ present.name }}</h1>
  <div class="difficulty">
    <ion-img
      v-for="n in 5"
      :key="n + '-difficulty'"
      :src="
        present.difficulty >= n
          ? require('@/assets/images/icons/walk/paw_green.svg')
          : require('@/assets/images/icons/walk/paw_grey.svg')
      "
      alt="nehézség"
    />
  </div>
  <p class="difficulty__title">Nehézségi szint</p>
  <div class="tips" v-if="!showImages">
    <div class="tip">
      <svg width="20" height="26" viewBox="0 0 20 26" fill="none" class="icon" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 0C14.1375 0 17.5 3.29333 17.5 7.36667C17.5 12.8886 10 21.0476 10 21.0476C10 21.0476 2.5 12.8886 2.5 7.36667C2.5 3.29333 5.8625 0 10 0ZM10 4.95238C9.33696 4.95238 8.70107 5.21326 8.23223 5.67764C7.76339 6.14202 7.5 6.77184 7.5 7.42857C7.5 8.0853 7.76339 8.71513 8.23223 9.1795C8.70107 9.64388 9.33696 9.90476 10 9.90476C10.663 9.90476 11.2989 9.64388 11.7678 9.1795C12.2366 8.71513 12.5 8.0853 12.5 7.42857C12.5 6.77184 12.2366 6.14202 11.7678 5.67764C11.2989 5.21326 10.663 4.95238 10 4.95238ZM20 21.0476C20 23.7838 15.525 26 10 26C4.475 26 0 23.7838 0 21.0476C0 19.4505 1.525 18.0267 3.8875 17.1229L4.6875 18.2495C3.3375 18.8067 2.5 19.5743 2.5 20.4286C2.5 22.1371 5.8625 23.5238 10 23.5238C14.1375 23.5238 17.5 22.1371 17.5 20.4286C17.5 19.5743 16.6625 18.8067 15.3125 18.2495L16.1125 17.1229C18.475 18.0267 20 19.4505 20 21.0476Z"
          :fill="inDistanceToPresent ? '#00DE1F' : '#F64242'"
        />
      </svg>
      <div class="description">
        <h1 v-if="inDistanceToPresent">Már elég közel vagy</h1>
        <h1 v-else>Közelebb kell lenned</h1>
        <p v-if="inDistanceToPresent">
          A jutalomfalat {{ present.minimum_distance }} méteren belül van, figyeljétek a részleteket és kezdjetek
          szaglászni.
        </p>
        <p v-else>
          Legalább {{ present.minimum_distance }} méteres sugarú távolságon belül kell tartózkodnod, hogy lásd a
          következő segítséget.
        </p>
      </div>
    </div>
    <div class="tip" :class="!inDistanceToPresent ? 'inactive' : null" @click="goToImages">
      <svg class="icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.8 18.9878L19.0593 17.3147C18.646 16.8652 18.1329 16.5191 17.5613 16.3042C16.9897 16.0893 16.3757 16.0116 15.7686 16.0774C15.1616 16.1431 14.5784 16.3505 14.0662 16.6829C13.5539 17.0152 13.1268 17.4632 12.8193 17.9907L7.722 26H2.6C1.91044 26 1.24912 25.7261 0.761522 25.2385C0.273928 24.7509 0 24.0896 0 23.4V10.4C0 9.71044 0.273928 9.04912 0.761522 8.56152C1.24912 8.07393 1.91044 7.8 2.6 7.8H18.2C18.8896 7.8 19.5509 8.07393 20.0385 8.56152C20.5261 9.04912 20.8 9.71044 20.8 10.4V18.9878ZM20.8 22.5654V23.4C20.8 24.0896 20.5261 24.7509 20.0385 25.2385C19.5509 25.7261 18.8896 26 18.2 26H11.0656L15.0657 19.2998C15.1681 19.1239 15.3104 18.9745 15.4811 18.8636C15.6519 18.7528 15.8462 18.6836 16.0486 18.6616C16.251 18.6395 16.4557 18.6654 16.6462 18.7369C16.8368 18.8085 17.0079 18.9238 17.1457 19.0736L20.8 22.5654ZM7.8 19.5C8.83434 19.5 9.82632 19.0891 10.5577 18.3577C11.2891 17.6263 11.7 16.6343 11.7 15.6C11.7 14.5657 11.2891 13.5737 10.5577 12.8423C9.82632 12.1109 8.83434 11.7 7.8 11.7C6.76566 11.7 5.77368 12.1109 5.04228 12.8423C4.31089 13.5737 3.9 14.5657 3.9 15.6C3.9 16.6343 4.31089 17.6263 5.04228 18.3577C5.77368 19.0891 6.76566 19.5 7.8 19.5ZM5.2 6.5V2.6C5.2 1.91044 5.47393 1.24912 5.96152 0.761522C6.44912 0.273928 7.11044 0 7.8 0L23.4 0C24.0896 0 24.7509 0.273928 25.2385 0.761522C25.7261 1.24912 26 1.91044 26 2.6V11.388L24.2593 9.5147C23.619 8.81777 22.7471 8.37744 21.8062 8.2758C21.3304 7.2241 20.332 6.5 19.175 6.5H16.679C16.4104 5.73881 15.9123 5.07966 15.2534 4.61343C14.5945 4.14719 13.8072 3.89681 13 3.89681C12.1928 3.89681 11.4055 4.14719 10.7466 4.61343C10.0877 5.07966 9.58958 5.73881 9.321 6.5H5.2ZM22.1 18.2V11.0656C22.1902 11.1245 22.2727 11.1944 22.3457 11.2736L26 15.1593V15.6C26 16.2896 25.7261 16.9509 25.2385 17.4385C24.7509 17.9261 24.0896 18.2 23.4 18.2H22.1Z"
          :fill="inDistanceToPresent ? (seenImages ? '#00DE1F' : '#F64242') : '#D2D2D2'"
        />
      </svg>
      <div class="description">
        <h1>Fényképek megtekintése</h1>
        <p>Kattints és nézd meg, hogy pontosan merre érdemes keresnetek a jutalomfalatot.</p>
      </div>
    </div>
    <div
      class="tip"
      :class="!inDistanceToPresent || !seenImages ? 'inactive' : null"
      @click="!(!inDistanceToPresent || !seenImages) ? goToCamera() : null"
    >
      <svg
        class="icon"
        :style="!inDistanceToPresent || !seenImages ? 'fill: #d2d2d2' : 'fill: #00DE1F'"
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.6899 0H16.62V1.06992H17.6899V0Z" />
        <path d="M10.4187 1.03876H9.34875V2.10867H10.4187V1.03876Z" />
        <path d="M12.4962 1.03876H11.4263V2.10867H12.4962V1.03876Z" />
        <path d="M14.5736 1.03876H13.5037V2.10867H14.5736V1.03876Z" />
        <path d="M16.6513 1.03876H15.5814V2.10867H16.6513V1.03876Z" />
        <path d="M9.3801 2.07755H8.31018V3.14746H9.3801V2.07755Z" />
        <path d="M12.4962 2.07755H11.4263V3.14746H12.4962V2.07755Z" />
        <path d="M13.535 2.07755H12.4651V3.14746H13.535V2.07755Z" />
        <path d="M15.6124 2.07755H14.5425V3.14746H15.6124V2.07755Z" />
        <path d="M17.6899 2.07755H16.62V3.14746H17.6899V2.07755Z" />
        <path d="M9.3801 3.11627H8.31018V4.18619H9.3801V3.11627Z" />
        <path d="M10.4187 3.11627H9.34875V4.18619H10.4187V3.11627Z" />
        <path d="M11.4575 3.11627H10.3876V4.18619H11.4575V3.11627Z" />
        <path d="M12.4962 3.11627H11.4263V4.18619H12.4962V3.11627Z" />
        <path d="M13.535 3.11627H12.4651V4.18619H13.535V3.11627Z" />
        <path d="M14.5736 3.11627H13.5037V4.18619H14.5736V3.11627Z" />
        <path d="M15.6124 3.11627H14.5425V4.18619H15.6124V3.11627Z" />
        <path d="M9.3801 4.155H8.31018V5.22492H9.3801V4.155Z" />
        <path d="M11.4575 4.155H10.3876V5.22492H11.4575V4.155Z" />
        <path d="M12.4962 4.155H11.4263V5.22492H12.4962V4.155Z" />
        <path d="M13.535 4.155H12.4651V5.22492H13.535V4.155Z" />
        <path d="M14.5736 4.155H13.5037V5.22492H14.5736V4.155Z" />
        <path d="M9.3801 5.19379H8.31018V6.2637H9.3801V5.19379Z" />
        <path d="M11.4575 5.19379H10.3876V6.2637H11.4575V5.19379Z" />
        <path d="M14.5736 5.19379H13.5037V6.2637H14.5736V5.19379Z" />
        <path d="M15.6124 5.19379H14.5425V6.2637H15.6124V5.19379Z" />
        <path d="M16.6513 5.19379H15.5814V6.2637H16.6513V5.19379Z" />
        <path d="M9.3801 6.23251H8.31018V7.30243H9.3801V6.23251Z" />
        <path d="M11.4575 6.23251H10.3876V7.30243H11.4575V6.23251Z" />
        <path d="M13.535 6.23251H12.4651V7.30243H13.535V6.23251Z" />
        <path d="M15.6124 6.23251H14.5425V7.30243H15.6124V6.23251Z" />
        <path d="M17.6899 6.23251H16.62V7.30243H17.6899V6.23251Z" />
        <path d="M9.3801 7.27127H8.31018V8.34118H9.3801V7.27127Z" />
        <path d="M10.4187 7.27127H9.34875V8.34118H10.4187V7.27127Z" />
        <path d="M11.4575 7.27127H10.3876V8.34118H11.4575V7.27127Z" />
        <path d="M12.4962 7.27127H11.4263V8.34118H12.4962V7.27127Z" />
        <path d="M13.535 7.27127H12.4651V8.34118H13.535V7.27127Z" />
        <path d="M15.6124 7.27127H14.5425V8.34118H15.6124V7.27127Z" />
        <path d="M16.6513 7.27127H15.5814V8.34118H16.6513V7.27127Z" />
        <path d="M17.6899 7.27127H16.62V8.34118H17.6899V7.27127Z" />
        <path d="M1.06992 8.31003H0V9.37994H1.06992V8.31003Z" />
        <path d="M3.14731 8.31003H2.07739V9.37994H3.14731V8.31003Z" />
        <path d="M4.18613 8.31003H3.11621V9.37994H4.18613V8.31003Z" />
        <path d="M5.22495 8.31003H4.15503V9.37994H5.22495V8.31003Z" />
        <path d="M6.26376 8.31003H5.19385V9.37994H6.26376V8.31003Z" />
        <path d="M7.30234 8.31003H6.23242V9.37994H7.30234V8.31003Z" />
        <path d="M11.4575 8.31003H10.3876V9.37994H11.4575V8.31003Z" />
        <path d="M13.535 8.31003H12.4651V9.37994H13.535V8.31003Z" />
        <path d="M14.5736 8.31003H13.5037V9.37994H14.5736V8.31003Z" />
        <path d="M15.6124 8.31003H14.5425V9.37994H15.6124V8.31003Z" />
        <path d="M19.7674 8.31003H18.6975V9.37994H19.7674V8.31003Z" />
        <path d="M20.8062 8.31003H19.7363V9.37994H20.8062V8.31003Z" />
        <path d="M21.8451 8.31003H20.7751V9.37994H21.8451V8.31003Z" />
        <path d="M22.8838 8.31003H21.8138V9.37994H22.8838V8.31003Z" />
        <path d="M23.9226 8.31003H22.8527V9.37994H23.9226V8.31003Z" />
        <path d="M3.14731 9.34882H2.07739V10.4187H3.14731V9.34882Z" />
        <path d="M4.18613 9.34882H3.11621V10.4187H4.18613V9.34882Z" />
        <path d="M14.5736 9.34882H13.5037V10.4187H14.5736V9.34882Z" />
        <path d="M17.6899 9.34882H16.62V10.4187H17.6899V9.34882Z" />
        <path d="M20.8062 9.34882H19.7363V10.4187H20.8062V9.34882Z" />
        <path d="M24.9612 9.34882H23.8912V10.4187H24.9612V9.34882Z" />
        <path d="M1.06992 10.3875H0V11.4575H1.06992V10.3875Z" />
        <path d="M2.10873 10.3875H1.03882V11.4575H2.10873V10.3875Z" />
        <path d="M5.22495 10.3875H4.15503V11.4575H5.22495V10.3875Z" />
        <path d="M7.30234 10.3875H6.23242V11.4575H7.30234V10.3875Z" />
        <path d="M9.3801 10.3875H8.31018V11.4575H9.3801V10.3875Z" />
        <path d="M11.4575 10.3875H10.3876V11.4575H11.4575V10.3875Z" />
        <path d="M12.4962 10.3875H11.4263V11.4575H12.4962V10.3875Z" />
        <path d="M15.6124 10.3875H14.5425V11.4575H15.6124V10.3875Z" />
        <path d="M16.6513 10.3875H15.5814V11.4575H16.6513V10.3875Z" />
        <path d="M17.6899 10.3875H16.62V11.4575H17.6899V10.3875Z" />
        <path d="M18.7287 10.3875H17.6588V11.4575H18.7287V10.3875Z" />
        <path d="M22.8838 10.3875H21.8138V11.4575H22.8838V10.3875Z" />
        <path d="M24.9612 10.3875H23.8912V11.4575H24.9612V10.3875Z" />
        <path d="M26 10.3875H24.9301V11.4575H26V10.3875Z" />
        <path d="M1.06992 11.4263H0V12.4962H1.06992V11.4263Z" />
        <path d="M3.14731 11.4263H2.07739V12.4962H3.14731V11.4263Z" />
        <path d="M5.22495 11.4263H4.15503V12.4962H5.22495V11.4263Z" />
        <path d="M8.34115 11.4263H7.27124V12.4962H8.34115V11.4263Z" />
        <path d="M15.6124 11.4263H14.5425V12.4962H15.6124V11.4263Z" />
        <path d="M16.6513 11.4263H15.5814V12.4962H16.6513V11.4263Z" />
        <path d="M18.7287 11.4263H17.6588V12.4962H18.7287V11.4263Z" />
        <path d="M20.8062 11.4263H19.7363V12.4962H20.8062V11.4263Z" />
        <path d="M26 11.4263H24.9301V12.4962H26V11.4263Z" />
        <path d="M4.18613 12.4651H3.11621V13.535H4.18613V12.4651Z" />
        <path d="M5.22495 12.4651H4.15503V13.535H5.22495V12.4651Z" />
        <path d="M6.26376 12.4651H5.19385V13.535H6.26376V12.4651Z" />
        <path d="M7.30234 12.4651H6.23242V13.535H7.30234V12.4651Z" />
        <path d="M8.34115 12.4651H7.27124V13.535H8.34115V12.4651Z" />
        <path d="M10.4187 12.4651H9.34875V13.535H10.4187V12.4651Z" />
        <path d="M13.535 12.4651H12.4651V13.535H13.535V12.4651Z" />
        <path d="M14.5736 12.4651H13.5037V13.535H14.5736V12.4651Z" />
        <path d="M16.6513 12.4651H15.5814V13.535H16.6513V12.4651Z" />
        <path d="M18.7287 12.4651H17.6588V13.535H18.7287V12.4651Z" />
        <path d="M19.7674 12.4651H18.6975V13.535H19.7674V12.4651Z" />
        <path d="M21.8451 12.4651H20.7751V13.535H21.8451V12.4651Z" />
        <path d="M23.9226 12.4651H22.8527V13.535H23.9226V12.4651Z" />
        <path d="M24.9612 12.4651H23.8912V13.535H24.9612V12.4651Z" />
        <path d="M26 12.4651H24.9301V13.535H26V12.4651Z" />
        <path d="M1.06992 13.5038H0V14.5737H1.06992V13.5038Z" />
        <path d="M3.14731 13.5038H2.07739V14.5737H3.14731V13.5038Z" />
        <path d="M4.18613 13.5038H3.11621V14.5737H4.18613V13.5038Z" />
        <path d="M5.22495 13.5038H4.15503V14.5737H5.22495V13.5038Z" />
        <path d="M8.34115 13.5038H7.27124V14.5737H8.34115V13.5038Z" />
        <path d="M9.3801 13.5038H8.31018V14.5737H9.3801V13.5038Z" />
        <path d="M14.5736 13.5038H13.5037V14.5737H14.5736V13.5038Z" />
        <path d="M17.6899 13.5038H16.62V14.5737H17.6899V13.5038Z" />
        <path d="M18.7287 13.5038H17.6588V14.5737H18.7287V13.5038Z" />
        <path d="M20.8062 13.5038H19.7363V14.5737H20.8062V13.5038Z" />
        <path d="M22.8838 13.5038H21.8138V14.5737H22.8838V13.5038Z" />
        <path d="M24.9612 13.5038H23.8912V14.5737H24.9612V13.5038Z" />
        <path d="M1.06992 14.5425H0V15.6125H1.06992V14.5425Z" />
        <path d="M3.14731 14.5425H2.07739V15.6125H3.14731V14.5425Z" />
        <path d="M5.22495 14.5425H4.15503V15.6125H5.22495V14.5425Z" />
        <path d="M7.30234 14.5425H6.23242V15.6125H7.30234V14.5425Z" />
        <path d="M8.34115 14.5425H7.27124V15.6125H8.34115V14.5425Z" />
        <path d="M9.3801 14.5425H8.31018V15.6125H9.3801V14.5425Z" />
        <path d="M10.4187 14.5425H9.34875V15.6125H10.4187V14.5425Z" />
        <path d="M11.4575 14.5425H10.3876V15.6125H11.4575V14.5425Z" />
        <path d="M12.4962 14.5425H11.4263V15.6125H12.4962V14.5425Z" />
        <path d="M13.535 14.5425H12.4651V15.6125H13.535V14.5425Z" />
        <path d="M14.5736 14.5425H13.5037V15.6125H14.5736V14.5425Z" />
        <path d="M15.6124 14.5425H14.5425V15.6125H15.6124V14.5425Z" />
        <path d="M16.6513 14.5425H15.5814V15.6125H16.6513V14.5425Z" />
        <path d="M17.6899 14.5425H16.62V15.6125H17.6899V14.5425Z" />
        <path d="M20.8062 14.5425H19.7363V15.6125H20.8062V14.5425Z" />
        <path d="M21.8451 14.5425H20.7751V15.6125H21.8451V14.5425Z" />
        <path d="M22.8838 14.5425H21.8138V15.6125H22.8838V14.5425Z" />
        <path d="M24.9612 14.5425H23.8912V15.6125H24.9612V14.5425Z" />
        <path d="M26 14.5425H24.9301V15.6125H26V14.5425Z" />
        <path d="M1.06992 15.5813H0V16.6512H1.06992V15.5813Z" />
        <path d="M4.18613 15.5813H3.11621V16.6512H4.18613V15.5813Z" />
        <path d="M5.22495 15.5813H4.15503V16.6512H5.22495V15.5813Z" />
        <path d="M6.26376 15.5813H5.19385V16.6512H6.26376V15.5813Z" />
        <path d="M11.4575 15.5813H10.3876V16.6512H11.4575V15.5813Z" />
        <path d="M12.4962 15.5813H11.4263V16.6512H12.4962V15.5813Z" />
        <path d="M15.6124 15.5813H14.5425V16.6512H15.6124V15.5813Z" />
        <path d="M16.6513 15.5813H15.5814V16.6512H16.6513V15.5813Z" />
        <path d="M19.7674 15.5813H18.6975V16.6512H19.7674V15.5813Z" />
        <path d="M20.8062 15.5813H19.7363V16.6512H20.8062V15.5813Z" />
        <path d="M21.8451 15.5813H20.7751V16.6512H21.8451V15.5813Z" />
        <path d="M26 15.5813H24.9301V16.6512H26V15.5813Z" />
        <path d="M1.06992 16.6201H0V17.69H1.06992V16.6201Z" />
        <path d="M4.18613 16.6201H3.11621V17.69H4.18613V16.6201Z" />
        <path d="M7.30234 16.6201H6.23242V17.69H7.30234V16.6201Z" />
        <path d="M8.34115 16.6201H7.27124V17.69H8.34115V16.6201Z" />
        <path d="M12.4962 16.6201H11.4263V17.69H12.4962V16.6201Z" />
        <path d="M17.6899 16.6201H16.62V17.69H17.6899V16.6201Z" />
        <path d="M18.7287 16.6201H17.6588V17.69H18.7287V16.6201Z" />
        <path d="M19.7674 16.6201H18.6975V17.69H19.7674V16.6201Z" />
        <path d="M20.8062 16.6201H19.7363V17.69H20.8062V16.6201Z" />
        <path d="M21.8451 16.6201H20.7751V17.69H21.8451V16.6201Z" />
        <path d="M23.9226 16.6201H22.8527V17.69H23.9226V16.6201Z" />
        <path d="M9.3801 17.6588H8.31018V18.7287H9.3801V17.6588Z" />
        <path d="M11.4575 17.6588H10.3876V18.7287H11.4575V17.6588Z" />
        <path d="M13.535 17.6588H12.4651V18.7287H13.535V17.6588Z" />
        <path d="M14.5736 17.6588H13.5037V18.7287H14.5736V17.6588Z" />
        <path d="M16.6513 17.6588H15.5814V18.7287H16.6513V17.6588Z" />
        <path d="M17.6899 17.6588H16.62V18.7287H17.6899V17.6588Z" />
        <path d="M21.8451 17.6588H20.7751V18.7287H21.8451V17.6588Z" />
        <path d="M22.8838 17.6588H21.8138V18.7287H22.8838V17.6588Z" />
        <path d="M10.4187 18.6975H9.34875V19.7675H10.4187V18.6975Z" />
        <path d="M14.5736 18.6975H13.5037V19.7675H14.5736V18.6975Z" />
        <path d="M15.6124 18.6975H14.5425V19.7675H15.6124V18.6975Z" />
        <path d="M17.6899 18.6975H16.62V19.7675H17.6899V18.6975Z" />
        <path d="M19.7674 18.6975H18.6975V19.7675H19.7674V18.6975Z" />
        <path d="M21.8451 18.6975H20.7751V19.7675H21.8451V18.6975Z" />
        <path d="M23.9226 18.6975H22.8527V19.7675H23.9226V18.6975Z" />
        <path d="M24.9612 18.6975H23.8912V19.7675H24.9612V18.6975Z" />
        <path d="M26 18.6975H24.9301V19.7675H26V18.6975Z" />
        <path d="M9.3801 19.7363H8.31018V20.8062H9.3801V19.7363Z" />
        <path d="M10.4187 19.7363H9.34875V20.8062H10.4187V19.7363Z" />
        <path d="M13.535 19.7363H12.4651V20.8062H13.535V19.7363Z" />
        <path d="M14.5736 19.7363H13.5037V20.8062H14.5736V19.7363Z" />
        <path d="M17.6899 19.7363H16.62V20.8062H17.6899V19.7363Z" />
        <path d="M21.8451 19.7363H20.7751V20.8062H21.8451V19.7363Z" />
        <path d="M22.8838 19.7363H21.8138V20.8062H22.8838V19.7363Z" />
        <path d="M24.9612 19.7363H23.8912V20.8062H24.9612V19.7363Z" />
        <path d="M26 19.7363H24.9301V20.8062H26V19.7363Z" />
        <path d="M9.3801 20.7751H8.31018V21.845H9.3801V20.7751Z" />
        <path d="M10.4187 20.7751H9.34875V21.845H10.4187V20.7751Z" />
        <path d="M13.535 20.7751H12.4651V21.845H13.535V20.7751Z" />
        <path d="M15.6124 20.7751H14.5425V21.845H15.6124V20.7751Z" />
        <path d="M16.6513 20.7751H15.5814V21.845H16.6513V20.7751Z" />
        <path d="M17.6899 20.7751H16.62V21.845H17.6899V20.7751Z" />
        <path d="M18.7287 20.7751H17.6588V21.845H18.7287V20.7751Z" />
        <path d="M19.7674 20.7751H18.6975V21.845H19.7674V20.7751Z" />
        <path d="M20.8062 20.7751H19.7363V21.845H20.8062V20.7751Z" />
        <path d="M21.8451 20.7751H20.7751V21.845H21.8451V20.7751Z" />
        <path d="M23.9226 20.7751H22.8527V21.845H23.9226V20.7751Z" />
        <path d="M24.9612 20.7751H23.8912V21.845H24.9612V20.7751Z" />
        <path d="M26 20.7751H24.9301V21.845H26V20.7751Z" />
        <path d="M9.3801 21.8138H8.31018V22.8837H9.3801V21.8138Z" />
        <path d="M10.4187 21.8138H9.34875V22.8837H10.4187V21.8138Z" />
        <path d="M11.4575 21.8138H10.3876V22.8837H11.4575V21.8138Z" />
        <path d="M17.6899 21.8138H16.62V22.8837H17.6899V21.8138Z" />
        <path d="M18.7287 21.8138H17.6588V22.8837H18.7287V21.8138Z" />
        <path d="M19.7674 21.8138H18.6975V22.8837H19.7674V21.8138Z" />
        <path d="M21.8451 21.8138H20.7751V22.8837H21.8451V21.8138Z" />
        <path d="M22.8838 21.8138H21.8138V22.8837H22.8838V21.8138Z" />
        <path d="M23.9226 21.8138H22.8527V22.8837H23.9226V21.8138Z" />
        <path d="M24.9612 21.8138H23.8912V22.8837H24.9612V21.8138Z" />
        <path d="M26 21.8138H24.9301V22.8837H26V21.8138Z" />
        <path d="M9.3801 22.8526H8.31018V23.9225H9.3801V22.8526Z" />
        <path d="M10.4187 22.8526H9.34875V23.9225H10.4187V22.8526Z" />
        <path d="M11.4575 22.8526H10.3876V23.9225H11.4575V22.8526Z" />
        <path d="M12.4962 22.8526H11.4263V23.9225H12.4962V22.8526Z" />
        <path d="M13.535 22.8526H12.4651V23.9225H13.535V22.8526Z" />
        <path d="M16.6513 22.8526H15.5814V23.9225H16.6513V22.8526Z" />
        <path d="M22.8838 22.8526H21.8138V23.9225H22.8838V22.8526Z" />
        <path d="M23.9226 22.8526H22.8527V23.9225H23.9226V22.8526Z" />
        <path d="M26 22.8526H24.9301V23.9225H26V22.8526Z" />
        <path d="M10.4187 23.8913H9.34875V24.9612H10.4187V23.8913Z" />
        <path d="M11.4575 23.8913H10.3876V24.9612H11.4575V23.8913Z" />
        <path d="M12.4962 23.8913H11.4263V24.9612H12.4962V23.8913Z" />
        <path d="M15.6124 23.8913H14.5425V24.9612H15.6124V23.8913Z" />
        <path d="M16.6513 23.8913H15.5814V24.9612H16.6513V23.8913Z" />
        <path d="M17.6899 23.8913H16.62V24.9612H17.6899V23.8913Z" />
        <path d="M18.7287 23.8913H17.6588V24.9612H18.7287V23.8913Z" />
        <path d="M20.8062 23.8913H19.7363V24.9612H20.8062V23.8913Z" />
        <path d="M21.8451 23.8913H20.7751V24.9612H21.8451V23.8913Z" />
        <path d="M22.8838 23.8913H21.8138V24.9612H22.8838V23.8913Z" />
        <path d="M26 23.8913H24.9301V24.9612H26V23.8913Z" />
        <path d="M9.3801 24.9301H8.31018V26H9.3801V24.9301Z" />
        <path d="M11.4575 24.9301H10.3876V26H11.4575V24.9301Z" />
        <path d="M12.4962 24.9301H11.4263V26H12.4962V24.9301Z" />
        <path d="M18.7287 24.9301H17.6588V26H18.7287V24.9301Z" />
        <path d="M20.8062 24.9301H19.7363V26H20.8062V24.9301Z" />
        <path d="M21.8451 24.9301H20.7751V26H21.8451V24.9301Z" />
        <path d="M22.8838 24.9301H21.8138V26H22.8838V24.9301Z" />
        <path d="M23.9226 24.9301H22.8527V26H23.9226V24.9301Z" />
        <path d="M24.9612 24.9301H23.8912V26H24.9612V24.9301Z" />
        <path d="M26 24.9301H24.9301V26H26V24.9301Z" />
        <path
          d="M0 0V3.63564C0 5.66121 1.61007 7.27128 3.63564 7.27128H7.27128V3.63564C7.27128 1.61007 5.66121 0 3.63564 0H0ZM3.63564 6.23252C2.18138 6.23252 1.03875 5.08989 1.03875 3.63564V1.03875H3.63564C5.08989 1.03875 6.23252 2.18138 6.23252 3.63564C6.23252 5.08989 5.08989 6.23252 3.63564 6.23252Z"
        />
        <path
          d="M25.9689 0L22.3332 0C20.3077 0 18.6976 1.61007 18.6976 3.63564V7.27128L22.3332 7.27128C24.3588 7.27128 25.9689 5.66121 25.9689 3.63564V0ZM19.7363 3.63564C19.7363 2.18138 20.879 1.03875 22.3332 1.03875L24.9301 1.03875V3.63564C24.9301 5.08989 23.7875 6.23252 22.3332 6.23252C20.879 6.23252 19.7363 5.08989 19.7363 3.63564Z"
        />
        <path
          d="M0 25.9688H3.63564C5.66121 25.9688 7.27128 24.3587 7.27128 22.3332L7.27128 18.6975H3.63564C1.61007 18.6975 0 20.3076 0 22.3332L0 25.9688ZM6.23252 22.3332C6.23252 23.7874 5.08989 24.9301 3.63564 24.9301H1.03875L1.03875 22.3332C1.03875 20.8789 2.18138 19.7363 3.63564 19.7363C5.08989 19.7363 6.23252 20.8789 6.23252 22.3332Z"
        />
        <path
          d="M3.63552 5.19381C2.75258 5.19381 2.07739 4.51862 2.07739 3.63568V2.07755H3.63552C4.51846 2.07755 5.19365 2.75274 5.19365 3.63568C5.19365 4.51862 4.51846 5.19381 3.63552 5.19381Z"
        />
        <path
          d="M20.7749 3.63565C20.7749 2.7527 21.45 2.07751 22.333 2.07751H23.8911V3.63565C23.8911 4.51859 23.2159 5.19378 22.333 5.19378C21.45 5.19378 20.7749 4.51859 20.7749 3.63565Z"
        />
        <path
          d="M5.19365 22.3332C5.19365 23.2161 4.51846 23.8913 3.63552 23.8913H2.07739V22.3332C2.07739 21.4503 2.75258 20.7751 3.63552 20.7751C4.51846 20.7751 5.19365 21.4503 5.19365 22.3332Z"
        />
      </svg>
      <div class="description">
        <h1>TAG vagy QR beolvasása</h1>
        <p>Olvasd be a DOXOCIAL Tag-et, vagy a QR-kódot, hogy regisztráld a nyereményed!</p>
      </div>
    </div>
  </div>
  <div v-else class="image-teaser-container">
    <div class="image-teaser">
      <div class="image" :style="{ 'background-image': `url(${present.image_url})` }"></div>
      <h2>{{ imageTimerCount }}</h2>
    </div>
    <p class="teaser-description">{{ present.image_description }}</p>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onUnmounted, toRefs } from 'vue'
import useGeoLocation from '@/composables/geolocation'
import useNavigation from '@/composables/navigation'

import { PAGE_PRESENT_CAMERA_ROUTE } from '@/constants/routes'

export default defineComponent({
  name: 'PresentDetails',
  props: {
    present: {},
  },
  setup(props) {
    const { navigateTo } = useNavigation()
    const { getCurrentPosition } = useGeoLocation()
    const inDistanceToPresent = ref(false)
    const present = toRefs(props).present
    const positionInterval = ref(null)
    const showImages = ref(false)
    const seenImages = ref(false)
    const imageTimer = ref(null)
    const imageTimerCount = ref(5)

    const getUserCoords = async () => {
      try {
        const res = await getCurrentPosition(null)
        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      }
    }

    const goToImages = () => {
      if (!inDistanceToPresent.value) return

      showImages.value = true
      imageTimerCount.value = 5

      startImageTimer()
    }

    const startImageTimer = () => {
      if (imageTimerCount.value > 0) {
        imageTimer.value = setTimeout(() => {
          imageTimerCount.value -= 1
          startImageTimer()
        }, 1000)
      } else {
        if (imageTimer.value) {
          clearTimeout(imageTimer.value)
          imageTimer.value = null
          showImages.value = false
          seenImages.value = true
        }
      }
    }

    const goToCamera = async () => {
      await navigateTo({ name: PAGE_PRESENT_CAMERA_ROUTE, params: { id: present.value.id } })
    }

    onMounted(() => {
      inDistanceToPoi(present.value)
      positionInterval.value = setInterval(() => {
        inDistanceToPoi(present.value)
      }, 1500)
    })

    onUnmounted(() => {
      clearInterval(positionInterval.value)
      positionInterval.value = null
    })

    const inDistanceToPoi = async poi => {
      const { coords } = await getUserCoords()

      if (coords.latitude && coords.longitude) {
        const dist = distanceBetweenTwoCoords(coords.latitude, poi.latitude, coords.longitude, poi.longitude)

        inDistanceToPresent.value = dist <= poi.minimum_distance
      } else {
        inDistanceToPresent.value = false
      }
    }

    const distanceBetweenTwoCoords = (lat1, lat2, lng1, lng2) => {
      const R = 6371e3 // metres
      const l1 = (lat1 * Math.PI) / 180
      const l2 = (lat2 * Math.PI) / 180
      const dLat = ((lat2 - lat1) * Math.PI) / 180
      const dLng = ((lng2 - lng1) * Math.PI) / 180

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(l1) * Math.cos(l2) * Math.sin(dLng / 2) * Math.sin(dLng / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      return R * c
    }

    return {
      showImages,
      seenImages,
      inDistanceToPresent,
      goToImages,
      imageTimerCount,
      goToCamera,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Poppins:wght@800&display=swap');

.present__icon {
  position: relative;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
  min-width: 55px;
  max-width: 55px;
  min-height: 55px;
  background: #fff;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  .lottie-animation {
    max-width: 50px;
  }
}

.branding-image ion-img {
  height: 20px;
  margin-top: -50px;
  margin-bottom: 55px;
}

h1 {
  margin-top: -40px;
  font-size: 15px !important;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
}

.difficulty {
  display: flex;
  justify-content: center;

  ion-img {
    margin: 0 1.5px;
    min-width: 14px;
  }

  &__title {
    font-size: 10px;
    font-weight: 400;
  }
}

.tips {
  margin-top: 20px;
  max-height: 450px;
  overflow-y: auto;

  @media (max-height: 700px) {
    padding-bottom: 250px;
  }

  .tip {
    max-width: calc(100vw - 70px);
    margin: 0 auto;
    box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 17px;

    &.inactive {
      color: #bababa;
    }

    .icon {
      max-height: 26px;
      flex-shrink: 0;
      margin-right: 20px;
    }

    h1 {
      margin: 0;
      font-size: 12px;
      font-weight: 900;
      font-family: 'Montserrat', sans-serif;
    }

    p {
      margin: 0;
      margin-top: 5px;
    }
  }
}

.image-teaser-container {
  max-height: 450px;
  overflow-y: auto;
}

.image-teaser {
  position: relative;
  max-width: 320px;
  margin: 0 auto;
  margin-top: 20px;

  .image {
    max-width: 320px;
    max-height: 320px;
    aspect-ratio: 1 / 1;
    width: 100%;

    background-position: center center;
    background-size: cover;
    border-radius: 9px;
  }

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    font-size: 50px;
    font-weight: 800;
    color: #fff;
    margin: 0;
  }
}

.teaser-description {
  max-width: 320px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 17px;
  padding-bottom: 250px;
}
</style>
